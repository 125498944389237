type ComponentClass = any;

type ComponentClassRecord = [string, ComponentClass];

class Registry {
    private static classes: ComponentClassRecord[] = [];

    static Add (name: string, cls: ComponentClass): void {
      Registry.classes.push([name, cls])
    }

    static Get (name: string): ComponentClass | null {
      const record = Registry.classes.find(tuple => tuple[0] === name)
      return record ? record[1] : null
    }
}

export default Registry
