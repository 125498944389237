export enum BusinessType {
  Individual = 'individual',
  Company = 'company'
}

export enum Country {
  UnitedStates = 'US',
  GreatBritain = 'GB',
  Canada = 'CA',
  Australia = 'AU'
}

type StripeConnectCommonFields = {
  country: Country,
  businessUrl: string,
  phoneNumber: string,
  streetAddress: string,
  postalCode: string,
  mcc: number,
  tosAccepted?: boolean // 'true' if just accepted but acceptance is not reported to the backend yet, or timestamp of acceptance date
}

export type StripeConnectIndividualFields = {
  ssnLast4: string,
  dob: number
} & StripeConnectCommonFields

export type StripeConnectCompanyFields = {
  fein?: string,
} & StripeConnectCommonFields

export type StripeConnectFields = StripeConnectIndividualFields & StripeConnectCompanyFields

export type StripeConnectState = {
  businessType: BusinessType | null,
} & StripeConnectFields

export type PropsWithStripeConnectState = {
  stripeConnect: StripeConnectState
}

export type StripeConnectActions = Readonly<{
  stripeConnectAdoptState: (state: StripeConnectState) => void,
  stripeConnectBusinessType: (type: BusinessType) => void,
  stripeConnectMergeFields: (fields: StripeConnectFields) => void
}>

export type StripeConnectError = {
  fein?: string,
  ssnLast4?: string,
  dob?: string,
  businessUrl?: string,
  phoneNumber?: string,
  streetAddress?: string,
  postalCode?: string,
  tosAccepted?: string,
  mcc?: string
}
