import React from 'react'
import * as types from 'Scenes/FormEditor/types'
import QuestionView from './QuestionView'
// import FormButton from 'Scenes/Beakid/FormButton'
// import './styles.scss'

type QuestionProps = {
  entity: types.Question,
  onEdit: () => void
}

function RadiosQuestionView (props: QuestionProps) {
  return (
    <QuestionView { ...props }>
      <ol className="options">
        {props.entity.options.map((option, index) => (<li key={index}>
          <label>{option.name}</label>
        </li>))}
      </ol>
      <input type="text" placeholder="Help text" readOnly value={props.entity.helpText}/>
    </QuestionView>
  )
}

export default RadiosQuestionView
