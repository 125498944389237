import React from 'react'
import * as types from 'Scenes/BusinessSetup/types'

export function Cost ({ unit, value }: types.SubscriptionOptionCost) {
  return unit === '%' ? (
    <>{value} {unit}</>
  ) : (
    <>{unit} {value}</>
  )
}
