import React from 'react'
import Cell from './Cell'
import ReactECharts from 'echarts-for-react'
import { connect } from 'react-redux'
import * as types from '../types'
import { EmptyState } from './EmptyState'

type SignUpByGenderChartProps = types.PropsWithBusinessDashboardState

function SignUpByGenderChart (props: SignUpByGenderChartProps) {
  const data = props.businessDashboard.analytics.data[props.businessDashboard.currentBusiness].sign_up_by_gender

  if (data.length === 0) {
    return <Cell title="Sign-ups by gender">
      <EmptyState message="Currently there is no information to display"/>
    </Cell>
  }

  const getOption = () => ({
    tooltip: {
      trigger: 'item'
    },
    legend: {
      top: '5%',
      left: 'center',
      // doesn't perfectly work with our tricks, disable it
      selectedMode: false
    },
    // color: ['#66bb6a', '#29b6f6', '#ef5350', '#7986cb', '#ff7043'],
    series: [
      {
        name: 'Sign ups',
        type: 'pie',
        data: [
          ...data,
          {
            // make an record to fill the bottom 50%
            value: data.reduce((result, item) => (result + item.value), 0),
            itemStyle: {
              // stop the chart from rendering this piece
              color: 'none',
              decal: {
                symbol: 'none'
              }
            },
            label: {
              show: false
            }
          }
        ],
        startAngle: 180,
        radius: ['40%', '70%'],
        center: ['50%', '70%'],
        label: {
          show: true,
          formatter (param) {
            // correct the percentage
            return param.name + ' (' + param.percent * 2 + '%)'
          }
        }
        // labelLine: {
        //   normal: {
        //     show: true
        //   }
        // }
      }
    ]
  })

  return (
    <Cell title="Sign-ups by gender">
      <ReactECharts
        option={getOption()}
        notMerge={true}
        lazyUpdate={true}
      />
    </Cell>
  )
}

const mapStateToProps = (state) => ({
  businessDashboard: state.businessDashboard
})

export default connect(mapStateToProps)(SignUpByGenderChart)
