// tab state
export const EVENTMANAGEMENT_ADOPT_STATE = 'EVENTMANAGEMENT_ADOPT_STATE'
export const EVENTMANAGEMENT_GOTO = 'EVENTMANAGEMENT_GOTO'
export const EVENTMANAGEMENT_POPUP_EVENT_CREATE = 'EVENTMANAGEMENT_POPUP_EVENT_CREATE'
// edit event - open event settings
export const EVENTMANAGEMENT_POPUP_EVENT_EDIT = 'EVENTMANAGEMENT_POPUP_EVENT_EDIT'
// open tab when already in event edit mode
export const EVENTMANAGEMENT_POPUP_EVENT_EDIT_TAB = 'EVENTMANAGEMENT_POPUP_EVENT_EDIT_TAB'
// view event (open timeline tab)
export const EVENTMANAGEMENT_TEAMS_CREATE = 'EVENTMANAGEMENT_TEAMS_CREATE'
export const EVENTMANAGEMENT_EVENT_SAVE = 'EVENTMANAGEMENT_EVENT_SAVE'
export const EVENTMANAGEMENT_EVENT_POPULATE_TEAMS = 'EVENTMANAGEMENT_EVENT_POPULATE_TEAMS'
export const EVENTMANAGEMENT_EVENT_DELETE = 'EVENTMANAGEMENT_EVENT_DELETE'
// add Post to event' timeline
export const EVENTMANAGEMENT_EVENT_TIMELINE_POST = 'EVENTMANAGEMENT_EVENT_TIMELINE_POST'
export const EVENTMANAGEMENT_EVENT_TIMELINE_POST_PERMANENT_ID = 'EVENTMANAGEMENT_EVENT_TIMELINE_POST_PERMANENT_ID'
export const EVENTMANAGEMENT_EVENT_TEAM_DELETE = 'EVENTMANAGEMENT_EVENT_TEAM_DELETE'
