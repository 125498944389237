import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import Scene from './Scene'
import Registry from './Registry'

declare global {
    interface Window { BeakidReactFactory: any; }
}

class Factory {
  FormContext: React.Context<string>

  constructor (private store: any) {
    this.FormContext = React.createContext('')
  }

  registerComponent = (/* instance */) => {
    //
  }

  private getComponentClass (name: string) {
    const cls = Registry.Get(name)
    if (cls == null) {
      throw new Error('Component ' + name + ' not found')
    }
    return cls
  }

  public createInstance (name: string, props: any, formId: string) {
    const FormContext = this.FormContext
    const NewScene = this.getComponentClass(name)

    return <FormContext.Provider value={formId}>
      <Provider store={this.store}>
        <Scene ref={this.registerComponent}>
          <NewScene {...props} />
        </Scene>
      </Provider>
    </FormContext.Provider>
  }

  public render (element, componentName, props, drupalFormId) {
    const scene = this.createInstance(componentName, props, drupalFormId)
    ReactDOM.render(scene, element)
  }
}

export let factory: Factory | null = null

export function setup (store) {
  if (factory === null) {
    factory = new Factory(store)
  }

  window.BeakidReactFactory = factory
}
