import React from 'react'
import { BusinessPlan } from 'Scenes/BusinessBilling/types'
import classNames from 'classnames'
import { Cost } from 'Scenes/BusinessBilling/components/Cost'
import { connect } from 'react-redux'
import * as types from 'Scenes/BusinessBilling/types'

type BusinessBillingProps = {
  section: string
  plan: BusinessPlan
  onSelect: () => void
  onUnselect: () => void
  selected: boolean
  purchased: boolean
  multiple?: boolean
  showFees?: boolean
  disabled: boolean
} & types.PropsWithBusinessBillingState

function Plan (props: BusinessBillingProps) {
  const showUpdateNote = props.businessBilling.activeSubscription !== null && !props.purchased && !props.disabled
  const [option1] = props.plan.subscriptionOptions
  const selectedOption = option1

  const cost = selectedOption.cost[props.businessBilling.period] ?? selectedOption.cost[Object.keys(selectedOption.cost).shift()]

  const handleClick = () => {
    if (!props.disabled) {
      if (props.selected) {
        props.onUnselect()
      } else {
        props.onSelect()
      }
    }
  }

  const handleChange = (e) => {
    if (e.target.checked) {
      props.onSelect()
    } else {
      props.onUnselect()
    }
  }

  return (
    <div
      className={classNames({
        plan: true,
        [props.plan.title.replace(/[\W_]+/g, '-').toLowerCase()]: true,
        promoted: props.plan.promoted,
        selected: props.selected,
        disabled: props.disabled
      })}
      onClick={handleClick}
    >

      <div className="form-item">
        <input
          type={props.multiple ? 'checkbox' : 'radio'}
          name={props.section}
          // onSelect={props.onSelect}
          checked={props.selected}
          onChange={handleChange}
          disabled={props.disabled}
        />
      </div>

      <div className="text-info">
        <h2>
          {props.plan.title}
          {props.purchased && <span className="tag tag-purchased">Purchased</span>}
          {showUpdateNote && <span className="tag tag-upgrade">Upgrade</span>}
        </h2>
        <div className="description">{props.plan.description?.substring(0, 70)}...
          {props.plan.description !== undefined &&
            <span className="tooltip-anchor tooltip" title={props.plan.description}></span>
          }
        </div>
      </div>

      <div className="cost">
        <span className="value"><Cost { ...cost }/></span>
        /
        {cost.subject}

        {props.showFees && (
          <div className="card-fees">
            <div className="plus">+</div>
            <div className="text">Credit Card / ACH Fees</div>
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  businessBilling: state.businessBilling
})

export default connect(mapStateToProps)(Plan)
