import React, { useEffect, useRef, useState } from 'react'
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import FormButton from 'Beakid/FormButton'
import ReactTooltip from 'react-tooltip'
import SpinnerPopup from 'reactjs-popup'
import { SpinnerCircular } from 'spinners-react'
import * as types from 'Scenes/BusinessSetup/types'
import { CreateTokenCardData } from '@stripe/stripe-js'
import { CountryInput } from '../CountryInput'
import './styles.scss'

type CardInputProps = {
  method: types.PaymentMethodCard
  onComplete: () => void
}

interface CardInputError {
  name?: string,
  country?: string,
  zip?: string,
  card?: string,
  expiry?: string,
  cvc?: string
}

export function CardInput (props: CardInputProps) {
  const elements = useElements()
  const stripe = useStripe()
  const [name, setName] = useState<string>(props.method && props.method.name)
  const [address, setAddress] = useState<string>(props.method && props.method.addressLine1)
  const [city, setCity] = useState<string>(props.method && props.method.addressCity)
  const [state, setState] = useState<string>(props.method && props.method.addressState)
  const [zip, setZip] = useState<string>(props.method && props.method.addressState)
  const [country, setCountry] = useState<string>(props.method && props.method.addressCountry)
  const [showWaitPopup, setShowWaitPopup] = useState<boolean>(false)

  const nameRef = useRef(null)
  const countryRef = useRef(null)
  const zipRef = useRef(null)
  const cardRef = useRef(null)
  const expiryRef = useRef(null)
  const cvcRef = useRef(null)
  const [error, setError] = useState<CardInputError>({})

  useEffect(() => {
    if (Object.keys(error).length > 0) {
      console.log('Validation error', error)
      setShowWaitPopup(false)
    }
    if ('name' in error) {
      ReactTooltip.show(nameRef.current)
    }
    if ('country' in error) {
      ReactTooltip.show(countryRef.current)
    }
    if ('zip' in error) {
      ReactTooltip.show(zipRef.current)
    }
    if ('card' in error) {
      ReactTooltip.show(cardRef.current)
    }
    if ('expiry' in error) {
      ReactTooltip.show(expiryRef.current)
    }
    if ('cvc' in error) {
      ReactTooltip.show(cvcRef.current)
    }
  }, [error])

  const resetError = (event) => {
    ReactTooltip.hide(event.target)
    setError({})
  }

  const handleCancel = (event?) => {
    event.preventDefault()
    event.stopPropagation()
    if ('onComplete' in props) {
      props.onComplete()
    }
  }

  const handleSaveCardValidate = async () => {
    if (name === null || name === '') {
      setError({ name: 'Please, submit cardholder name' })
      return false
    }
    if (country === null || country === 'unset') {
      setError({ country: 'Please, select your country' })
      return false
    }
    if (zip === null || zip === '') {
      setError({ zip: 'Please, submit postal code' })
      return false
    }

    const values: CreateTokenCardData = {
      name,
      address_country: country
    }

    if (address) {
      values.address_line1 = address
    }
    if (city) {
      values.address_city = city
    }
    if (state) {
      values.address_state = state
    }
    if (zip) {
      values.address_zip = zip
    }

    setShowWaitPopup(true)

    return stripe.createToken(
      elements.getElement('cardNumber'),
      values
    )
      .catch(error => {
        console.error(error)
        return null
      })
      .then(result => {
        console.log('result', result)
        if ('error' in result) {
          console.error('Token generation error', result.error)
          switch (result.error.code) {
            case 'incomplete_number':
            case 'invalid_number':
              setError({ card: result.error.message })
              break

            case 'incomplete_expiry':
            case 'invalid_expiry_year_past':
              setError({ expiry: result.error.message })
              break

            case 'incomplete_cvc':
              setError({ cvc: result.error.message })
              break

            default:
              setError({ card: result.error.message })
          }
          return null
        }
        return result.token.id
      })
  }

  const handleSaveCardSubmit = (validationResult) => {
    const token = validationResult
    console.log('token', token)
    return { token }
  }

  const handleSaveCardComplete = () => {
    console.log('handleSaveCardComplete')
    setShowWaitPopup(false)
    if ('onComplete' in props) {
      props.onComplete()
    }
  }

  const stripeElementStyle = {
    base: {
      backgroundColor: '#F3F3F7B3',
      lineHeight: '40px',
      '::placeholder': {
        color: '#2C303F80',
        fontSize: '14px'
      }
    }
  }

  return (
    <div className="card-input2">
      <h3>Payment Method</h3>
      <div className="elements">
        <div className="section section-1">
          <div className="line line-1">
            <input
              type="text"
              className="name"
              placeholder="Name on card"
              onChange={event => setName(event.target.value)}
              defaultValue={name}
              {...('name' in error ? {
                'data-tip': error.name,
                'data-type': 'error',
                'data-effect': 'solid',
                ref: nameRef,
                onFocus: resetError
              } : {})}
            />
          </div>
          <div className="line line-2 line-card-elements">
            <div
              className="stripe-element-wrapper"
              {...('card' in error ? {
                'data-tip': error.card,
                'data-type': 'error',
                'data-effect': 'solid',
                ref: cardRef,
                onFocus: resetError
              } : {})}
            >
              <CardNumberElement options={{
                placeholder: 'Card Number',
                style: stripeElementStyle
              }}/>
            </div>
            <div
              className="stripe-element-wrapper"
              {...('expiry' in error ? {
                'data-tip': error.expiry,
                'data-type': 'error',
                'data-effect': 'solid',
                ref: expiryRef,
                onFocus: resetError
              } : {})}
            >
              <CardExpiryElement options={{
                placeholder: 'MM/YY',
                style: stripeElementStyle
              }}/>
            </div>
            <div
              className="stripe-element-wrapper"
              {...('cvc' in error ? {
                'data-tip': error.cvc,
                'data-type': 'error',
                'data-effect': 'solid',
                ref: cvcRef,
                onFocus: resetError
              } : {})}
            >
              <CardCvcElement options={{
                placeholder: 'CVC/CVV',
                style: stripeElementStyle
              }}/>
            </div>
          </div>
        </div>
        <div className="section section-2">
          <div className="line line-1">
            <CountryInput
              name="card[country]"
              defaultValue={country}
              onChange={event => setCountry(event.target.value)}
              inputRef={countryRef}
              {...('country' in error ? {
                'data-tip': error.country,
                'data-type': 'error',
                'data-effect': 'solid',
                onFocus: resetError
              } : {})}
            />
          </div>
          <div className="line line-2 line-address">
            <input type="text" name="card[address]" placeholder="Address" onChange={event => setAddress(event.target.value)}/>
            <input
              type="text"
              name="card[postal-code]"
              placeholder="Zip Code"
              onChange={event => setZip(event.target.value)}
              {...('zip' in error ? {
                'data-tip': error.zip,
                'data-type': 'error',
                'data-effect': 'solid',
                ref: zipRef,
                onFocus: resetError
              } : {})}
            />
          </div>
          <div className="line line-3 line-city-state">
            <input type="text" name="card[city]" placeholder="City (optional)" onChange={event => setCity(event.target.value)}/>
            <select name="card[state]" defaultValue="unset" onChange={event => setState(event.target.value)}>
              <option value="unset">State (optional)</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AS">American Samoa</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="AA">Armed Forces - Americas</option>
              <option value="AE">Armed Forces - Europe</option>
              <option value="AP">Armed Forces - Pacific</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District of Columbia</option>
              <option value="FM">Federated States of Micronesia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="GU">Guam</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MH">Marshall Islands</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="MP">Northern Mariana Islands</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PW">Palau</option>
              <option value="PA">Pennsylvania</option>
              <option value="PR">Puerto Rico</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VI">Virgin Islands</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
          </div>
        </div>
      </div>

      <div className="actions">
        <button className="btn-cancel" onClick={event => handleCancel(event)}>Back</button>
        <FormButton
          text={props.method ? 'Update Card' : 'Save Card'}
          className="btn-submit"
          beakidElement="save-card"
          onClick={() => setError({})}
          onValidate={handleSaveCardValidate}
          onSubmit={handleSaveCardSubmit}
          onComplete={handleSaveCardComplete}
        />
      </div>
      {Object.keys(error).length > 0 && <ReactTooltip />}
      <SpinnerPopup open={showWaitPopup} className="business-billing-wait-popup">
        <SpinnerCircular />
      </SpinnerPopup>
    </div>
  )
}
