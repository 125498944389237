import React, { useEffect, useRef, PropsWithChildren } from 'react'
import * as types from 'Scenes/FormEditor/types'
import FormButton from 'Beakid/FormButton'
import Switch from 'react-ios-switch'
import ReactTooltip from 'react-tooltip'

export enum QuestionMode {
  View = 'view',
  Edit = 'edit'
}

export type QuestionInputProps = PropsWithChildren<{
  entity: types.Question,
  error?: {
    title?: string,
    type?: string
  },
  onChange: (question: types.Question) => void,
  onDuplicate: () => void,
  onEdit: () => void,
  onDelete: () => void,
  onResetError?: () => void
}>

function QuestionInput (props: QuestionInputProps) {
  const titleRef = useRef(null)
  const typeRef = useRef(null)
  const inputRef = useRef(null)

  const handleTitleChange = (event: { target: { value: any } }) => props.onChange({ ...props.entity, title: event.target.value })
  const handleTypeChange = (event: { target: { value: any } }) => props.onChange({ ...props.entity, type: event.target.value })
  const handleRequiredChange = (required: any) => props.onChange({ ...props.entity, required })
  const handleQuestionEdit = event => {
    if (!event.target.matches('input[type="checkbox"]') && !event.target.matches('input[type="button"]')) {
      props.onEdit()
    }
  }

  const resetError = event => {
    ReactTooltip.hide(event.target)
    props.onResetError()
  }

  useEffect(() => {
    if ('error' in props) {
      if ('title' in props.error) {
        ReactTooltip.show(titleRef.current)
      }
      if ('type' in props.error) {
        ReactTooltip.show(typeRef.current)
      }
      inputRef.current.scrollIntoView()
    }
  }, [props.error || {}])

  const questionTypes = [
    {
      value: types.QuestionType.Text,
      label: 'Text'
    },
    {
      value: types.QuestionType.Long,
      label: 'Long text'
    },
    {
      value: types.QuestionType.Dropdown,
      label: 'Dropdown'
    },
    {
      value: types.QuestionType.Radios,
      label: 'Radios'
    },
    {
      value: types.QuestionType.Checkboxes,
      label: 'Checkboxes'
    },
    {
      value: types.QuestionType.File,
      label: 'File upload'
    },
    {
      value: types.QuestionType.Signature,
      label: 'Signature'
    }
  ]

  return (
    <div
      className="question-input"
      onClick={handleQuestionEdit}
      ref={inputRef}
    >
      <div className="line line-title">
        <input
          type="text"
          placeholder="Question"
          className="question-title"
          onChange={handleTitleChange}
          value={props.entity.title}
          {...('error' in props && 'title' in props.error ? {
            'data-tip': props.error.title,
            'data-type': 'error',
            'data-effect': 'solid',
            'data-scroll-hide': false,
            ref: titleRef,
            onFocus: resetError
          } : {})}
        />
        <select
          onChange={handleTypeChange}
          defaultValue={props.entity.type}
          {...('error' in props && 'type' in props.error ? {
            'data-tip': props.error.type,
            'data-type': 'error',
            'data-effect': 'solid',
            'data-scroll-hide': false,
            ref: typeRef,
            onFocus: resetError
          } : {})}
        >
          <option value={null}>Answer Type</option>
          {questionTypes.map((option, index) => <option value={option.value} key={index}>{option.label}</option>)}
        </select>
      </div>
      {props.children}
      <div className="toolbar">
        <label>
          <Switch
            checked={props.entity.required}
            onChange={handleRequiredChange}
            className="switch"
          />
          Required
        </label>

        <div className="buttons">
          <FormButton onClick={props.onDuplicate} text="Duplicate" className="btn-duplicate"/>
          <FormButton onClick={props.onDelete} text="Delete" className="btn-delete"/>
        </div>
      </div>
    </div>
  )
}

export default QuestionInput
