import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as businessSetupActions from 'Scenes/BusinessSetup/actions'
import * as types from 'Scenes/BusinessSetup/types'
import FormButton from 'Beakid/FormButton'
import PhoneInput from 'react-phone-input-2'
import ReactTooltip from 'react-tooltip'
import Axios from 'axios'
import Header from '../Header'
import './styles.scss'

type TaskBasicProps = {
  task: types.Task
  onDone: () => void
} & types.PropsWithBusinessSetupState & types.BusinessSetupActions

interface StepBusinessAboutError {
  name?: string,
  email?: string,
  website?: string,
  phone?: string,
  country?: string,
  streetAddress?: string,
  postalCode?: string,
  first?: string,
  last?: string
}

function TaskBasic (props: TaskBasicProps) {
  const data = props.task.data as types.DataBasic
  const [name, setName] = useState(data.name)
  const [website, setWebsite] = useState(data.website)
  const [country, setCountry] = useState(data.country)
  const [streetAddress, setStreetAddress] = useState(data.streetAddress)
  const [postalCode, setPostalCode] = useState(data.postalCode)
  const [phone, setPhone] = useState(data.phone)
  const [first, setFirst] = useState(data.first)
  const [last, setLast] = useState(data.last)

  const [error, setError] = useState<StepBusinessAboutError>({})

  const handleNameChange = (event) => setName(event.target.value)
  const handleWebsiteChange = (event) => setWebsite(event.target.value)
  const handleCountryChange = (event) => setCountry(event.target.value)
  const handleStreetAddressChange = (event) => setStreetAddress(event.target.value)
  const handlePostalCodeChange = (event) => setPostalCode(event.target.value)
  const handleFirstChange = (event) => setFirst(event.target.value)
  const handleLastChange = (event) => setLast(event.target.value)

  const nameRef = useRef(null)
  const websiteRef = useRef(null)
  const phoneRef = useRef(null)
  const countryRef = useRef(null)
  const streetAddressRef = useRef(null)
  const postalCodeRef = useRef(null)
  const firstRef = useRef(null)
  const lastRef = useRef(null)

  const resetError = (event) => {
    ReactTooltip.hide(event.target)
    setError({})
  }

  useEffect(() => {
    if (Object.keys(error).length > 0) {
      console.log('Validation error', error)
    }
    if ('name' in error) {
      ReactTooltip.show(nameRef.current)
    }
    if ('website' in error) {
      ReactTooltip.show(websiteRef.current)
    }
    if ('phone' in error) {
      ReactTooltip.show(phoneRef.current)
    }
    if ('postalCode' in error) {
      ReactTooltip.show(postalCodeRef.current)
    }
    if ('streetAddress' in error) {
      ReactTooltip.show(streetAddressRef.current)
    }
    if ('first' in error) {
      ReactTooltip.show(firstRef.current)
    }
    if ('last' in error) {
      ReactTooltip.show(lastRef.current)
    }
  }, [error])

  const validateWebsite = () => {
    return true
  }

  const validatePhone = () => {
    return true
  }

  const validatePostalCode = async ():Promise<boolean> => {
    return Axios.get('/grovvup/setup/zip/validate/' + postalCode + '/' + country).then(res => {
      if (res.status !== 200) {
        console.warn('Postal code validating failed')
        return false
      }
      console.log('Postal code validated OK')
      return true
    }).catch(error => {
      console.error('Error in promise', error)
      return false
    })
  }

  const validateStreetAddress = () => {
    return true
  }

  const formValidate = async ():Promise<boolean> => {
    if (name === '') {
      setError({ name: 'Please, submit business name' })
    } else if (website !== '' && !validateWebsite()) {
      setError({ website: 'Invalid website' })
    } else if (phone !== '' && !validatePhone()) {
      setError({ phone: 'Invalid phone number' })
    } else if (typeof postalCode === 'undefined' || postalCode === '' || !await validatePostalCode()) {
      setError({ postalCode: 'Invalid zip code' })
    } else if (typeof streetAddress === 'undefined' || streetAddress === '' || !validateStreetAddress()) {
      setError({ streetAddress: 'Invalid street address' })
    } else if (first === '') {
      setError({ first: 'Please, submit your first name' })
    } else if (last === '') {
      setError({ last: 'Please, submit your last name' })
    } else {
      return true
    }
    return false
  }

  const handleValidate = () => formValidate()

  const taskData = () => ({
    ...data,
    name,
    website,
    country,
    streetAddress,
    phone,
    postalCode,
    first,
    last
  })

  const handleSubmit = () => ({
    'task-basic-data': taskData()
  })

  const handleComplete = () => {
    props.businessSetupSaveTask({
      ...props.task,
      completed: 1,
      data: taskData()
    })
    props.onDone()
  }

  const Countries = {
    [types.Country.UnitedStates]: 'United States',
    [types.Country.GreatBritain]: 'Great Britain',
    [types.Country.Canada]: 'Canada',
    [types.Country.Australia]: 'Australia'
  }

  return (
    <div className="task task-basic">
      <Header task={props.task.name}/>

      <div className="content">
        <div className="section">
          <h2>Company Information</h2>
          <div className="line line-general">
            <div className="field textfield">
              <input
                type="text"
                placeholder="Name of Your Business"
                defaultValue={name}
                onChange={handleNameChange}
                {...('name' in error ? {
                  'data-tip': error.name,
                  'data-type': 'error',
                  'data-effect': 'solid',
                  ref: nameRef,
                  onFocus: resetError
                } : {})}/>
            </div>
          </div>
          <div className="line line-company-contacts">
            <div className="field textfield">
              <input
                type="text"
                placeholder="Company website"
                defaultValue={website}
                onChange={handleWebsiteChange}
                {...('website' in error ? {
                  'data-tip': error.website,
                  'data-type': 'error',
                  'data-effect': 'solid',
                  ref: websiteRef,
                  onFocus: resetError
                } : {})}/>
            </div>
            <div className="field textfield">
              <PhoneInput
                placeholder="Main Phone Number *"
                value={phone === '' ? '1' : phone}
                onChange={setPhone}
                country={(country || 'us').toLowerCase()}
                onlyCountries={Object.values(types.Country).map(country => country.toLowerCase())}
                countryCodeEditable={false}
                {...('phone' in error ? {
                  'data-tip': error.phone,
                  'data-type': 'error',
                  'data-effect': 'solid',
                  ref: phoneRef,
                  onFocus: resetError
                } : {})}/>
            </div>
          </div>
        </div>
        <div className="section">
          <h2>Company Address</h2>
          <div className="line">
            <div className="field textfield">
              <select
                name="country"
                placeholder="Country"
                defaultValue={country}
                onChange={handleCountryChange}
                {...('country' in error ? {
                  'data-tip': error.country,
                  'data-type': 'error',
                  'data-effect': 'solid',
                  ref: countryRef,
                  onFocus: resetError
                } : {})}
              >
                <option key={-1} disabled>Country</option>
                {Object.values(types.Country).map(code => <option value={code} key={code}>{Countries[code]}</option>)}
              </select>
            </div>
          </div>
          <div className="line line-address">
            <div className="field textfield">
              <input
                type="text"
                name="streetAddress"
                placeholder="Street address *"
                value={streetAddress}
                onChange={handleStreetAddressChange}
                {...('streetAddress' in error ? {
                  'data-tip': error.streetAddress,
                  'data-type': 'error',
                  'data-effect': 'solid',
                  ref: streetAddressRef,
                  onFocus: resetError
                } : {})}/>
            </div>
            <div className="field textfield">
              <input
                type="text"
                name="postalCode"
                placeholder="Zip Code *"
                value={postalCode}
                onChange={handlePostalCodeChange}
                {...('postalCode' in error ? {
                  'data-tip': error.postalCode,
                  'data-type': 'error',
                  'data-effect': 'solid',
                  ref: postalCodeRef,
                  onFocus: resetError
                } : {})}/>
            </div>
          </div>
        </div>
        <div className="section">
          <h2>Your information</h2>
          <div className="line line-owner">
            <div className="field textfield">
              <input
                type="text"
                placeholder="Your First name *"
                value={first}
                onChange={handleFirstChange}
                {...('first' in error ? {
                  'data-tip': error.first,
                  'data-type': 'error',
                  'data-effect': 'solid',
                  ref: firstRef,
                  onFocus: resetError
                } : {})}/>
            </div>
            <div className="field textfield">
              <input
                type="text"
                placeholder="Last name *"
                value={last}
                onChange={handleLastChange}
                {...('last' in error ? {
                  'data-tip': error.last,
                  'data-type': 'error',
                  'data-effect': 'solid',
                  ref: lastRef,
                  onFocus: resetError
                } : {})}/>
            </div>
          </div>
          <div className="line line-email">
            <input
              type="text"
              placeholder="E-mail"
              value={data.mail}
              readOnly
              disabled
            />
          </div>
        </div>

        <div className="actions">
          <FormButton text="Cancel" className="btn-cancel" onClick={props.onDone}/>
          <FormButton
            text="Finish task"
            onValidate={handleValidate}
            beakidElement='task-basic-submit'
            onSubmit={handleSubmit}
            onComplete={handleComplete}
          />
        </div>
      </div>

      {Object.keys(error).length > 0 && <ReactTooltip />}

    </div>
  )
}

const mapStateToProps = (state) => ({
  businessSetup: state.businessSetup
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    ...businessSetupActions
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(TaskBasic)
