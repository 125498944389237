import React from 'react'
import { Invoice } from './Invoice'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as businessBillingActions from '../../actions'
import * as types from 'Scenes/BusinessBilling/types'
import './styles.scss'

type InvoiceListProps = types.PropsWithBusinessBillingState & types.BusinessBillingActions

function InvoiceList (props: InvoiceListProps) {
  return (
    <div className="invoice-list">
      <div className="header">
        <div className="cell number">Invoice No</div>
        <div className="cell created">Created</div>
        <div className="cell status">Status</div>
        <div className="cell product">Business Plan</div>
        <div className="cell amount-due">Amount</div>
      </div>
      <div className="list">
        {props.businessBilling.invoices.map((invoice, index) => (
          <Invoice invoice={invoice} key={index}/>
        ))}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  businessBilling: state.businessBilling
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    ...businessBillingActions
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceList)
