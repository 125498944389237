import React from 'react'
import './styles.scss'
import classNames from 'classnames'

type Format = 'money' | 'number' | '%'

export type Metric = {
  name: string
  values: {
    current: number
    previous?: number
    currency?: string
  }[]
  format?: Format
  lessIsGood?: boolean
}

type ChartTotalProps = {
  metrics: Metric[]
}

export default function ChartTotal (props: ChartTotalProps) {
  const format = (value: number, format: Format) => (
    format === 'money' || format === '%'
      ? new Intl.NumberFormat('en-US').format(value)
      : value
  )

  return (
    <div className="chart-total">
      <div className="metrics">
        {props.metrics.map((metric, index) => (
          <div className={classNames({
            metric: true,
            [metric.name.toLowerCase().replace(/[^a-z0-9]/gi, '-')]: true,
            'less-is-good': metric.lessIsGood ?? false
          })} key={index}>
            <div className="name">{metric.name}</div>
            <div className="values">
              {metric.values.map((value, valueIndex) => (
                <div className="item" key={valueIndex}>
                  <span className="currency table-cell">{value.currency ?? null}</span>
                  <span className="value table-cell">{format(value.current, metric.format)}</span>
                  {((value.previous ?? 0) > 0 && value.previous !== value.current) && (
                    <div className={classNames({
                      change: true,
                      'table-cell': true,
                      up: value.current > value.previous
                    })}>
                      <span className={classNames({
                        arrow: true,
                        'arrow-up': value.current > value.previous,
                        'arrow-down': value.current < value.previous
                      })}></span>
                      <span className="uprise">{format(Math.abs((value.current - value.previous) / value.previous), '%')} %</span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
