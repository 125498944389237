import React, { useMemo } from 'react'
import Plan from './Plan'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as businessSetupActions from 'Scenes/BusinessSetup/actions'
import * as types from 'Scenes/BusinessSetup/types'
import CustomizedSwitch from '../Switch'
import './styles.scss'

type BusinessBillingProps = {
  onComplete?: () => void
} & types.PropsWithBusinessSetupState & types.BusinessSetupActions

function PlanSelect (props: BusinessBillingProps) {
  const data = props.businessSetup.tasks[3].data as types.DataBilling

  const handlePlanSelected = planMachineName => {
    props.businessSetupPlanSelect(planMachineName)
    if ('onComplete' in props) {
      props.onComplete()
    }
  }

  const options = useMemo(() => {
    const allOptions = data.businessPlans.filter(plan => plan.status === 'public').reduceRight((result, plan) => {
      return [
        ...result,
        ...plan.options
          .filter(option => result.findIndex(
            optionInResult => optionInResult.machineName === option.machineName
          ) === -1)
      ]
    }, [])

    return allOptions.map(option => ({
      ...option,
      byPlan: data.businessPlans.filter(plan => plan.status === 'public')
        .map(
          plan => plan.options.findIndex(
            planOption => planOption.machineName === option.machineName
          ) !== -1
        )
    }))
  }, [data.businessPlans])

  console.log('options', options)

  return (
    <div className="plan-select">
      <div className="main-section">
        <div className="help">
          <h2>Choose your payment plan</h2>
        </div>

        <div className="period">
          <label>Monthly</label>
          <CustomizedSwitch
            checked={data.period === 'yearly'}
            onChange={() => props.businessSetupBillingPeriodSet(data.period === 'monthly' ? 'yearly' : 'monthly')}
          />
          <label>Yearly<span className="discount">Get 10% off</span></label>
        </div>

        <div className="plans">
          {data.businessPlans.filter(plan => plan.status === 'public').map((plan, index) => (
            <Plan plan={plan} key={index} onSelected={handlePlanSelected}/>
          ))}
        </div>
        <div className="details">
          <a href="https://beakid.com/for-business#pricing" target="_blank" rel="noreferrer">Learn more</a>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  businessSetup: state.businessSetup
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    ...businessSetupActions
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(PlanSelect)
