import React from 'react'
import * as types from 'Scenes/FormEditor/types'
import QuestionView from './QuestionView'

type QuestionProps = {
  entity: types.Question,
  onEdit: () => void
}

function FileQuestionView (props: QuestionProps) {
  return (
    <QuestionView { ...props }>
      <div className="line">
        <input type="text" placeholder="File upload" readOnly={true}/>
      </div>
      <div className="line">
        <input type="text" placeholder="Help text" readOnly value={props.entity.helpText}/>
      </div>
    </QuestionView>
  )
}

export default FileQuestionView
