import React from 'react'
import * as types from 'Scenes/FormEditor/types'
import NewQuestion from './components/NewQuestion'
import TextQuestionView from './components/TextQuestionView'
import TextQuestionInput from './components/TextQuestionInput'
import LongQuestionView from './components/LongQuestionView'
import LongQuestionInput from './components/LongQuestionInput'
import DropdownQuestionView from './components/DropdownQuestionView'
import DropdownQuestionInput from './components/DropdownQuestionInput'
import RadiosQuestionView from './components/RadiosQuestionView'
import RadiosQuestionInput from './components/RadiosQuestionInput'
import CheckboxesQuestionView from './components/CheckboxesQuestionView'
import CheckboxesQuestionInput from './components/CheckboxesQuestionInput'
import FileQuestionView from './components/FileQuestionView'
import FileQuestionInput from './components/FileQuestionInput'
import classNames from 'classnames'
import './styles.scss'
import SignatureQuestionView from './components/SignatureQuestionView'
import SignatureQuestionInput from './components/SignatureQuestionInput'

export enum QuestionMode {
  View = 'view',
  Edit = 'edit'
}

export type QuestionProps = {
  entity: types.Question,
  mode: QuestionMode,
  error?: {
    title?: string
  },
  onChange: (question: types.Question) => void,
  onDuplicate: () => void,
  onEdit: () => void,
  onDelete: () => void,
  onResetError?: () => void
}

function QuestionComponent (props: QuestionProps) {
  const handleQuestionEdit = event => {
    if (!event.target.matches('input[type="checkbox"]') && !event.target.matches('input[type="button"]')) {
      props.onEdit()
    }
  }

  const chooseHandler = () => {
    switch (props.entity.type) {
      case null:
        return <NewQuestion { ...props }/>

      case types.QuestionType.Text:
        return props.mode === QuestionMode.View
          ? <TextQuestionView { ...props }/>
          : <TextQuestionInput { ...props }/>

      case types.QuestionType.Long:
        return props.mode === QuestionMode.View
          ? <LongQuestionView { ...props }/>
          : <LongQuestionInput { ...props }/>

      case types.QuestionType.Dropdown:
        return props.mode === QuestionMode.View
          ? <DropdownQuestionView { ...props }/>
          : <DropdownQuestionInput { ...props }/>

      case types.QuestionType.Radios:
        return props.mode === QuestionMode.View
          ? <RadiosQuestionView { ...props }/>
          : <RadiosQuestionInput { ...props }/>

      case types.QuestionType.Checkboxes:
        return props.mode === QuestionMode.View
          ? <CheckboxesQuestionView { ...props }/>
          : <CheckboxesQuestionInput { ...props }/>

      case types.QuestionType.File:
        return props.mode === QuestionMode.View
          ? <FileQuestionView { ...props }/>
          : <FileQuestionInput { ...props }/>

      case types.QuestionType.Signature:
        return props.mode === QuestionMode.View
          ? <SignatureQuestionView { ...props }/>
          : <SignatureQuestionInput { ...props }/>
    }
  }

  return (
    <div
      className={classNames({
        question: true,
        [`question-${props.entity.type}`]: true
      })}
      onClick={handleQuestionEdit}
    >
      {chooseHandler()}
    </div>
  )
}

export default QuestionComponent
