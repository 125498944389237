import React, { useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as businessSetupActions from 'Scenes/BusinessSetup/actions'
import * as types from 'Scenes/BusinessSetup/types'
import FormButton from 'Beakid/FormButton'
import ReactTooltip from 'react-tooltip'
import Datetime from 'react-datetime'
import moment from 'moment'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid' // a plugin!
import interactionPlugin from '@fullcalendar/interaction'
import Popup from 'react-popup'
import SpinnerPopup from 'reactjs-popup'
import { SpinnerCircular } from 'spinners-react'
import Header from '../Header'
import './styles.scss'

type TaskClassProps = {
  task: types.Task
  onDone: () => void
} & types.PropsWithBusinessSetupState & types.BusinessSetupActions

interface TaskClassError {
  name?: string
  from?: string
  to?: string
  cost?: string
  events?: string
}

function TaskClass (props: TaskClassProps) {
  const basicData = props.businessSetup.tasks[0].data as types.DataBasic
  const data = props.task.data as types.DataClass
  const [name, setName] = useState(data.name)
  const [from, setFrom] = useState<number>(data.from)
  const [to, setTo] = useState<number>(data.to)
  const [events, setEvents] = useState(
    data.events
      ? data.events?.map(event => (
        { start: new Date(event.start * 1000), end: new Date(event.end * 1000) }
      ))
      : []
  )
  const [costStr, setCostStr] = useState<string>(data.cost?.toString())
  const classroomNidRef = useRef<string | number>()
  classroomNidRef.current = data.nid

  const [error, setError] = useState<TaskClassError>({})
  const [showWaitPopup, setShowWaitPopup] = useState<boolean>(false)

  const handleNameChange = (event) => setName(event.target.value)

  const nameRef = useRef(null)
  const fromRef = useRef(null)
  const toRef = useRef(null)
  const costRef = useRef(null)
  const eventsRef = useRef(null)

  const currencies = {
    [types.Country.UnitedStates]: '$',
    [types.Country.Australia]: 'A$',
    [types.Country.Canada]: 'C$',
    [types.Country.GreatBritain]: 'GBP'
  }

  const resetError = (event) => {
    ReactTooltip.hide(event.target)
    setError({})
  }

  useEffect(() => {
    if (Object.keys(error).length > 0) {
      console.log('Validation error', error)
    }
    if ('name' in error) {
      ReactTooltip.show(nameRef.current)
    }
    if ('from' in error) {
      ReactTooltip.show(fromRef.current)
    }
    if ('to' in error) {
      ReactTooltip.show(toRef.current)
    }
    if ('cost' in error) {
      ReactTooltip.show(costRef.current)
    }
    if ('events' in error) {
      ReactTooltip.show(eventsRef.current)
    }
  }, [error])

  const cost = useMemo(() => {
    const value = parseFloat(costStr)
    if (!Number.isNaN(value)) {
      return costStr
    }
    return cost
  }, [costStr])

  const schedule = useMemo(() => {
    if (from === null || to === null) {
      return {
        lessons: 0,
        weeks: 0,
        byTime: []
      }
    }

    const byTime: { [time: string]: string[] } = {}
    let lessons = 0
    let weeks = 0

    const end = moment.unix(to)
    const date = moment.unix(from)

    while (date.isBefore(end)) {
      weeks++

      for (const index in events) {
        const event = events[index]

        const weekdayShift = moment.weekdays().findIndex(item => item === moment(event.start).format('dddd'))
        const eventStart = moment(date).add(weekdayShift, 'd')
        if (eventStart.isAfter(end)) {
          break
        }

        const time = `${eventStart.format('h:mm a')} - ${moment(event.end).format('h:mm a')}`
        const weekday = eventStart.format('dddd')

        byTime[time] = (byTime[time] || []).findIndex(item => item === weekday) === -1
          ? [...(byTime[time] || []), weekday]
          : byTime[time]
        lessons++
      }

      date.add(1, 'w')
    }

    return {
      lessons,
      weeks,
      byTime: Object.entries(byTime)
        .map(([time, weekdays]) => ({
          time,
          weekdays: weekdays.sort((a, b) => (moment.weekdays().indexOf(a) > moment.weekdays().indexOf(b) ? 1 : -1))
        }))
    }
  }, [events, from, to])

  const formValidate = (): boolean => {
    if (name === undefined || name.trim() === '') {
      setError({ name: 'Please, submit business name' })
    } else if (from === undefined) {
      setError({ from: 'Please, submit class start date' })
    } else if (to === undefined) {
      setError({ to: 'Please, submit class end date' })
    } else if (to < from) {
      setError({ to: 'Class end date has to be greater than start date' })
    } else if (events.length === 0) {
      setError({ events: 'Please, choose time for at least one lesson' })
    } else if (Number.isNaN(parseFloat(costStr)) || parseFloat(costStr) < 0) {
      setError({ cost: 'Please submit valid tuition cost' })
    } else {
      return true
    }
    return false
  }

  const handleValidate = () => formValidate()

  const taskData = (): types.DataClass => ({
    name,
    from,
    to,
    cost,
    events: events.map(event => ({
      day: moment(event.start).isoWeekday() - 1,
      start: event.start.getTime() / 1000,
      end: event.end.getTime() / 1000
    }))
  })

  const handlePreviewClick = () => {
    console.log('setShowWaitPopup(true)')
    setShowWaitPopup(true)
  }

  const handlePreviewComplete = () => {
    setShowWaitPopup(false)

    const businessNid = basicData.nid

    const timerId = setInterval(() => {
      const classroomNid = classroomNidRef.current
      if (classroomNid !== undefined) {
        clearInterval(timerId)
        console.log('classroomNid', classroomNid)

        Popup.create({
          title: 'Class preview',
          className: 'class-preview-popup',
          content: <>
            <p>Click below to open class preview page in  a new tab.  Remember you will have the ability to create individual pages fro classes or/and integrate widgets directly into your site.  Click on Add to Cart and Check out to test our parent portal as well.  Then simply go back to this tab to finish the setup.</p>
            <a className="preview btn" href={`/portal/${businessNid}/class/${classroomNid}`} target="_blank" rel="noreferrer">Open preview in new tab</a>
          </>
        })
      }
    }, 500)
  }

  const handleSubmit = () => ({
    'task-class-data': taskData()
  })

  const handleComplete = () => {
    props.businessSetupSaveTask({
      ...props.task,
      completed: 1,
      data: taskData()
    })
    props.onDone()
  }

  return (
    <div className="task task-class">
      <Header task={props.task.name}/>

      <div className="content">

        <div className="section-wrapper">
          <div className="section">
            <h2>Name of Class / Program</h2>
            <div className="line line-general">
              <div className="field textfield">
                <input
                  type="text"
                  placeholder="Camp Session 1"
                  defaultValue={name}
                  onChange={handleNameChange}
                  {...('name' in error ? {
                    'data-tip': error.name,
                    'data-type': 'error',
                    'data-effect': 'solid',
                    ref: nameRef,
                    onFocus: resetError
                  } : {})}/>
              </div>
            </div>
          </div>

          <div className="section">
            <h2>Total tuition cost, <span className="currency">{currencies[basicData.country]}</span></h2>
            <div className="line line-cost">
              <div className="field textfield">
                <input
                  type="text"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault()
                    }
                  }}
                  onChange={ev => setCostStr(ev.target.value)}
                  value={cost}
                  {...('cost' in error ? {
                    'data-tip': error.cost,
                    'data-type': 'error',
                    'data-effect': 'solid',
                    ref: costRef,
                    onFocus: resetError
                  } : {})}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="section-wrapper">
          <div className="section">
            <h2>Class start date</h2>
            <div className="line line-dates">
              <div
                className="field textfield"
                {...('from' in error ? {
                  'data-tip': error.from,
                  'data-type': 'error',
                  'data-effect': 'solid',
                  ref: fromRef
                } : {})}
              >
                <Datetime
                  value={from > 0 ? moment.unix(from) : null}
                  {...('from' in error ? {
                    onFocus: resetError
                  } : {})}
                  dateFormat='MM/DD/YYYY'
                  timeFormat={false}
                  closeOnSelect={true}
                  inputProps={{ placeholder: 'Start Date *' }}
                  onChange={value => {
                    if (!(typeof value === 'string' || value instanceof String)) {
                      setFrom(value.unix())
                    }
                  }}
                />
              </div>
            </div>
          </div>

          <div className="section">
            <h2>End date</h2>
            <div className="line line-dates">
              <div
                className="field textfield"
                {...('to' in error ? {
                  'data-tip': error.to,
                  'data-type': 'error',
                  'data-effect': 'solid',
                  ref: toRef
                } : {})}
              >
                <Datetime
                  value={from > 0 ? moment.unix(to) : null}
                  {...('to' in error ? {
                    onFocus: resetError
                  } : {})}
                  dateFormat='MM/DD/YYYY'
                  timeFormat={false}
                  closeOnSelect={true}
                  inputProps={{ placeholder: 'End Date *' }}
                  onChange={value => {
                    if (!(typeof value === 'string' || value instanceof String)) {
                      setTo(value.unix())
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="section section-calendar">
          <h2>Days & Time</h2>
          <div
            className="line"
            {...('events' in error ? {
              'data-tip': error.events,
              'data-type': 'error',
              'data-effect': 'solid',
              ref: eventsRef,
              onFocus: resetError
            } : {})}
          >
            <FullCalendar
              plugins={[timeGridPlugin, interactionPlugin]}
              initialView="timeGridOneWeek"
              views={{
                timeGridOneWeek: {
                  type: 'timeGrid',
                  duration: { weeks: 1 }
                }
              }}
              dayHeaderFormat={{ weekday: 'short' }}
              timeZone='UTC'
              scrollTime={'09:00:00'}
              allDaySlot={false}
              headerToolbar={false}

              selectable={true}
              selectOverlap={false}
              select={({ start, end }) => setEvents([...events, { start, end }])}
              selectAllow={info => moment(info.start).utc().day() === moment(info.end).utc().day()}
              events={events}
              eventClick={info => {
                setEvents([...events.filter(test => test.start.valueOf() !== info.event.start.valueOf())])
              }}
            />
          </div>
        </div>

        <div className="section-wrapper">
          <div className="section section-schedule">
            <h2>Your class schedule</h2>
            <div className="line line-schedule">
              <div className="overview">
                <span className="lessons count">{schedule.lessons}</span> lessons
                <span className="weeks count">{schedule.weeks}</span> weeks
              </div>
              <ul className="details">
                {schedule.byTime.map((rec, key) => (
                  <li key={key}>
                    <span className="weekdays">
                      {rec.weekdays.length > 1
                        ? [rec.weekdays.slice(0, -1).join(', '), rec.weekdays.slice(-1)].join(' and ')
                        : rec.weekdays.join(', ')}
                    </span>
                    at
                    <span className="time">{rec.time}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {events && (
            <div className="section section-preview">
              <h2>Preview your class</h2>
              <div className="line">
                <FormButton
                  text="Preview Class"
                  onValidate={handleValidate}
                  beakidElement='task-class-submit'
                  onClick={handlePreviewClick}
                  onSubmit={handleSubmit}
                  onComplete={handlePreviewComplete}
                />
              </div>
            </div>
          )}
        </div>

        <div className="section">
          <div className="line line-help">
            If you need more advanced configuration, such as multiple & recurring sessions per class, complex payment plans (monthly, tuitions, etc), you will have it later, after finishing initial setup
          </div>
        </div>

        <div className="actions">
          <FormButton text="Cancel" className="btn-cancel" onClick={props.onDone}/>
          <FormButton
            text="Finish task"
            onValidate={handleValidate}
            beakidElement='task-class-submit'
            onSubmit={handleSubmit}
            onComplete={handleComplete}
          />
        </div>
      </div>

      <Popup/>
      <SpinnerPopup open={showWaitPopup} className="business-billing-wait-popup">
        <SpinnerCircular />
      </SpinnerPopup>

      {Object.keys(error).length > 0 && <ReactTooltip />}

    </div>
  )
}

const mapStateToProps = (state) => ({
  businessSetup: state.businessSetup
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    ...businessSetupActions
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(TaskClass)
