import React, { useState } from 'react'
import Cell from '../Cell'
import ReactECharts from 'echarts-for-react'
import { connect } from 'react-redux'
import * as types from '../../types'
import ChartTotal, { Metric } from '../ChartTotal'
import { filterByPeriod } from '../helpers'
import { EmptyState } from '../EmptyState'
import moment from 'moment'

type EnrollmentsChartProps = types.PropsWithBusinessDashboardState

function EnrollmentsChart (props: EnrollmentsChartProps) {
  const [period, setPeriod] = useState<types.Period>('ytd')

  const enrollments = props.businessDashboard.analytics.data[props.businessDashboard.currentBusiness].enrollments[period]

  if (!enrollments || enrollments.length === 0) {
    return <Cell title="Enrollments">
      <EmptyState message="Currently there is no information to display"/>
    </Cell>
  }

  const data = enrollments.filter(filterByPeriod(period, true))

  const prevData = enrollments.filter(filterByPeriod(period, false))

  const tooltipDateFormat = period === 'mtd'
    ? 'MMM DD, YYYY'
    : 'MMMM YYYY'

  const metrics: Metric[] = [
    {
      name: 'Enrollments',
      format: 'number',
      values: [{
        current: data.reduce((result, rec) => (result + rec[1]), 0),
        previous: prevData.reduce((result, rec) => (result + rec[1]), 0)
      }]
    }
  ]

  const getOption = () => ({
    xAxis: [{ type: 'category', data: data.map(rec => moment(rec[0]).format(period === 'mtd' ? 'Do' : 'MMM')) }],
    yAxis: [{ type: 'value' }],
    series: [
      {
        name: 'Enrollments',
        type: 'line',
        smooth: true,
        data: data.map(rec => ({
          value: rec[1],
          date: rec[0]
        }))
      }
    ],
    tooltip: {
      trigger: 'item',
      formatter: (params) => (
        `${moment(params.data.date).format(tooltipDateFormat)}: ${params.seriesName}: ${params.value}`
      )
    }
  })

  return (
    <Cell title="New enrollments" onPeriodChange={setPeriod} period={period}>
      <ChartTotal metrics={metrics}/>
      <ReactECharts
        option={getOption()}
        notMerge={true}
        lazyUpdate={true}
      />
    </Cell>
  )
}

const mapStateToProps = (state) => ({
  businessDashboard: state.businessDashboard
})

export default connect(mapStateToProps)(EnrollmentsChart)
