import { Question } from './question'
export * from './question'

export enum FormEditorMode {
  Edit = 'edit',
  New = 'new'
}

export type FormEditorState = {
  title: string
  description: string
  lockoutDate: string
  questions: Question[]
  mode?: FormEditorMode
}

export type PropsWithFormEditorState = {
  formEditor: FormEditorState
}

export type PropsWithFormEditorActions = {
  adoptState: (state: FormEditorState) => void,
  newQuestion: () => void,
  updateQuestion: (index: number, question: Question) => void,
  setTitle: (value: string) => void,
  setDescription: (value: string) => void,
  setLockoutDate: (value: string) => void,
  duplicateQuestion: (index: number) => void,
  deleteQuestion: (index: number) => void,
  reset: () => void,
  sortQuestions: (oldIndex: number, newIndex: number) => void
}
