import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import * as formEditorActions from './actions'
import FormButton from 'Beakid/FormButton'
import Question, { QuestionMode } from './components/Question'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import * as types from './types'
import ReactTooltip from 'react-tooltip'
import './styles.scss'
import ReactDatetimeClass from 'react-datetime'
import moment from 'moment'

type FormEditorProps = {
  state?: {
    formEditor: types.FormEditorState
  }
} & types.PropsWithFormEditorState & types.PropsWithFormEditorActions

type FormEditorError = {
  title?: string,
  description?: string,
  lockoutDate?: string,
  questions?: {
    [index: number]: {
      title?: string,
      type?: string,
      helpText?: string,
      options?: string,
    }
  }
}

const DragHandle = SortableHandle(() => <span className="sortable-handle">:::</span>)

const SortableQuestion = SortableElement(({ value }) => (
  <li className="question-wrapper">
    <div className="sortable-element">
      <DragHandle />
      {value}
    </div>
  </li>
))

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SortableQuestionList = SortableContainer(({ children }) => {
  return (
    <ul>
      {children.map((value, index) => (
        <SortableQuestion key={`item-${index}`} index={index} value={value} />
      ))}
    </ul>
  )
})

function FormEditor (props: FormEditorProps) {
  const [stateAdopted, setStateAdopted] = useState<boolean>(false)
  const [editingQuestion, setEditingQuestion] = useState<number | null>(null)

  // error processing
  const [error, setError] = useState<FormEditorError>({})
  const titleRef = useRef<HTMLInputElement>(null)
  const descriptionRef = useRef<HTMLInputElement>(null)
  const resetError = (event) => {
    ReactTooltip.hide(event.target)
    setError({})
  }

  // display tooltip on validation error
  useEffect(() => {
    if ('title' in error) {
      ReactTooltip.show(titleRef.current)
      titleRef.current.scrollIntoView()
    }
    if ('description' in error) {
      ReactTooltip.show(descriptionRef.current)
      descriptionRef.current.scrollIntoView()
    }
    if ('questions' in error) {
      const key = Object.keys(error.questions).pop()
      setEditingQuestion(+key)
    }
  }, [error])

  // prevent stale tooltip over a hidden question input
  useEffect(() => {
    ReactTooltip.hide()
  }, [editingQuestion])

  setInterval(() => {
    ReactTooltip.rebuild()
  }, 1000)

  const handleAddQuestion = () => {
    props.newQuestion()
    setEditingQuestion(props.formEditor.questions.length)
  }
  const handleTitleChange = event => props.setTitle(event.target.value)
  const handleDescriptionChange = event => props.setDescription(event.target.value)
  const handleQuestionChange = (index: number, question: types.Question) => props.updateQuestion(index, question)
  const handleDuplicate = (index: number) => {
    props.duplicateQuestion(index)
    setEditingQuestion(0)
  }
  const handleDelete = (index: number) => {
    props.deleteQuestion(index)
    setEditingQuestion(null)
  }
  const handleCancel = () => {}

  // TODO: rewrite
  const handleValidate = ():boolean => {
    if (props.formEditor.title === '') {
      setError({ title: 'Please, submit form title' })
    } else if (props.formEditor.description === '') {
      setError({ description: 'Please, submit form description' })
    } else {
      const questionIdx = props.formEditor.questions.findIndex(question => question.title === '')
      if (questionIdx !== -1) {
        setError({ questions: { [questionIdx]: { title: 'Please, choose question title' } } })
      } else {
        const questionIdx = props.formEditor.questions.findIndex(question => question.type === null)
        if (questionIdx !== -1) {
          setError({ questions: { [questionIdx]: { type: 'Please, choose question type' } } })
        } else {
          return true
        }
      }
    }
    return false
  }
  const handleSubmit = () => {}
  const haneleSortEnd = ({ oldIndex, newIndex }) => props.sortQuestions(oldIndex, newIndex)
  const handleLockoutDateChange = (value: string) => props.setLockoutDate(value)

  useEffect(() => {
    if ('state' in props && 'formEditor' in props.state) {
      console.log('Adopting state', props.state)
      props.adoptState(props.state.formEditor)
      setStateAdopted(true)
    }
  }, [props.state])

  useEffect(() => {
    if (props.formEditor.mode === types.FormEditorMode.New && !('state' in props && 'formEditor' in props.state)) {
      console.log('resetting state')
      props.reset()
    }
  }, [])

  console.log('props.formEditor.lockoutDate', props.formEditor.lockoutDate)
  console.log('datetime', props.formEditor.lockoutDate !== null ? new Date(props.formEditor.lockoutDate) : null)

  return (
    <div className="form-editor">
      <div className="section">
        <div className="line">
          {stateAdopted && (
            <ReactDatetimeClass
              value={props.formEditor.lockoutDate !== null ? moment(props.formEditor.lockoutDate) : null}
              {...('lockoutDate' in error ? {
                onFocus: resetError
              } : {})}
              dateFormat='MMM D, YYYY'
              timeFormat={false}
              closeOnSelect={true}
              inputProps={{ placeholder: 'Lockout date', autoComplete: 'off' }}
              onChange={handleLockoutDateChange}
            />
          )}
        </div>
        <div className="line">
          <input
            type="text"
            placeholder="Form Title"
            onChange={handleTitleChange}
            value={props.formEditor.title}
            {...('title' in error ? {
              'data-tip': error.title,
              'data-type': 'error',
              'data-effect': 'solid',
              'data-scroll-hide': false,
              ref: titleRef,
              onFocus: resetError
            } : {})}
          />
        </div>
        <div className="line">
          <input
            type="text"
            placeholder="Form Description"
            onChange={handleDescriptionChange}
            value={props.formEditor.description}
            {...('description' in error ? {
              'data-tip': error.description,
              'data-type': 'error',
              'data-effect': 'solid',
              'data-scroll-hide': false,
              ref: descriptionRef,
              onFocus: resetError
            } : {})}
          />
        </div>
      </div>

      <div className="questions">
        <SortableQuestionList
          helperClass="form-editor-question-sortable-helper"
          useDragHandle={true}
          onSortEnd={haneleSortEnd}
        >
          {props.formEditor.questions.map((question, index) => (
            <Question
              entity={question}
              mode={editingQuestion === index ? QuestionMode.Edit : QuestionMode.View}
              key={index}
              onChange={updated => handleQuestionChange(index, updated)}
              onDuplicate={() => handleDuplicate(index)}
              onEdit={() => setEditingQuestion(index)}
              onDelete={() => handleDelete(index)}
              {...('questions' in error && index in error.questions ? {
                error: error.questions[index],
                onResetError: () => setError({})
              } : {})}
            />
          ))}
        </SortableQuestionList>
      </div>

      <div className="actions">
        <FormButton onClick={handleAddQuestion} text="+ Add Question" className="btn-add-question" />
      </div>

      <div className="actions submit">
        <FormButton
          text="Cancel"
          className="btn-cancel"
          onClick={handleCancel}
          beakidElement='cancel'
        />
        <FormButton
          text="Save"
          className="btn-submit"
          onValidate={handleValidate}
          onClick={handleSubmit}
          beakidElement='save'
        />
      </div>
      {Object.keys(error).length > 0 && <ReactTooltip />}
    </div>
  )
}

const mapStateToProps = (state: { formEditor: any }) => ({
  formEditor: state.formEditor
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => (
  bindActionCreators({
    ...formEditorActions
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(FormEditor)
