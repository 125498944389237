import React from 'react'
import QuestionInput, { QuestionInputProps } from './QuestionInput'
import OptionsInput from './OptionsInput'
import { QuestionOption } from 'Scenes/FormEditor/types'

type QuestionProps = QuestionInputProps

function RadiosQuestionInput (props: QuestionProps) {
  const handleHelpTextChange = event => props.onChange({ ...props.entity, helpText: event.target.value })
  const handleOptionsChange = (options: QuestionOption[]) => props.onChange({ ...props.entity, options })

  return (
    <QuestionInput { ...props }>
      <div className="line line-help-text">
        <input type="text" onChange={handleHelpTextChange} placeholder="Help text" defaultValue={props.entity.helpText} />
      </div>
      <OptionsInput options={props.entity.options} onChange={handleOptionsChange}/>
    </QuestionInput>
  )
}

export default RadiosQuestionInput
