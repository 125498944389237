import FormButton from 'Beakid/FormButton'
import React from 'react'
import * as types from 'Scenes/BusinessSetup/types'
import './styles.scss'

type UsBankAccountProps = {
  method: types.PaymentMethodBankAccount
  isPrimary: boolean
}

function UsBankAccount (props: UsBankAccountProps) {
  return (
    <div className="business-billing-us-bank-account">
      <div className="cell account">
        <label>Us Bank Account</label>
        <div className="value">
          {/* <span className="brand"></span> */}
          <span className="last4">**** {props.method.last4}</span>
        </div>
      </div>
      <div className="cell bank-name">
        <label>Bank name</label>
        <div className="value">
          {props.method.bankName}
        </div>
      </div>
      <div className="cell primary">
        {props.isPrimary ? (
          <div className="primary-indicator">Primary method</div>
        ) : (<>
          <FormButton text="Make primary" beakidElement="card-primary" className="btn-make-primary"/>
        </>)}
      </div>
      {!props.isPrimary && <FormButton text="" beakidElement="card-delete" className="btn-delete"/>}
    </div>
  )
}

export default UsBankAccount
