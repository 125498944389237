import React, { useMemo, useState } from 'react'
import Cell from '../Cell'
import ReactECharts from 'echarts-for-react'
import { connect } from 'react-redux'
import * as types from '../../types'
import moment from 'moment'
import ChartTotal, { Metric } from '../ChartTotal'
import { fillGaps, filterByPeriod } from '../helpers'

type ReceivablesRefundsChartProps = types.PropsWithBusinessDashboardState

function ReceivablesRefundsChart (props: ReceivablesRefundsChartProps) {
  const [period, setPeriod] = useState<types.Period>('ytd')

  const receivablesRefunds = props.businessDashboard.analytics.data[props.businessDashboard.currentBusiness].receivablesRefunds[period]

  if (!receivablesRefunds || receivablesRefunds.length === 0) {
    return null
  }

  const currencies = useMemo(() => (
    [{ currency: props.businessDashboard.mainBusinessCurrency }, ...props.businessDashboard.franchisees]
      .map(franchise => franchise.currency.isoCode)
      .reduce((result, item) => (
        result.find(rec => rec === item) ? result : [...result, item]
      ), [])
      .filter(currency => currency in receivablesRefunds)
  ), [props.businessDashboard])

  const data = currencies.map(currency => ({
    currency,
    values: receivablesRefunds[currency]
      .filter(filterByPeriod(period, true))
  }))

  const completeData = fillGaps(data, period)

  const prevData = currencies.map(currency => ({
    currency,
    values: receivablesRefunds[currency]
      .filter(filterByPeriod(period, false))
  }))

  const metrics: Metric[] = completeData.length > 0 ? [
    {
      name: 'Receivables',
      format: 'money',
      values: currencies.map(currency => ({
        current: data.find(item => item.currency === currency).values.reduce((result, rec) => (result + rec[1]), 0),
        previous: prevData.find(item => item.currency === currency).values.reduce((result, rec) => (result + rec[1]), 0),
        currency
      }))
    },
    {
      name: 'Refunds',
      format: 'money',
      values: currencies.map(currency => ({
        current: data.find(item => item.currency === currency).values.reduce((result, rec) => (result + rec[2]), 0),
        previous: prevData.find(item => item.currency === currency).values.reduce((result, rec) => (result + rec[2]), 0),
        currency
      })),
      lessIsGood: true
    }
  ] : []

  const intl = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 })

  const tooltipDateFormat = period === 'mtd'
    ? 'MMM DD, YYYY'
    : 'MMMM YYYY'

  const palette = ['#0A4D68', '#088395', '#05BFDB', '#00FFCA', '#CB1C8D', '#E74646']

  const getOption = () => (completeData.length > 0 ? {
    xAxis: [
      {
        type: 'category',
        data: completeData.find(item => item.currency === currencies[0]).values.map(rec => moment(rec[0]).format(period === 'mtd' ? 'Do' : 'MMM')),
        interval: 1
      }
    ],
    yAxis: [{ type: 'value' }],
    series: [
      ...currencies.map(currency => ({
        name: 'Refunded',
        type: 'bar',
        stack: currency,
        data: completeData.find(item => item.currency === currency).values.map(rec => ({
          value: rec[2],
          date: rec[0],
          currency,
          itemStyle: {
            color: 'darkred'
          }
        })),
        colorBy: 'data'
      })),
      ...currencies.map((currency, index) => ({
        name: 'Paid',
        type: 'bar',
        stack: currency,
        data: completeData.find(item => item.currency === currency).values.map(rec => ({
          value: rec[1],
          date: rec[0],
          currency,
          itemStyle: {
            color: palette[index] ?? palette[0]
          }
        })),
        colorBy: 'data'
      }))
    ],
    tooltip: {
      trigger: 'axis',
      formatter: (params) => (
        `<center>${moment(params[0].data.date).format(tooltipDateFormat)}</center><br/>` +
        params.reverse().map(
          series => (
            `${series.seriesName}: ` +
            '<span style="float: right; margin-left: 12px;">' +
              `<span style="margin-right: 8px; color: ${series.color}; font-weight: bold;">${intl.format(series.value)}</span>` +
              `<span style="text-decoration: underline;">${series.data.currency}</span>` +
            '</span>'
          )
        ).join('<br/>')
      )
    }
  } : {})

  return (
    <Cell title="Receivables/Refunds" onPeriodChange={setPeriod} period={period}>
      <ChartTotal metrics={metrics}/>
      <ReactECharts
        option={getOption()}
        notMerge={true}
        lazyUpdate={true}
      />
    </Cell>
  )
}

const mapStateToProps = (state) => ({
  businessDashboard: state.businessDashboard
})

export default connect(mapStateToProps)(ReceivablesRefundsChart)
