import React from 'react'
import QuestionInput, { QuestionInputProps } from './QuestionInput'

type QuestionProps = QuestionInputProps

function SignatureQuestionInput (props: QuestionProps) {
  const handleHelpTextChange = event => {
    if ('onChange' in props) {
      props.onChange({ ...props.entity, helpText: event.target.value })
    }
  }

  return (
    <QuestionInput { ...props }>
      <div className="line line-description">
        <p>The question will allow user to sign the form</p>
        <p>Sigature picture will be saved to the filled form</p>
        <p>User name, IP address, browser information (guessed) and signature date/time will be collected</p>
        <p>Please mark this question REQUIRED if you would like it to be impossible to complete the form without a signature</p>
      </div>
      <div className="line line-help-text">
        <textarea onChange={handleHelpTextChange} placeholder="Signature prompt" defaultValue={props.entity.helpText} />
      </div>
    </QuestionInput>
  )
}

export default SignatureQuestionInput
