import React, { useState } from 'react'
import FormButton from 'Beakid/FormButton'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as businessSetupActions from 'Scenes/BusinessSetup/actions'
import * as types from 'Scenes/BusinessSetup/types'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import PaymentMethodList from './components/PaymentMethodList'
import ActivePaymentMethod from './components/ActivePaymentMethod'
import UsBankAccount from './components/UsBankAccount'
import Card from './components/Card'
import Popup from 'react-popup'
import SpinnerPopup from 'reactjs-popup'
import { SpinnerCircular } from 'spinners-react'
import './styles.scss'
import PlanSelect from './components/PlanSelect'
import Notification from './components/Notification'
import CurrentPlan from './components/CurrentPlan'
import Header from '../Header'

type TaskBillingProps = {
  task: types.Task
  onDone: () => void
} & types.PropsWithBusinessSetupState & types.BusinessSetupActions

const stripePromise = loadStripe(window.Drupal.settings.beakid.billing.stripe.keys.publishable)

declare var Drupal: {
  behaviors: {
    grovvupReactApp: {
      formSubmit: (formId: string, beakidElement: string, store: any, completeCallback: () => void) => void
    }
  }
}

let formId

function TaskBilling (props: TaskBillingProps) {
  const data = props.task.data as types.DataBilling
  // const [data, setData] = useState(props.task.data as types.DataBilling)
  const [showPaymentMethods, setShowPaymentMethods] = useState<boolean>(false)
  const [showPlanSelect, setShowPlanSelect] = useState<Boolean>(false)
  const [showWaitPopup, setShowWaitPopup] = useState<boolean>(false)

  const formValidate = (): boolean => true

  const handleValidate = () => formValidate()

  const taskData = () => (data)

  const handleSubmit = () => ({
    'task-billing-data': taskData()
  })

  const handleComplete = () => {
    props.businessSetupSaveTask({
      ...props.task,
      completed: 1,
      data: taskData()
    })
    props.onDone()
  }

  const getOptionByPlanMachineName = (planMachineName: string): types.SubscriptionOption => (
    data.businessPlans
      .reduce((options, plan) => ([...options, ...plan.subscriptionOptions]), [])
      .find((option: types.SubscriptionOption) => option.planMachineName === planMachineName)
  )

  const handleActivateSubscription = () => {
    const option = getOptionByPlanMachineName(data.activeBusinessPlan.machineName)

    const factory = window.BeakidReactFactory
    const FormContext = factory.FormContext

    const PopupContent = () => (
      <FormContext.Consumer>
        {drupalFormId => {
          formId = drupalFormId

          return (
            <div className="subscription-confirmation-popup">
              <div className="message">You are about to activate subscription:</div>
              <div className="subscription-title">{option.planTitle}</div>
            </div>
          )
        }}
      </FormContext.Consumer>
    )

    Popup.create({
      title: 'Confirmation',
      content: <PopupContent/>,
      buttons: {
        right: [{
          text: 'Cancel',
          className: 'cancel',
          action: () => Popup.close()
        }, {
          text: 'Proceed',
          className: 'success',
          action: function () {
            // TODO: submit form
            console.log('Subscribing!', formId)

            setShowWaitPopup(true)
            Drupal.behaviors.grovvupReactApp.formSubmit(
              formId,
              'subscription-activate',
              {
                'subscription-plan': option.planMachineName,
                'task-billing-data': taskData()
              },
              () => setShowWaitPopup(false)
            )

            Popup.close()
          }
        }]
      }
    })
  }

  const handleDeactivateSubscription = () => {
    const factory = window.BeakidReactFactory
    const FormContext = factory.FormContext

    const PopupContent = () => (
      <FormContext.Consumer>
        {drupalFormId => {
          formId = drupalFormId

          return (
            <div className="message">You are about to cancel your subscription. This will delete your company from BeAKid System and you will no longer be able to login or run your business. </div>
          )
        }}
      </FormContext.Consumer>
    )

    Popup.create({
      title: 'Confirmation',
      content: <PopupContent/>,
      className: 'subscription-cancel-popup',
      buttons: {
        right: [{
          text: 'No don\'t cancel subscription',
          className: 'success',
          action: () => Popup.close()
        }, {
          text: 'Proceed with cancelation',
          className: 'cancel',
          action: function () {
            console.log('Subscribing!', formId)

            setShowWaitPopup(true)
            Drupal.behaviors.grovvupReactApp.formSubmit(
              formId,
              'subscription-deactivate',
              {},
              () => setShowWaitPopup(false)
            )

            Popup.close()
          }
        }]
      }
    })
  }

  const handlePaymentMethodsComplete = () => {
    if (data.activeBusinessPlan === null) {
      setShowPlanSelect(true)
    } else {
      setShowPaymentMethods(false)
    }
  }

  // const screen = showPaymentMethods || data.defaultPaymentMethod === null
  //   ? 'PaymentMethodList'
  //   : 'Default'

  console.log('showPlanSelect', showPlanSelect)

  // const screen = data.activeBusinessPlan !== null && !showPlanSelect
  //   ? 'Default'
  //   : showPaymentMethods || data.defaultPaymentMethod === null
  //     ? 'PaymentMethodList'
  //     : 'PlanSelect'

  const screen = showPlanSelect || data.activeBusinessPlan === null
    ? 'PlanSelect'
    : showPaymentMethods || data.defaultPaymentMethod === null
      ? 'PaymentMethodList'
      : 'Default'

  console.log('screen', screen, data)

  const defaultPaymentMethod = data.defaultPaymentMethod !== null
    ? data.paymentMethods?.find(method => method.id === data.defaultPaymentMethod)
    : null

  return (
    <div className="task task-billing">
      <Header task={props.task.name}/>

      <div className="content">
        <Elements stripe={stripePromise} options={{}}>
          {screen === 'PlanSelect' && <PlanSelect onComplete={() => setShowPlanSelect(false)}/>}
          {screen === 'PaymentMethodList' && <PaymentMethodList onComplete={handlePaymentMethodsComplete}/>}
          {screen === 'Default' && (
            <>
              {data.activeBusinessPlan &&
                data.activeBusinessPlan.subscribed !== 0 &&
                data.activeBusinessPlan.status in ['active', 'trialing'] &&

                <Notification onUpdatePaymentMethod={() => setShowPaymentMethods(true)}/>
              }
              <CurrentPlan
                option={getOptionByPlanMachineName(data.activeBusinessPlan.machineName)}
                onChange={() => setShowPlanSelect(true)}
                proposeActivation={data.activeBusinessPlan.subscribed === 0}
                upcomingPayment={data.activeBusinessPlan.upcomingPayment}
                onActivate={handleActivateSubscription}
                onDeactivate={handleDeactivateSubscription}
                trialing={data.activeBusinessPlan.status === 'trialing'}
              />
              {defaultPaymentMethod !== null && (
                <ActivePaymentMethod onChange={() => {
                  console.log('setShowPaymentMethods(true)')
                  setShowPaymentMethods(true)
                }}>
                  {defaultPaymentMethod.type === 'card' && <Card method={defaultPaymentMethod as types.PaymentMethodCard} isPrimary={true}/>}
                  {defaultPaymentMethod.type === 'us_bank_account' && <UsBankAccount method={defaultPaymentMethod as types.PaymentMethodBankAccount} isPrimary={true}/>}
                </ActivePaymentMethod>
              )}
            </>
          )}

          <Popup/>
          <SpinnerPopup open={showWaitPopup} className="business-billing-wait-popup">
            <SpinnerCircular />
          </SpinnerPopup>
        </Elements>

        <div className="actions">
          <FormButton text="Cancel task" className="btn-cancel" onClick={props.onDone}/>
          {data.activeBusinessPlan?.subscribed === 0 && screen === 'Default'
            ? (
              <FormButton
                text="Activate subscription & finish task"
                beakidElement='task-billing-submit'
                onValidate={handleValidate}
                onSubmit={handleSubmit}
                onComplete={handleComplete}
              />
            )
            : (
              props.task.completed === 1.0 && (
                <FormButton
                  text="Finish task"
                  onValidate={handleValidate}
                  beakidElement='task-billing-submit'
                  onSubmit={handleSubmit}
                  onComplete={handleComplete}
                />
              )
            )
          }
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  businessSetup: state.businessSetup
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    ...businessSetupActions
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(TaskBilling)
