import React from 'react'
import classNames from 'classnames'
import './styles.scss'

type ProgressProps = {
  value: number,
  text?: string
}

function Progress (props: ProgressProps) {
  return (
    <div
      style={{
        background: 'radial-gradient(closest-side, white 89%, transparent 90%, transparent 100%),' +
                    `conic-gradient(darkgreen ${props.value * 100}%, lightgray 0)`
      }}
      className={classNames({
        progress: true,
        completed: props.value === 1.0
      })}
    >
      <div className="text">{props.text ?? `${props.value * 100}%`}</div>
    </div>
  )
}

export default Progress
