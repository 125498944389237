import FormButton from 'Beakid/FormButton'
import React from 'react'
import * as types from 'Scenes/BusinessSetup/types'
import './styles.scss'

type CardProps = {
  method: types.PaymentMethodCard
  isPrimary: boolean
}

function Card (props: CardProps) {
  return (
    <div className="business-billing-card">
      <div className="cell card-no">
        <label>Card</label>
        <div className="value">
          <span className="brand">{props.method.brand}</span>
          <span className="last4">**** {props.method.last4}</span>
        </div>
      </div>
      <div className="cell card-valid">
        <label>Valid Thru</label>
        <div className="value">
          <span className="month">{props.method.expMonth}</span>
          /
          <span className="year">{props.method.expYear}</span>
        </div>
      </div>
      <div className="cell primary">
        {props.isPrimary ? (
          <div className="primary-indicator">Primary method</div>
        ) : (<>
          <FormButton text="Make primary" beakidElement="card-primary" className="btn-make-primary"/>
        </>)}
      </div>
      {!props.isPrimary && <FormButton text="" beakidElement="card-delete" className="btn-delete"/>}
    </div>
  )
}

export default Card
