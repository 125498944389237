// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';

import { setup as initFactory } from 'Beakid/Factory'
import './Scenes'
import store from './Scenes/store'
import './styles'

initFactory(store)

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );
