import React from 'react'
import Popup from 'reactjs-popup'
import { SpinnerCircular } from 'spinners-react'
import './styles.scss'

type WaitPopupProps = {
  open: boolean
}

function WaitPopup (props: WaitPopupProps) {
  return (
    <Popup open={props.open} className="popup-business-billing-wait">
      <SpinnerCircular />
    </Popup>
  )
}

export default WaitPopup
