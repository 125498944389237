import React from 'react'

export type Coach = {
  name: string,
  email: string
}

export type Team = {
  id?: number,
  teamNumber: number,
  title: string,
  coach?: Coach,
  coachAssistant?: Coach,
  stat: {
    participants: number
  }
}

export enum TimelineItemType {
  Post = 'post'
}

export type TimelinePostFile = {
  fid: number,
  basename: string,
  url: string,
  mimetype: string,
  imgPreview?: string,
  fileIcon?: string
}

export type TimelinePostLink = {
  url: string,
  title: string,
  description?: string
}

export type TimelinePostData = {
  author: {
    uid: number,
    name: string,
    avatar: string
  },
  files?: TimelinePostFile[],
  links?: TimelinePostLink[]
}

export type TimelineItem = {
  id?: number,
  type: TimelineItemType,
  groupType: 'event' | 'team' | 'node', // group entity type
  gid: number, // id of an event or classroom
  date: number,
  body: string,
  data: TimelinePostData
}

export type Event = {
  id?: number,
  title: string,
  type: number,
  from: number,
  to: number,
  description: string,
  email: string,
  website: string,
  phone: string,
  locationName: string,
  postalCode: string,
  streetAddress: string,
  teamsRegistered?: number,
  registrationCap: number,
  teams?: Team[],
  timeline?: (TimelineItem & { isNew: boolean })[]
}

export type VoidCallback = (none: void) => void

export enum EventManagementTab {
  // Dashboard = 0,
  Events = 0,
  Teams = 1
}

export enum EventEditPopupTab {
  Timeline = 0,
  // Calendar = 1,
  Lineup = 1,
  // Albums = 3,
  // Files = 4,
  Settings = 2
}

export type EventType = {
  id: number,
  title: string,
  limit: number
}

export type EventManagementState = Readonly<{
  // wizard state
  tab: EventManagementTab,
  popup: {
    name: string,
    data: any
  } | null,
  // popupEventNew: boolean,
  // popupEventEdit: {id: number, tab: EventEditPopupTab},
  events: Event[],
  types: EventType[]
}>

export type EventManagementEventsActions = Readonly<{
  adoptState: (state: EventManagementState) => void,
  goto: (tab: EventManagementTab) => void,
  popupEventCreate: (show: boolean) => void,
  popupEventEdit: (id: number | boolean, tab?: EventEditPopupTab) => void,
  popupEventDelete: (id: number) => void,
  popupEventEditTab: (tab: EventEditPopupTab) => void,
  eventSave: (event: Event) => void,
  eventPopulateTeams: (teams: Team[]) => void,
  eventTimelinePost: (eventId: number, item: TimelineItem) => void,
  eventTeamDelete: (eventId: number, teamId: number) => void
}>

export type PropsWithEventManagementState = Readonly<{
  eventManagement: EventManagementState
}>

export type PropsEithSubmitCallback = {
  stepRef: React.Ref<{submit: () => void}>
}
