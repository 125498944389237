import React from 'react'

type CountryInputProps = {
  [name: string]: any
  inputRef: any
}

export function CountryInput (props: CountryInputProps) {
  const { inputRef, ...inputProps } = props
  return (
    <select
      { ...inputProps }
      ref={inputRef}
    >
      <option value="unset">Country</option>
      <option value="US">United States</option>
      <option value="CA">Canada</option>
      <option value="AU">Australia</option>
      <option value="GB">United Kingdom</option>
    </select>
  )
}
