import * as types from './types'
import { createAction } from 'typesafe-actions'
import * as commands from './constants'

export const businessBillingAdoptState = createAction(commands.BUSINESSBILLING_ADOPT_STATE)<types.BusinessBillingState>()
export const businessBillingCartSelect = createAction(commands.BUSINESSBILLING_CART_SELECT)<types.Cart | null>()
export const businessBillingActiveSubscriptionSelect = createAction(commands.BUSINESSBILLING_ACTIVE_SUBSCRIPTION_SELECT)<types.Subscription | null>()
export const businessBillingPaymentMethodsSet = createAction(commands.BUSINESSBILLING_PAYMENT_METHODS_SET)<types.PaymentMethod[]>()
export const businessBillingInvoicesSet = createAction(commands.BUSINESSBILLING_INVOICES_SET)<types.Invoice[]>()
export const businessBillingDefaultPaymentMethodSet = createAction(commands.BUSINESSBILLING_DEFAULT_PAYMENT_METHOD_SET)<string>()
export const businessBillingBankAccountSetupIntentReset = createAction(commands.BUSINESSBILLING_BANK_ACCOUNT_SETUP_INTENT_RESET)<void>()
export const businessBillingPeriodSet = createAction(commands.BUSINESSBILLING_BILLING_PERIOD_SET)<types.BillingPeriod>()
