import React, { PropsWithChildren } from 'react'
import * as types from 'Scenes/FormEditor/types'

type QuestionViewProps = PropsWithChildren<{
  entity: types.Question,
  onEdit: () => void
}>

function QuestionView (props: QuestionViewProps) {
  console.log(props.entity)

  return (
    <div className="question-view" onClick={props.onEdit}>
      <div className="line line-title">
        {props.entity.title || 'Question'}
        {props.entity && props.entity.required && <span className="required-field-mark">*</span>}
      </div>
      {props.children}
    </div>
  )
}

export default QuestionView
