import React, { useState } from 'react'
import { connect } from 'react-redux'
import * as types from 'Scenes/BusinessBilling/types'
import Popup from 'reactjs-popup'
import WaitPopup from '../WaitPopup'
import './styles.scss'

type ActivatePopupProps = {
  open: boolean
  onCancel: () => void
  onActivated: () => void
} & types.PropsWithBusinessBillingState

declare var Drupal: {
  behaviors: {
    grovvupReactApp: {
      formSubmit: (formId: string, beakidElement: string, store: any, completeCallback: () => void) => void
    }
  }
}

function ActivatePopup (props: ActivatePopupProps) {
  const [showWaitPopup, setShowWaitPopup] = useState<boolean>(false)

  const handleCancel = (e) => {
    e.preventDefault()
    props.onCancel()
  }

  const handleActivate = (e, formId) => {
    e.preventDefault()

    setShowWaitPopup(true)

    const cart = props.businessBilling.cart

    Drupal.behaviors.grovvupReactApp.formSubmit(
      formId,
      'subscription-activate',
      {
        'subscription-plan': cart.saasPlanMachineName,
        'saas-addons': cart.saasAddonsMachineNames,
        'marketing-plan': cart.marketingPlanMachineName,
        period: cart.period,
        coupon: cart.coupon
      },
      () => {
        setShowWaitPopup(false)
        props.onActivated()
      }
    )
  }

  const factory = window.BeakidReactFactory
  const FormContext = factory.FormContext

  return (
    <Popup open={props.open} onClose={props.onCancel} modal className="popup-activate">
      <div className="modal">
        <div className="title">Confirmation</div>

        <div className="modal-content">
          <p>You are in ane step ahead from activating your subscription</p>
          <p>Please click <strong>Activate</strong> to proceed</p>
        </div>

        <div className="actions">
          <button onClick={handleCancel} className="btn-cancel">Cancel</button>
          <FormContext.Consumer>
            {drupalFormId => {
              return <button onClick={e => handleActivate(e, drupalFormId)} className="btn-submit">Activate</button>
            }}
          </FormContext.Consumer>
        </div>
      </div>

      <WaitPopup open={showWaitPopup}/>
    </Popup>
  )
}

const mapStateToProps = (state) => ({
  businessBilling: state.businessBilling
})

export default connect(mapStateToProps)(ActivatePopup)
