import React, { useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as businessSetupActions from 'Scenes/BusinessSetup/actions'
import ReactTooltip from 'react-tooltip'
import SpinnerPopup from 'reactjs-popup'
import { SpinnerCircular } from 'spinners-react'
import FormButton from 'Beakid/FormButton'
import * as types from 'Scenes/BusinessSetup/types'
import './styles.scss'

type InitialProps = {
  onComplete: (accountHolderName: string, accountHolderEmail: string) => void
  onCancel: () => void
} & types.PropsWithBusinessSetupState & types.BusinessSetupActions

interface InitialError {
  accountHolderName?: string,
  accountHolderEmail?: string,
}

function Initial (props: InitialProps) {
  const dataBasic = useMemo(() => props.businessSetup.tasks.find(task => task.name === 'basic').data as types.DataBasic, [])
  const data = props.businessSetup.tasks[3].data as types.DataBilling

  const [accountHolderName, setAccountHolderName] = useState<string>(dataBasic.first + ' ' + dataBasic.last)
  const [accountHolderEmail, setAccountHolderEmail] = useState<string>('')
  const [showWaitPopup, setShowWaitPopup] = useState<boolean>(false)

  const accountHolderNameRef = useRef(null)
  const accountHolderEmailRef = useRef(null)
  const [error, setError] = useState<InitialError>({})

  const [isRequested, setIsRequested] = useState<boolean>(false)

  useEffect(() => {
    if (Object.keys(error).length > 0) {
      console.log('Validation error', error)
      setShowWaitPopup(false)
    }
    if ('accountHolderName' in error) {
      ReactTooltip.show(accountHolderNameRef.current)
    }
    if ('accountHolderEmail' in error) {
      ReactTooltip.show(accountHolderEmailRef.current)
    }
  }, [error])

  const resetError = (event) => {
    ReactTooltip.hide(event.target)
    setError({})
  }

  const handleClick = () => {
    props.businessSetupBankAccountSetupIntentReset()
    setIsRequested(true)
    return {}
  }

  const handleCancel = (event?) => {
    event.preventDefault()
    event.stopPropagation()
    if ('onComplete' in props) {
      props.onCancel()
    }
  }

  const gotClientSecret = () => {
    console.log('clientSecret', data.setupIntent.clientSecret)
    props.onComplete(accountHolderName, accountHolderEmail)
  }

  useEffect(() => {
    if (isRequested && data.setupIntent) {
      gotClientSecret()
    }
  }, [isRequested, data.setupIntent])

  return (
    <div className="us-bank-account-input--initial">
      <div className="elements">
        <div className="section section-1">
          <div className="line line-1">
            <input
              type="text"
              className="account-holder-name"
              placeholder="Account holder name"
              onChange={event => setAccountHolderName(event.target.value)}
              // defaultValue={accountHolderName}
              {...('accountHolderName' in error ? {
                'data-tip': error.accountHolderName,
                'data-type': 'error',
                'data-effect': 'solid',
                ref: accountHolderNameRef,
                onFocus: resetError
              } : {})}
            />
          </div>
          <div className="line line-2">
            <input
              type="text"
              className="account-holder-email"
              placeholder="Account holder e-mail"
              onChange={event => setAccountHolderEmail(event.target.value)}
              // defaultValue={accountHolderEmail}
              {...('accountHolderEmail' in error ? {
                'data-tip': error.accountHolderEmail,
                'data-type': 'error',
                'data-effect': 'solid',
                ref: accountHolderEmailRef,
                onFocus: resetError
              } : {})}
            />
          </div>
        </div>
      </div>

      <div className="input-actions">
        <button className="btn-cancel" onClick={event => handleCancel(event)}>Back</button>
        <FormButton
          text="Setup Bank Account"
          className="btn-start btn-submit"
          beakidElement="create-setup-intent"
          onClick={() => setError({})}
          onSubmit={() => handleClick()}
        />
      </div>

      {Object.keys(error).length > 0 && <ReactTooltip />}
      <SpinnerPopup open={showWaitPopup} className="business-billing-wait-popup">
        <SpinnerCircular />
      </SpinnerPopup>
    </div>
  )
}

const mapStateToProps = (state) => ({
  businessSetup: state.businessSetup
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    ...businessSetupActions
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(Initial)
