import React, { useMemo, useState } from 'react'
import { connect } from 'react-redux'
import * as types from 'Scenes/BusinessSetup/types'
import Card from '../Card'
import UsBankAccount from '../UsBankAccount'
import AddButtonDropdown from './AddButton'
import { CardInput } from '../CardInput'
import UsBankAccountInput from '../UsBankAccountInput'
import './styles.scss'

type PaymentMethodListProps = {
  onComplete?: () => void
} & types.PropsWithBusinessSetupState

function PaymentMethodList (props: PaymentMethodListProps) {
  const data = useMemo(() => props.businessSetup.tasks.find(task => task.name === 'billing').data as types.DataBilling, [])

  const [addCard, setAddCard] = useState<boolean>(false)
  const [editCard, setEditCard] = useState<string>(null)
  const [addUsBankAccount, setAddUsBankAccount] = useState<boolean>(false)
  const [editUsBankAccount, setEditUsBankAccount] = useState<string>(null)

  const handleCancel = () => {
    props.onComplete()
  }

  const AddButton = () => <AddButtonDropdown onCard={() => setAddCard(true)} onUsBankAccount={() => setAddUsBankAccount(true)}/>

  const screen = addCard
    ? 'add card'
    : addUsBankAccount
      ? 'add us bank account'
      : editCard
        ? 'edit card'
        : editUsBankAccount
          ? 'edit us bank account'
          : data.paymentMethods.length === 0
            ? 'empty state'
            : 'default'

  console.log('screen', screen, 'addCard', addCard, 'addUsBankAccount', addUsBankAccount)

  return (
    <div className="payment-method-list2">
      {screen === 'default' && (
        <>
          <div className="toolbar">
            <button className="btn-cancel" onClick={() => handleCancel()}>Go back</button>
            <AddButton/>
          </div>
          <div className="payment-methods">
            {data.paymentMethods.map((method, index) => (
              method.type === 'card'
                ? <Card method={method as types.PaymentMethodCard} isPrimary={method.id === data.defaultPaymentMethod} key={index}/>
                : <UsBankAccount method={method as types.PaymentMethodBankAccount} isPrimary={method.id === data.defaultPaymentMethod} key={index}/>
            ))}
          </div>
        </>
      )}

      {screen === 'empty state' && (
        <div className="empty-state">
          <AddButton/>
        </div>
      )}

      {screen === 'add card' && (
        <CardInput method={null} onComplete={() => setAddCard(false)}/>
      )}

      {screen === 'add us bank account' && (
        <UsBankAccountInput method={null} onComplete={() => setAddUsBankAccount(false)}/>
      )}

      {screen === 'edit card' && (
        <CardInput
          method={data.paymentMethods.find(method => method.id === editCard) as types.PaymentMethodCard}
          onComplete={() => setEditCard(null)}
        />
      )}

      {screen === 'edit us bank account' && (
        <UsBankAccountInput
          method={data.paymentMethods.find(method => method.id === editCard) as types.PaymentMethodBankAccount}
          onComplete={() => setEditUsBankAccount(null)}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  businessSetup: state.businessSetup
})

export default connect(mapStateToProps)(PaymentMethodList)
