import React, { useImperativeHandle } from 'react'
import { factory } from './Factory'
import { ReactReduxContext } from 'react-redux'

declare var Drupal: {
  behaviors: {
    grovvupReactApp: {
      formSubmit: (formId: string, beakidElement: string, store: any, cb: () => void) => void
    }
  }
}

type FormButtonProps = React.HTMLProps<HTMLButtonElement> & {
    text: string,
    beakidElement?: string,
    onValidate?: () => (any | Promise<boolean>)
    onClick?: () => void,
    onSubmit?: (validationResult?: any) => {[name: string]: any},
    onComplete?: () => void,
    submitBtnRef?: React.Ref<{trySubmit: () => void}>
}

function FormButton (props: FormButtonProps) {
  const processClick = (formId, event, beakidElement, store, validationResult = null) => {
    if ('onClick' in props) {
      props.onClick(event)
    }

    if (beakidElement) {
      const storeState = store.getState()
      const merge = 'onSubmit' in props ? props.onSubmit(validationResult) : {}
      const formData = { ...storeState, ...merge }

      console.log('calling Drupal.behaviors.grovvupReactApp.formSubmit(', formId, ', ', beakidElement, ', ', formData, ')')
      Drupal.behaviors.grovvupReactApp.formSubmit(formId, beakidElement, formData, props.onComplete)
    }
  }

  const handleClick = (event, formId, onValidate, beakidElement, store) => {
    event.preventDefault()
    event.stopPropagation()

    if (typeof onValidate === 'undefined') {
      onValidate = () => true
    }

    const validateResult = onValidate()
    console.log('validateResult', validateResult)

    Promise.resolve(validateResult).then(result => {
      console.log('validation result', result)
      if (result) {
        processClick(formId, event, beakidElement, store, result)
      }
    })

    // if (typeof validateResult !== 'boolean') {
    //   validateResult.then(result => {
    //     if (result) {
    //       processClick(formId, event, beakidElement, store, result)
    //     }
    //   })
    // } else if (validateResult) {
    //   processClick(formId, event, beakidElement, store)
    // }
  }

  // handle top menu button click
  const trySubmit = () => {
    // how to issue button click here?))
  }

  useImperativeHandle(props.submitBtnRef, () => ({
    trySubmit
  }))

  const FormContext = factory.FormContext

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { text, beakidElement, onValidate, onComplete, ...buttonProps } = props

  return (
    <FormContext.Consumer>
      {formId => (
        <ReactReduxContext.Consumer>
          {({ store }) => (
            <button
              {...{ ...buttonProps, ...{ className: 'not-a-btn' + ('className' in buttonProps ? (' ' + buttonProps.className) : '') } }}
              onClick={event => handleClick(event, formId, onValidate, beakidElement, store)}
              type="button"
            >
              {text}
            </button>
          )}
        </ReactReduxContext.Consumer>
      )}
    </FormContext.Consumer>
  )
}

export default FormButton
