import React from 'react'
import './styles.scss'

type ActivePaymentMethodProps = {
  children: React.ReactNode
  onChange: () => void
}

export default function ActivePaymentMethod (props: ActivePaymentMethodProps) {
  const handleBtnChange = event => {
    event.preventDefault()
    event.stopPropagation()
    props.onChange()
  }

  return (
    <div className="active-payment-method2">
      <div className="method">
        {props.children}
      </div>
      <div className="button-wrapper">
        <button className="btn-make-primary" onClick={handleBtnChange}>Change Payment Method</button>
      </div>
    </div>
  )
}
