import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from './ErrorFallback'

interface SceneProps {
    children: any
}

const Scene = React.forwardRef<HTMLDivElement, SceneProps>(
  (props: SceneProps, ref) => (
    <div ref={ref} className="beakid-component">
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {props.children}
      </ErrorBoundary>
    </div>
  )
)

Scene.displayName = 'Scene'

export default Scene
