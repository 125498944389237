import React from 'react'
import notificationIcon from './images/notification.svg'
import './styles.scss'

type NotificationProps = {
  onUpdatePaymentMethod: () => void
}

function Notification (props: NotificationProps) {
  const handleUpdatePaymentMethod = event => {
    event.preventDefault()
    event.stopPropagation()
    props.onUpdatePaymentMethod()
  }

  return (
    <div className="notification2">
      <img src={notificationIcon} />
      Oops!  We were unable to process your payment.
      <button onClick={handleUpdatePaymentMethod}>Update Payment Method</button>
    </div>
  )
}

export default Notification
