import React from 'react'
import Plan from './Plan'
import { connect } from 'react-redux'
import * as types from 'Scenes/BusinessBilling/types'
import './styles.scss'

type BusinessBillingProps = {
  section: string
  plans: types.BusinessPlan[]
  onChange?: (plan: types.BusinessPlan | types.BusinessPlan[]) => void
  selected: types.BusinessPlan | types.BusinessPlan[]
  multiple?: boolean
  disabled?: boolean } & types.PropsWithBusinessBillingState

function PlanSelect (props: BusinessBillingProps) {
  const getIsPurchased = (planMachineName: string) => (
    props.section === 'saas'
      ? props.businessBilling.activeSubscription?.saasPlanMachineName === planMachineName
      : props.section === 'saas-addons'
        ? planMachineName in (props.businessBilling.activeSubscription?.saasAddonsMachineNames ?? [])
        : props.section === 'marketing'
          ? props.businessBilling.activeSubscription?.marketingPlanMachineName === planMachineName
          : false
  )

  return (
    <div className="plan-select2">
      <div className="main-section">
        <div className="plans">
          {props.plans.filter(plan => plan.status === 'public').map((plan, index) => (
            <Plan
              section={props.section}
              plan={plan}
              key={index}
              onSelect={() => props.onChange(props.multiple ? [...(props.selected as types.BusinessPlan[]), plan] : plan)}
              onUnselect={() => props.onChange(props.multiple ? [...(props.selected as types.BusinessPlan[]).filter(test => test !== plan)] : undefined)}
              purchased={getIsPurchased(plan.machineName)}
              selected={props.multiple ? (props.selected as types.BusinessPlan[]).filter(test => (test === plan)).length > 0 : props.selected === plan}
              multiple={props.multiple}
              showFees={props.section === 'saas'}
              disabled={props.disabled ?? false}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  businessBilling: state.businessBilling
})

export default connect(mapStateToProps)(PlanSelect)
