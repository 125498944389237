import * as types from './types'
import { createAction } from 'typesafe-actions'
import * as commands from './constants'

export const businessDashboardAdoptState = createAction(commands.BUSINESSDASHBOARD_ADOPT_STATE)<types.BusinessDashboardState>()
export const businessDashboardFilterSet = createAction(commands.BUSINESSDASHBOARD_FILTER_SET)<types.AnalyticsFilter>()
// export const businessDashboardTotalSignupsSet = createAction(commands.BUSINESSDASHBOARD_TOTAL_SIGNUPS_SET)<types.SignupTotal[]>()
export const businessDashboardBusinessSet = createAction(commands.BUSINESSDASHBOARD_BUSINESS_SET)<number>()
export const businessDashboardCalendarSet = createAction(commands.BUSINESSDASHBOARD_CALENDAR_SET)<types.CalendarState>()
export const businessDashboardPeriodSet = createAction(commands.BUSINESSDASHBOARD_PERIOD_SET)<types.Period | 'custom'>()
