import React from 'react'
import Dropdown, { MenuItem } from '@trendmicro/react-dropdown'
import '@trendmicro/react-buttons/dist/react-buttons.css'
import '@trendmicro/react-dropdown/dist/react-dropdown.css'

type AddButtonProps = {
  onCard: () => void
  onUsBankAccount: () => void
}

function AddButton (props: AddButtonProps) {
  return (
    <div className="dropdown-wrapper">
      <Dropdown pullRight>
        <Dropdown.Toggle btnStyle="flat">Add Payment Method</Dropdown.Toggle>
        <Dropdown.Menu>
          <MenuItem onSelect={props.onCard}>New Card</MenuItem>
          <MenuItem onSelect={props.onUsBankAccount}>New US Bank Account</MenuItem>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default AddButton
