// import React from 'react'
import { DeepReadonly } from 'utility-types'

export type VoidCallback = (none: void) => void

// export interface BusinessAbout {
//   name: string,
//   logo: {
//     fid: number | null,
//     url?: string
//   },
//   email: string,
//   website: string,
//   country: Country,
//   streetAddress: string,
//   postalCode: string,
//   phone: string,
//   owner: {
//     first: string,
//     last: string
//   }
// }

export enum Country {
  UnitedStates = 'US',
  GreatBritain = 'GB',
  Canada = 'CA',
  Australia = 'AU'
}

// export enum BusinessSetupStage {
//   Business = 'business',
//   Class = 'class',
//   Account = 'account',
//   Finish = 'finish'
// }

// export enum BusinessSteps {
//   about = 'about',
//   locations = 'locations',
// }

// export enum ClassSteps {
//   tuitions = 'tuitions',
//   classes = 'classes',
//   staff = 'staff'
// }

// export enum AccountSteps {
//   epayment = 'epayment',
//   widget = 'widget'
// }

// export enum FinishSteps {
//   finish = 'finish'
// }

// export type BusinessSetupStep = BusinessSteps | ClassSteps | AccountSteps | FinishSteps;

// export enum LocationFormState {
//   List,
//   Create,
//   Edit
// }

// export type Location = {
//   id?: number,
//   name: string,
//   streetAddress: string,
//   postalCode: string,
//   city?: string,
//   state?: string,
//   phoneNumber: string,
//   isMain: boolean
// }

// export enum StaffRole {
//   NoRole = -1,
//   Owner = 0,
//   Administrator = 1,
//   Teacher = 2
// }

// export type StaffMember = DeepReadonly<{
//   id?: number,
//   name: string,
//   email: string,
//   classes: number[],
//   role: StaffRole
// }>

// export enum StaffFormState {
//   List = 'list',
//   IntroduceOwner = 'introduce owner',
//   Create = 'create',
//   Edit = 'edit'
// }

// export type Tuition = {
//   id?: number,
//   title: string,
//   cost: number,
// }

// export enum TuitionFormState {
//   List,
//   Create,
//   Edit
// }

// export enum Weekday {
//   Monday = 0,
//   Tuesday = 1,
//   Wednesday = 2,
//   Thursday = 3,
//   Friday = 4,
//   Saturday = 5,
//   Sunday = 6
// }

// export enum WeekdayAbbr {
//   Mon = 0,
//   Tue = 1,
//   Wed = 2,
//   Thu = 3,
//   Fri = 4,
//   Sat = 5,
//   Sun = 6
// }

// export type ScheduleRow = {
//   from: number,
//   to: number,
//   weekdays: Weekday[]
// }

// // export type Schedule = DeepReadonly<ScheduleRow[]>

// export type Class = {
//   id?: number,
//   title: string,
//   description: string,
//   locationIndex: number,
//   tuitionIndex: number,
//   from: number,
//   to: number,
//   schedule: ScheduleRow
// }

export enum BusinessType {
  Individual = 'individual',
  Company = 'company'
}

// export type EPaymentSettings = {
//   ssnLast4: string,
//   fein?: string,
//   businessUrl: string,
//   phoneNumber: string,
//   streetAddress: string,
//   postalCode: string,
//   tosAccepted?: boolean // 'true' if just accepted but acceptance is not reported to the backend yet, or timestamp of acceptance date
// }

// export type EPayment = {
//   businessType: BusinessType | null,
//   settings?: EPaymentSettings,
// }

// export enum ClassFormState {
//   List,
//   Create,
//   Edit
// }

type Currency = '$'

export type UpcomingPayment = {
  when: number
  amount: number
  currency: Currency
}

export type ActiveBusinessPlan = {
  machineName: string
  status: 'incomplete' | 'incomplete_expired' | 'trialing' | 'active' | 'past_due' | 'canceled' | 'unpaid'
  subscribed: number // ts
  upcomingPayment?: UpcomingPayment
  payeeBusinessNid: number
}

export type DataBasic = {
  nid?: number
  name: string
  website: string
  phone: string
  country: string
  streetAddress: string
  postalCode: string
  first: string
  last: string
  mail?: string
}

export type DataClass = {
  nid?: number
  name: string
  from: number
  to: number
  cost: number
  events: { start: number, end: number }[]
}

export type DataPayment = {
  businessType: BusinessType | null
  ssnLast4: string
  fein?: string
  businessUrl: string
  phoneNumber: string
  streetAddress: string
  postalCode: string
  tosAccepted?: boolean
}

type PaymentMethodProps = {
  title: string
  id: string
  type: 'card' | 'us_bank_account'
}

export type PaymentMethodCard = PaymentMethodProps & {
  last4: string
  brand: string
  expMonth: string
  expYear: string
  name: string
  addressLine1: string
  addressZip: string
  addressCity: string
  addressState: string
  addressCountry: string
}

export type PaymentMethodBankAccount = PaymentMethodProps & {
  // country: string
  // currency: string
  // accountHolderName?: string
  accountHolderType?: 'individual' | 'company'
  routingNumber?: string
  // accountNumber: string
  last4: string
  accountType: string
  bankName: string
}

export type PaymentMethod = PaymentMethodCard | PaymentMethodBankAccount

type SetupIntent = {
  clientSecret: string
}

export type SubscriptionOptionCost = {
  value: number // 1.5
  unit: string // %
  subject: string // Transaction
}

export type SubscriptionOption = {
  title: string // Monthly subscription | Transaction
  planId: string
  planMachineName: string // spoonful_remote
  planTitle: string // Spoonful
  machineName: string // monthly
  cost: SubscriptionOptionCost
  requestPaymentMethod: boolean
}

type PlanOption = {
  title: string,
  machineName: string
}

export type BusinessPlan = {
  title: string
  subscriptionOptions: SubscriptionOption[]
  status: 'public' | 'private'
  promoted: boolean
  options: PlanOption[]
}

export type BillingPeriod = 'yearly' | 'monthly'

export type DataBilling = {
  defaultPaymentMethod: string // id of the default payment method
  paymentMethods: PaymentMethod[]
  setupIntent: SetupIntent
  activeBusinessPlan: null | ActiveBusinessPlan
  businessPlans: BusinessPlan[]
  period: BillingPeriod
}

export type TaskType = 'basic' | 'class' | 'payment' | 'billing' | 'launch'

export type Task = {
  name: TaskType
  title: string
  description: string
  order: number
  completed: number
  reentrable: boolean
  data: DataBasic | DataClass | DataPayment | DataBilling
}

export type BusinessSetupState = DeepReadonly<{
  tasks: Task[]
  task: string
  // wizard state
  // stage: BusinessSetupStage,
  // step: BusinessSetupStep,

  // // Business / About
  // about: BusinessAbout | null,

  // // Business / Locations
  // locations: [Location],
  // locationFormState: LocationFormState,
  // editingLocationIndex: number | null,

  // // Business / Staff
  // staffMembers: [StaffMember],
  // staffFormState: StaffFormState,
  // editingStaffMemberIndex: number | null

  // // Tuitions
  // tuitions: [Tuition],
  // tuitionFormState: TuitionFormState,
  // editingTuitionIndex: number | null,

  // // Classes
  // classes: [Class],
  // classFormState: ClassFormState,
  // editingClassIndex: number | null,

  // // Sessions
  // // sessions: [Session],
  // // sessionFormState: SessionFormState,
  // // editingSessionIndex: number | null,

  // // E-Payment
  // epayment: EPayment | null,

  // account: {
  //   ids: {
  //     cart: number,
  //     calendar: number
  //   }
  // } | null
}>

export type BusinessSetupStateActions = Readonly<{
  businessSetupAdoptState: (state: BusinessSetupState) => void
}>

export type BusinessSetupWizardActions = Readonly<{
  // businessSetupGoto: (stage: BusinessSetupStage, step: BusinessSetupStep) => void
}>

// export type BusinessSetupAboutActions = Readonly<{
//   businessStageAboutSubmit: (about: BusinessAbout) => void,
// }>

// export type BusinessSetupLocationActions = Readonly<{
//   businessSetupLocationAdd: (location: Location) => void,
//   businessSetupLocationDelete: (index: number) => void,
//   businessSetupLocationUpdate: (index: number, location: Location) => void,
//   businessSetupLocationMakeMain: (index: number) => void,
//   businessSetupLocationFormCreate: VoidCallback,
//   businessSetupLocationFormEdit: (index: number) => void,
//   businessSetupLocationFormList: VoidCallback,
// }>

// export type BusinessSetupStaffActions = Readonly<{
//   staffAdd: (member: StaffMember) => void,
//   staffDelete: (index: number) => void,
//   staffUpdate: (index: number, member: StaffMember) => void,
//   staffFormCreate: VoidCallback,
//   staffFormEdit: (index: number) => void,
//   staffFormList: VoidCallback,
//   staffFormIntroduceOwner: VoidCallback,
// }>

// export type BusinessSetupTuitionActions = Readonly<{
//   businessSetupTuitionAdd: (tuition: Tuition) => void,
//   businessSetupTuitionDelete: (index: number) => void,
//   businessSetupTuitionUpdate: (index: number, Tuition) => void,
//   businessSetupTuitionFormCreate: VoidCallback,
//   businessSetupTuitionFormEdit: (index: number) => void,
//   businessSetupTuitionFormList: VoidCallback,
// }>

// export type BusinessSetupClassActions = Readonly<{
//   businessSetupClassAdd: (cls: Class) => void,
//   businessSetupClassDelete: (index: number) => void,
//   businessSetupClassUpdate: (number, cls: Class) => void,
//   businessSetupClassFormCreate: VoidCallback,
//   businessSetupClassFormEdit: (index: number) => void,
//   businessSetupClassFormList: VoidCallback,
// }>

// export type BusinessSetupAccountActions = Readonly<{
//   businessSetupEPaymentBusinessType: (businessType: BusinessType) => void,
//   businessSetupEPaymentSettings: (settings: EPaymentSettings) => void,
// }>

// export type BusinessSetupSessionActions = Readonly<{
//   businessSetupSessionAdd: (session: Session) => void,
//   businessSetupSessionDelete: (number) => void,
//   businessSetupSessionUpdate: (sessionIndex: number, session: Session) => void,
//   businessSetupSessionFormCreate: VoidCallback,
//   businessSetupSessionFormEdit: (index: number) => void,
//   businessSetupSessionFormList: VoidCallback
// }>

export type BusinessSetupActions = Readonly<{
  businessSetupSaveTask: (task: Task) => void
  businessSetupPlanSelect: (planMachineName: string) => void
  businessSetupPlanSet: (plan: ActiveBusinessPlan) => void
  businessSetupDefaultPaymentMethodSet: (method: string) => void
  businessSetupBankAccountSetupIntentReset: () => void
  businessSetupBillingPeriodSet: (value: BillingPeriod) => void
}
  // & BusinessSetupWizardActions
  // & BusinessSetupAboutActions
  // & BusinessSetupLocationActions
  // & BusinessSetupStaffActions
  // & BusinessSetupTuitionActions
  // & BusinessSetupClassActions
  // & BusinessSetupAccountActions
>

export type PropsWithBusinessSetupState = DeepReadonly<{
  businessSetup: BusinessSetupState
}>

// export type ZipCodeDetails = {
//   city: string,
//   state: string
// }

// export interface StepAccountEPaymentError {
//   fein?: string,
//   ssnLast4?: string,
//   businessUrl?: string,
//   phoneNumber?: string,
//   streetAddress?: string,
//   postalCode?: string,
//   tosAccepted?: string
// }

// export type PropsEithSubmitCallback = {
//   stepRef: React.Ref<{submit: () => void}>
// }
