import React, { useCallback, useEffect, useState } from 'react'
import QuestionInput, { QuestionInputProps } from './QuestionInput'
import { QuestionFileAllowedType } from 'Scenes/FormEditor/types'
import { useDropzone } from 'react-dropzone'
import { confirmAlert } from 'react-confirm-alert'
import axios from 'axios'
import classNames from 'classnames'

type QuestionProps = QuestionInputProps

const FileTypeOptionMap = {
  [QuestionFileAllowedType.Pdf]: 'PDF',
  [QuestionFileAllowedType.Pictures]: 'Pictures',
  [QuestionFileAllowedType.Xls]: 'XLS',
  [QuestionFileAllowedType.Docs]: 'Docs'
}

function FileQuestionInput (props: QuestionProps) {
  const [showDz, setShowDz] = useState<boolean>(false)
  const [attachment, setAttachment] = useState(props.entity.attachment ?? null)
  const [uploading, setUploading] = useState(null)
  const [attachmentFid, setAttachmentFid] = useState(props.entity.attachment?.fid ?? null)
  const handleHelpTextChange = event => props.onChange({ ...props.entity, helpText: event.target.value })
  const handleAlowedTypesChange = (option: QuestionFileAllowedType) => props.onChange({ ...props.entity, allowedFileTypes: [...props.entity.allowedFileTypes, option] })

  useEffect(() => {
    if (attachmentFid) {
      props.onChange({
        ...props.entity,
        attachment: {
          fid: attachmentFid,
          name: attachment.name
        }
      })
    }
  }, [attachmentFid])

  const onDrop = useCallback(acceptedFiles => {
    const rejected = acceptedFiles.filter(file => (file.size > 10 * 1024 * 1024))

    if (rejected.length > 0) {
      confirmAlert({
        title: 'File rejected',
        message: 'File must not exceed 10MB of size',
        buttons: [
          {
            label: 'Ok',
            onClick: () => {}
          }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true
      })
      return
    }

    const file = acceptedFiles[0]

    setAttachment(file)
    setUploading(true)

    try {
      const data = new FormData()
      data.append('file', file)

      console.log(data)

      axios.post('/grovvup/questionary/attach', data, {
        // receive two    parameter endpoint url ,form data
      }).then(res => {
        if (res.status !== 200) {
          console.warn('Logo upload failed')
          return
        }

        if (!res.data.response.fid) {
          confirmAlert({
            title: 'Upload error',
            message: 'Failed to upload file',
            buttons: [
              {
                label: 'Ok',
                onClick: () => {}
              }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true
          })
        }
        setAttachmentFid(res.data.response.fid)
        setShowDz(false)
      })
    } finally {
      setUploading(false)
    }
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <QuestionInput { ...props }>
      <div className="line line-help-text">
        <input type="text" onChange={handleHelpTextChange} placeholder="Help text" defaultValue={props.entity.helpText} />
      </div>
      <div className="line line-settings">
        <div className="attachment">
          {showDz && (<>
            <div
              className={classNames({
                dropzone: true,
                uploading
              })}
              {...getRootProps()}
              tabIndex={-1}
            >
              <div className="dz-message">Drop your attachment here</div>
              <input {...getInputProps()} />
            </div>
            <div className="help">
              JPEG, PNG or GIF formats <br/>
              Recommended size: 1920 x 1080
              Max. file size: 4mb
            </div>
            <button onClick={() => setShowDz(false)} className="btn-cancel">Cancel</button>
          </>)}
          {!showDz && attachmentFid === null && (<>
            <button onClick={() => setShowDz(true)} className="btn-attach">Attach file</button>
            <div className="help-text">Optional</div>
          </>)}
          {!showDz && attachmentFid !== null && (<>
            <div className="attachment-info">File attached</div>
            <button onClick={() => setAttachmentFid(null)} className="btn-remove">Remove attachment</button>
          </>)}
        </div>
        {!showDz && (<>
          <ul className="filetypes">
            {Object.values(QuestionFileAllowedType).map((option, index) => (
              <li key={index}>
                <label>
                  <input
                    type="checkbox"
                    checked={props.entity.allowedFileTypes.indexOf(option) !== -1}
                    onChange={() => handleAlowedTypesChange(option)}
                  />
                  {FileTypeOptionMap[option]}
                </label>
              </li>
            ))}
          </ul>
          <input type="text" placeholder="Maximum file size (up to 1GB)"/>
        </>)}
      </div>
    </QuestionInput>
  )
}

export default FileQuestionInput
