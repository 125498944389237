import React from 'react'
import * as types from 'Scenes/BusinessBilling/types'
import SubscriptionItem from './SubscriptionItem'
import './styles.scss'

type SubscriptionProps = {
  subscription: types.SubscriptionContent
  hideCost?: boolean
  longText?: boolean
}

function Subscription (props: SubscriptionProps) {
  return (
    <div className="subscription">
      {props.subscription.saasPlanMachineName && (
        <SubscriptionItem itemType="saasPlan" planMachineName={props.subscription.saasPlanMachineName} period={props.subscription.period} hideCost={props.hideCost ?? false} longText={props.longText ?? false}/>
      )}
      {props.subscription.saasAddonsMachineNames.length > 0 &&
        props.subscription.saasAddonsMachineNames.map((addon, key) => (
          <SubscriptionItem key={key} itemType="saasAddon" planMachineName={addon} period={props.subscription.period} hideCost={props.hideCost ?? false} longText={props.longText ?? false}/>
        ))
      }
      {props.subscription.marketingPlanMachineName && (
        <SubscriptionItem itemType="marketingPlan" planMachineName={props.subscription.marketingPlanMachineName} period={props.subscription.period} hideCost={props.hideCost ?? false} longText={props.longText ?? false}/>
      )}
    </div>
  )
}

export default Subscription
