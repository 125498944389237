import React from 'react'
import Cell from '../Cell'
import { connect } from 'react-redux'
import * as types from '../../types'
import './styles.scss'

type TopFranchiseesProps = types.PropsWithBusinessDashboardState

function TopFranchisees (props: TopFranchiseesProps) {
  const data = props.businessDashboard.analytics.data[0].top_franchisees

  return (
    <Cell title="Top franchisees">
      <div className="data">
        <div className="header">
          <div className="field index">#</div>
          <div className="field title">Franchisee</div>
          <div className="field revenue">Gross Revenue</div>
        </div>
        <div className="rows">
          {data.map((franchisee, index) => (
            <div className="franchisee" key={index}>
              <div className="field index">{index + 1}</div>
              <div className="field title">
                <span className="color" style={{ backgroundColor: franchisee.color }}></span>
                {franchisee.title}
              </div>
              <div className="field revenue">{franchisee.currency} {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(franchisee.value)}</div>
            </div>
          ))}
        </div>
      </div>
    </Cell>
  )
}

const mapStateToProps = (state) => ({
  businessDashboard: state.businessDashboard
})

export default connect(mapStateToProps)(TopFranchisees)
