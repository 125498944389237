import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as businessBillingActions from '../../actions'
import * as types from 'Scenes/BusinessBilling/types'
import Card from '../Card'
import UsBankAccount from '../UsBankAccount'
import AddButtonDropdown from './AddButton'
import './styles.scss'
import { CardInput } from '../CardInput'
import UsBankAccountInput from '../UsBankAccountInput'

type PaymentMethodListProps = {
  onComplete?: () => void
} & types.PropsWithBusinessBillingState & types.BusinessBillingActions

function PaymentMethodList (props: PaymentMethodListProps) {
  const [addCard, setAddCard] = useState<boolean>(false)
  const [editCard, setEditCard] = useState<string>(null)
  const [addUsBankAccount, setAddUsBankAccount] = useState<boolean>(false)
  const [editUsBankAccount, setEditUsBankAccount] = useState<string>(null)

  const handleCancel = () => {
    props.onComplete()
  }

  const AddButton = ({ addMode }: { addMode: boolean }) => <AddButtonDropdown addMode={addMode} onCard={() => setAddCard(true)} onUsBankAccount={() => setAddUsBankAccount(true)}/>
  const CancelButton = () => <button className="btn-cancel" onClick={() => handleCancel()}>Go back</button>

  const screen = addCard
    ? 'add card'
    : addUsBankAccount
      ? 'add us bank account'
      : editCard
        ? 'edit card'
        : editUsBankAccount
          ? 'edit us bank account'
          : props.businessBilling.paymentMethods.length === 0
            ? 'empty state'
            : 'default'

  console.log('screen', screen, 'addCard', addCard, 'addUsBankAccount', addUsBankAccount)

  return (
    <div className="payment-method-list">
      {screen === 'default' && (
        <>
          <div className="toolbar">
            {props.onComplete && <CancelButton/>}
            <AddButton addMode={props.businessBilling.paymentMethods.length !== 1}/>
          </div>
          <div className="payment-methods">
            {props.businessBilling.paymentMethods.map(method => (
              method.type === 'card'
                ? <Card method={method as types.PaymentMethodCard} isPrimary={method.id === props.businessBilling.defaultPaymentMethod}/>
                : <UsBankAccount method={method as types.PaymentMethodBankAccount} isPrimary={method.id === props.businessBilling.defaultPaymentMethod}/>
            ))}
          </div>
        </>
      )}

      {screen === 'empty state' && (
        <div className="empty-state">
          <AddButton addMode={true}/>
          <CancelButton/>
        </div>
      )}

      {screen === 'add card' && (
        <CardInput method={null} onComplete={props.onComplete ?? (() => setAddCard(false))}/>
      )}

      {screen === 'add us bank account' && (
        <UsBankAccountInput method={null} onComplete={props.onComplete ?? (() => setEditCard(null))}/>
      )}

      {screen === 'edit card' && (
        <CardInput
          method={props.businessBilling.paymentMethods.find(method => method.id === editCard) as types.PaymentMethodCard}
          onComplete={() => setEditCard(null)}
        />
      )}

      {screen === 'edit us bank account' && (
        <UsBankAccountInput
          method={props.businessBilling.paymentMethods.find(method => method.id === editCard) as types.PaymentMethodBankAccount}
          onComplete={() => setEditUsBankAccount(null)}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  businessBilling: state.businessBilling
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    ...businessBillingActions
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodList)
