import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as businessBillingActions from 'Scenes/BusinessBilling/actions'
import * as types from 'Scenes/BusinessBilling/types'
import Goods from './Goods'
import Cart from './Cart'
import PaymentMethodList from './PaymentMethodList'
import ActivatePopup from './ActivatePopup'
// import './styles.scss'
import 'reactjs-popup/dist/index.css'

type ShowcaseProps = {} & types.PropsWithBusinessBillingState & types.BusinessBillingStateActions

type ShowcaseScreen = 'Goods' | 'Cart' | 'PaymentMethodList' | 'Done'

function Showcase (props: ShowcaseProps) {
  const [screen, setScreen] = useState<ShowcaseScreen>('Goods')
  const [showActivatePopup, setShowActivatePopup] = useState<boolean>(false)
  const [paymentMethodListComplete, setPaymentMethodListComplete] = useState<boolean>(false)

  const handleGoodsComplete = () => setScreen('Cart')
  const handleOnCartGoShopping = () => setScreen('Goods')
  const handleOnCartGoPaymentMethods = () => setScreen('PaymentMethodList')
  const handleActivated = () => {
    setScreen('Goods')
    setShowActivatePopup(false)
    props.businessBillingCartSelect(null)
  }
  const handleCartCheckout = (paymentMethodId: string) => {
    if (paymentMethodId) {
      props.businessBillingDefaultPaymentMethodSet(paymentMethodId)
      setShowActivatePopup(true)
    } else {
      setScreen('PaymentMethodList')
    }
  }

  const handlePaymentMethodListComplete = () => {
    setPaymentMethodListComplete(true)
    setScreen('Cart')
  }

  useEffect(() => {
    if (props.businessBilling.paymentMethods.length > 0 && paymentMethodListComplete) {
      setPaymentMethodListComplete(false)
      setShowActivatePopup(true)
    }
  }, [props.businessBilling.paymentMethods, paymentMethodListComplete])

  return (
    <div className="showcase">
      {screen === 'Goods' && (
        <Goods onComplete={handleGoodsComplete}/>
      )}
      {screen === 'Cart' && (
        <Cart onGoShopping={handleOnCartGoShopping} onGoPaymentMethods={handleOnCartGoPaymentMethods} onCheckout={handleCartCheckout}/>
      )}
      {screen === 'PaymentMethodList' && (
        <PaymentMethodList onComplete={handlePaymentMethodListComplete}/>
      )}
      {screen === 'Done' && (
        <>Done!</>
      )}

      <ActivatePopup
        open={showActivatePopup}
        onActivated={handleActivated}
        onCancel={() => setShowActivatePopup(false)}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  businessBilling: state.businessBilling
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    ...businessBillingActions
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(Showcase)
