import React from 'react'
import './styles.scss'

type UsBankAccountInputProps = {
  onComplete: () => void
  onCancel: () => void
}

export default function Mandate (props: UsBankAccountInputProps) {
  const handleAccept = (event?) => {
    event.preventDefault()
    event.stopPropagation()

    props.onComplete()
  }

  const handleCancel = (event?) => {
    event.preventDefault()
    event.stopPropagation()

    props.onCancel()
  }

  return (
    <div className="us-bank-account-input--mandate">
      <p>
       By clicking [accept], you authorize  Collective Learning Inc. to debit the bank account specified
       above for agreed upon amount owed for charges arising from your use of <strong>Collective Learning Inc.</strong>’ services
       and/or purchase of products from <strong>Collective Learning Inc.</strong>, pursuant to <strong>Collective
       Learning Inc.</strong> website and terms, until this authorization is revoked. You may amend or cancel this
       authorization at any time by providing notice to <strong>Collective Learning Inc.</strong> with 30 (thirty) days notice.
      </p>

      <p>
       If you use <strong>Collective Learning Inc.</strong>’ services or purchase additional products periodically pursuant
       to <strong>Collective Learning Inc.</strong> terms, you authorize <strong>Collective Learning Inc.</strong> to debit your bank account periodically.
       Payments that fall outside of the regular debits authorized above will only be debited
       after your authorization is obtained.
      </p>

      <div className="actions">
        <button onClick={(event) => handleAccept(event)}>Accept</button>
        <button onClick={(event) => handleCancel(event)}>Decline</button>
      </div>
    </div>
  )
}
