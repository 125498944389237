import * as types from './types'
import { createAction, createCustomAction } from 'typesafe-actions'
import * as commands from './commands'

export const adoptState = createCustomAction(
  commands.ADOPT_STATE,
  (state: types.FormEditorState) => ({ payload: state })
)

export const setTitle = createCustomAction(
  commands.SET_TITLE,
  (value: string) => ({ payload: value })
)

export const setDescription = createCustomAction(
  commands.SET_DESCRIPTION,
  (value: string) => ({ payload: value })
)

export const newQuestion = createAction(commands.NEW_QUESTION)()
export const updateQuestion = createCustomAction(
  commands.UPDATE_QUESTION,
  (index: number, question: types.Question) => ({ payload: { index, question } })
)

export const duplicateQuestion = createCustomAction(
  commands.DUPLICATE_QUESTION,
  (index: number) => ({ payload: index })
)

export const deleteQuestion = createCustomAction(
  commands.DELETE_QUESTION,
  (index: number) => ({ payload: index })
)

export const reset = createAction(commands.RESET)()

export const sortQuestions = createCustomAction(
  commands.SORT_QUESTIONS,
  (oldIndex: number, newIndex: number) => ({ payload: { oldIndex, newIndex } })
)

export const setLockoutDate = createCustomAction(
  commands.LOCKOUT_DATE_SET,
  (value: string) => ({ payload: value })
)
