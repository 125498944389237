export const BUSINESSBILLING_ADOPT_STATE = 'BUSINESSBILLING_ADOPT_STATE'
export const BUSINESSBILLING_CART_SELECT = 'BUSINESSBILLING_CART_SELECT'
export const BUSINESSBILLING_ACTIVE_SUBSCRIPTION_SELECT = 'BUSINESSBILLING_ACTIVE_SUBSCRIPTION_SELECT'
export const BUSINESSBILLING_BILLING_PERIOD_SET = 'BUSINESSBILLING_BILLING_PERIOD_SET'
export const BUSINESSBILLING_PAYMENT_METHODS_SET = 'BUSINESSBILLING_PAYMENT_METHODS_SET'
export const BUSINESSBILLING_INVOICES_SET = 'BUSINESSBILLING_INVOICES_SET'
export const BUSINESSBILLING_DEFAULT_PAYMENT_METHOD_SET = 'BUSINESSBILLING_DEFAULT_PAYMENT_METHOD_SET'
export const BUSINESSBILLING_BANK_ACCOUNT_SETUP_INTENT_SET = 'BUSINESSBILLING_BANK_ACCOUNT_SETUP_INTENT_SET' // called from backend only
export const BUSINESSBILLING_BANK_ACCOUNT_SETUP_INTENT_RESET = 'BUSINESSBILLING_BANK_ACCOUNT_SETUP_INTENT_RESET'
export const BUSINESSBILLING_COUPON_SET = 'BUSINESSBILLING_COUPON_SET'
