import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import * as types from '../../types'
import './styles.scss'
import classNames from 'classnames'

type LatestSignupsProps = {} & types.PropsWithBusinessDashboardState

function LatestSignups (props: LatestSignupsProps) {
  console.log('latest signups', props.businessDashboard.latestSignups)

  return (
    <div className="latest-signups">
      <table className="signups">
        <thead className="header">
          <tr>
            <th className="column student">Student</th>
            <th className="column class">Class</th>
          </tr>
        </thead>
        <tbody className="list">
          {props.businessDashboard.latestSignups?.list?.map((signup, index) => ([
            props.businessDashboard.latestSignups?.lastSeenId === signup.id && index > 0 && (
              <tr className={classNames({ signup: true })} key='new'>
                <td colSpan={2}>
                  <div className="separator intent-attention">
                    <span className="separator-content">New</span>
                  </div>
                </td>
              </tr>
            ),
            <tr className={classNames({ signup: true, odd: index % 2 === 0 })} key={signup.id}>
              <td className="student">
                <div className="avatar">
                  {signup.person.avatar.exists
                    ? <img src={signup.person.avatar.file}/>
                    : <div className="abbr">{signup.person.avatar.abbr}</div>
                  }
                </div>
                <div className="text-info">
                  <div className="name">{signup.student.name}</div>
                  <div className="adult">by {signup.adult.name}</div>
                </div>
              </td>
              <td className="classroom">
                <div className="title">{signup.classroom.title}</div>
                <div className="date-range">
                  <div className="date from">{moment(signup.dateRange.from * 1000).utc().format('MMM DD')}</div>
                  {signup.dateRange.from !== signup.dateRange.to && (
                    <div className="date to">{moment(signup.dateRange.to * 1000).utc().format('MMM DD')}</div>
                  )}
                </div>
              </td>
            </tr>
          ]))}
        </tbody>
      </table>
    </div>
  )
}

const mapStateToProps = (state) => ({
  businessDashboard: state.businessDashboard
})

export default connect(mapStateToProps)(LatestSignups)
