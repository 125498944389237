import React from 'react'
import { FallbackProps } from 'react-error-boundary'

function ErrorFallback (props: FallbackProps, error: Error) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={props.resetErrorBoundary}>Try again</button>
    </div>
  )
}

export default ErrorFallback
