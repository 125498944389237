import * as types from './types'
import { createReducer } from 'typesafe-actions'
import * as commands from './commands'
import arrayMove from 'array-move'

const initialState: types.FormEditorState = {
  title: '',
  description: '',
  lockoutDate: null,
  questions: []
}

export default createReducer(initialState)
  .handleType(
    commands.RESET,
    state => ({ ...state, ...initialState })
  )
  .handleType(
    commands.ADOPT_STATE,
    (state, action) => action.payload
  )
  .handleType(
    commands.SET_TITLE,
    (state, action) => ({
      ...state,
      title: action.payload
    })
  )
  .handleType(
    commands.SET_DESCRIPTION,
    (state, action) => ({
      ...state,
      description: action.payload
    })
  )
  .handleType(
    commands.NEW_QUESTION,
    state => ({
      ...state,
      questions: [...state.questions, { type: null, title: '', required: false, helpText: '', options: [], allowedFileTypes: [] }]
    })
  )
  .handleType(
    commands.UPDATE_QUESTION,
    (state, action) => ({
      ...state,
      questions: Object.assign([], state.questions, { [action.payload.index]: action.payload.question })
    })
  )
  .handleType(
    commands.DUPLICATE_QUESTION,
    (state, action) => {
      const genTitle = (prefix: string, iteration: number): string => {
        if (prefix.indexOf(' copy') !== -1) {
          const reversed = prefix.split('').reverse().join('')
          const spacePos = reversed.indexOf(' ')
          if (spacePos !== -1) {
            const s = prefix.substr(prefix.length - spacePos)
            if (s.trim() !== '' && +(s)) {
              let index = +s
              while (index < 100) {
                const result = prefix.substr(0, prefix.length - spacePos - 1) + ' ' + (index + 1)
                if (state.questions.findIndex(question => question.title === result) === -1) {
                  return result
                }
                index++
              }
            }
          }
        }
        let s = prefix
        if (prefix.indexOf(' copy') === -1) {
          s = s + ' copy'
        }

        let index = iteration
        while (index < 100) {
          const result = s + (index !== 0 ? (' ' + (index + 1)) : '')
          if (state.questions.findIndex(question => question.title === result) === -1) {
            return result
          }
          index++
        }

        return prefix
      }

      // remove id from duplicated question, to avoid replacing of the original one
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, ...newQuestion } = state.questions[action.payload]

      return {
        ...state,
        questions: [
          ...state.questions,
          {
            ...newQuestion,
            title: genTitle(newQuestion.title, 0)
          }
        ]
      }
    }
  )
  .handleType(
    commands.DELETE_QUESTION,
    (state, action) => ({
      ...state,
      questions: [...state.questions.slice(0, action.payload), ...state.questions.slice(action.payload + 1)]
    })
  )
  .handleType(
    commands.SORT_QUESTIONS,
    (state, action) => ({
      ...state,
      questions: arrayMove(state.questions, action.payload.oldIndex, action.payload.newIndex)
    })
  )
  .handleType(
    commands.LOCKOUT_DATE_SET,
    (state, action) => ({
      ...state,
      lockoutDate: action.payload
    })
  )
