import React from 'react'
import './styles.scss'

type SubscriptionProps = {
  title: string
  children: React.ReactNode
}

function ContentSection (props: SubscriptionProps) {
  return (
    <div className="content-section">
      <h2 className="section-title">{props.title}</h2>
      <div className="content-section-children">
        {props.children}
      </div>
    </div>
  )
}

export default ContentSection
