import * as types from './types'
import { createReducer } from 'typesafe-actions'
import * as commands from './constants'
import moment from 'moment'

const initialState: types.BusinessDashboardState = {
  isFranchisor: null,
  franchisees: [],
  mainBusiness: null,
  mainBusinessCurrency: null,
  currentBusiness: null,
  period: 'ytd',
  calendar: { events: [] },
  latestSignups: { list: [] },
  analytics: {
    enabled: false,
    filter: {
      from: moment().subtract(7, 'days').unix(),
      to: moment().unix()
    },
    data: {}
  }
}

export default createReducer(initialState)
  .handleType(
    commands.BUSINESSDASHBOARD_ADOPT_STATE,
    (state, action) => {
      console.log(commands.BUSINESSDASHBOARD_ADOPT_STATE, action)
      return {
        ...state,
        ...action.payload
      }
    }
  )
  .handleType(
    commands.BUSINESSDASHBOARD_FILTER_SET,
    (state, action) => ({
      ...state,
      analytics: {
        ...state.analytics,
        filter: action.payload
      }
    })
  )
  // .handleType(
  //   commands.BUSINESSDASHBOARD_TOTAL_SIGNUPS_SET,
  //   (state, action) => ({
  //     ...state,
  //     analytics: {
  //       ...state.analytics,
  //       totalSignups: action.payload
  //     }
  //   })
  // )
  .handleType(
    commands.BUSINESSDASHBOARD_ANALYTICS_SET,
    (state, action) => ({
      ...state,
      analytics: {
        data: action.payload
      }
    })
  )
  // .handleType(
  //   commands.BUSINESSDASHBOARD_TOTAL_CHARGED_SET,
  //   (state, action) => ({
  //     ...state,
  //     analytics: {
  //       ...state.analytics,
  //       totalCharged: action.payload
  //     }
  //   })
  // )
  // .handleType(
  //   commands.BUSINESSDASHBOARD_REFUNDS_SET,
  //   (state, action) => ({
  //     ...state,
  //     analytics: {
  //       ...state.analytics,
  //       refunds: action.payload
  //     }
  //   })
  // )
  // .handleType(
  //   commands.BUSINESSDASHBOARD_POPULAR_CLASSES_SET,
  //   (state, action) => ({
  //     ...state,
  //     analytics: {
  //       ...state.analytics,
  //       popularClasses: action.payload
  //     }
  //   })
  // )
  // .handleType(
  //   commands.BUSINESSDASHBOARD_SCORES_SET,
  //   (state, action) => ({
  //     ...state,
  //     analytics: {
  //       ...state.analytics,
  //       scores: action.payload
  //     }
  //   })
  // )
  // .handleType(
  //   commands.BUSINESSDASHBOARD_PAYOUTS_SET,
  //   (state, action) => ({
  //     ...state,
  //     analytics: {
  //       ...state.analytics,
  //       payouts: action.payload
  //     }
  //   })
  // )
  .handleType(
    commands.BUSINESSDASHBOARD_BUSINESS_SET,
    (state, action) => ({
      ...state,
      currentBusiness: action.payload
    })
  )
  .handleType(
    commands.BUSINESSDASHBOARD_CALENDAR_SET,
    (state, action) => ({
      ...state,
      calendar: action.payload
    })
  )
  .handleType(
    commands.BUSINESSDASHBOARD_PERIOD_SET,
    (state, action) => ({
      ...state,
      period: action.payload
    })
  )
