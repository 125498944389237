import React, { useState } from 'react'
import Cell from '../Cell'
import ReactECharts from 'echarts-for-react'
import { connect } from 'react-redux'
import * as types from '../../types'
import { filterByPeriod } from '../helpers'
import { EmptyState } from '../EmptyState'
import moment from 'moment'
import './styles.scss'

type AverageScoreChartProps = types.PropsWithBusinessDashboardState

type Score = [string, number]
type BusinessScores = {
  [nid: number]: Score[]
}

function AverageScoreChart (props: AverageScoreChartProps) {
  const [period, setPeriod] = useState<types.Period>('ytd')

  const averageScore: BusinessScores = props.businessDashboard.analytics.data[props.businessDashboard.currentBusiness].average_score[period]

  if (!averageScore || Object.keys(averageScore).length === 0) {
    return <Cell title="Average score">
      <EmptyState message="Currently there is no information to display"/>
    </Cell>
  }

  const totalAveragesSum = Object.values(averageScore).reduce((result, scores) => (
    result + scores[scores.length - 1][1]
  ), 0)
  const totalAverageScore = (totalAveragesSum / Object.keys(averageScore).length).toFixed(2)

  // [nid, scores.filter(filterByPeriod(period, true))]
  const data = Object.fromEntries(Object.entries(averageScore).map(([nid, scores]) => ([nid, (scores as Score[]).filter(filterByPeriod(period, true))])))

  console.log('Average score', data)

  // const prevData = Object.fromEntries(Object.entries(averageScore).map(([nid, scores]) => ([nid, (scores as Score[]).filter(filterByPeriod(period, false))])))

  const dates = Object.values(data).reduce((result, scores) => {
    return [...result, ...scores.map(rec => rec[0])]
  }, [])
    .filter((value, index, array) => (array.indexOf(value) === index))
    .map(rec => moment(rec).format(period === 'mtd' ? 'Do' : 'MMM'))

  console.log('dates', dates)

  const tooltipDateFormat = period === 'mtd'
    ? 'MMM DD, YYYY'
    : 'MMMM YYYY'

  const businessName = (nid) => (
    nid === props.businessDashboard.mainBusiness
      ? 'Main business'
      : props.businessDashboard.franchisees.find(item => item.id === nid)?.title
  )

  const businessColor = (nid) => (
    nid === props.businessDashboard.mainBusiness
      ? 'orange'
      : props.businessDashboard.franchisees.find(item => item.id === nid)?.color
  )

  const getOptionLine = () => ({
    xAxis: [{ type: 'category', data: dates }],
    yAxis: [{ type: 'value', min: 'dataMin', max: 'dataMax' }],
    series: Object.entries(data).map(([nid, scores]) => ({
      name: businessName(nid),
      type: 'line',
      smooth: true,
      data: scores.map(rec => ({
        value: rec[1],
        date: rec[0]
      })),
      color: businessColor(nid)
    })),
    tooltip: {
      trigger: 'item',
      formatter: (params) => (
        `${moment(params.data.date).format(tooltipDateFormat)}:<br/>${params.seriesName}:<br/>Average score: ${params.value}`
      )
    }
  })

  const getOptionGauge = () => ({
    series: [
      {
        type: 'gauge',
        // startAngle: 180,
        // endAngle: 0,
        min: 0,
        max: 5,
        splitNumber: 5,
        axisLine: {
          lineStyle: {
            width: 6,
            color: [
              [0.60, 'darkred'],
              [0.80, 'orange'],
              [1.00, 'darkgreen']
            ]
          }
        },
        pointer: {
          itemStyle: {
            color: 'inherit'
          }
        },
        axisTick: {
          distance: -6,
          length: 3,
          lineStyle: {
            color: '#fff',
            width: 2
          }
        },
        splitLine: {
          distance: -30,
          length: 30,
          lineStyle: {
            color: '#fff',
            width: 4
          }
        },
        axisLabel: {
          color: 'inherit',
          distance: 10,
          fontSize: 8
        },
        detail: {
          valueAnimation: true,
          formatter: '{value} out of 5',
          color: 'inherit',
          offsetCenter: [0, '100%']
        },
        data: [
          {
            value: totalAverageScore
          }
        ]
      }
    ]
  })

  return (
    <Cell title="Average scores" onPeriodChange={setPeriod} period={period}>
      <ReactECharts
        option={getOptionGauge()}
        notMerge={true}
        lazyUpdate={true}
      />
      <ReactECharts
        option={getOptionLine()}
        notMerge={true}
        lazyUpdate={true}
      />
    </Cell>
  )
}

const mapStateToProps = (state) => ({
  businessDashboard: state.businessDashboard
})

export default connect(mapStateToProps)(AverageScoreChart)
