import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as businessBillingActions from '../../actions'
import * as types from 'Scenes/BusinessBilling/types'
import PlanSelect from './PlanSelect'
import CustomizedSwitch from 'Scenes/BusinessBilling/components/Switch'
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion'
import './styles.scss'
import './accordion.css'

type BusinessBillingProps = {
  onComplete?: () => void
} & types.PropsWithBusinessBillingState & types.BusinessBillingActions

function Goods (props: BusinessBillingProps) {
  const [selectedBusinessPlan, setSelectedBusinessPlan] = useState<types.BusinessPlan>(props.businessBilling.businessPlans.find(plan => plan.machineName === (props.businessBilling.cart ?? props.businessBilling.activeSubscription)?.saasPlanMachineName))
  const [selectedSaasAddons, setSelectedSaasAddons] = useState<types.BusinessPlan[]>(((props.businessBilling.cart ?? props.businessBilling.activeSubscription)?.saasAddonsMachineNames ?? []).map(addon => (props.businessBilling.saasAddons.find(plan => plan.machineName === addon))))
  const [selectedMarketingPlan, setSelectedMarketingPlan] = useState<types.BusinessPlan>(props.businessBilling.marketingPlans.find(plan => plan.machineName === (props.businessBilling.cart ?? props.businessBilling.activeSubscription)?.marketingPlanMachineName))

  const [purchasingBusinessPlan, setPurchasingBusinessPlan] = useState<types.BusinessPlan>(props.businessBilling.businessPlans.find(plan => plan.machineName === props.businessBilling.cart?.saasPlanMachineName))
  const [purchasingSaasAddons, setPurchasingSaasAddons] = useState<types.BusinessPlan[]>((props.businessBilling.cart?.saasAddonsMachineNames ?? []).map(addon => (props.businessBilling.saasAddons.find(plan => plan.machineName === addon))))
  const [purchasingMarketingPlan, setPurchasingMarketingPlan] = useState<types.BusinessPlan>(props.businessBilling.marketingPlans.find(plan => plan.machineName === props.businessBilling.cart?.marketingPlanMachineName))

  const handleBusinessPlanChanged = (plan: types.BusinessPlan) => {
    setSelectedBusinessPlan(plan)
    setPurchasingBusinessPlan(plan)
  }

  const handleSaasAddonChanged = (plans: types.BusinessPlan[]) => {
    setSelectedSaasAddons(plans)
    setPurchasingSaasAddons(plans)
  }

  const handleMarketingPlanChanged = (plan: types.BusinessPlan) => {
    setSelectedMarketingPlan(plan)
    setPurchasingMarketingPlan(plan)
  }

  console.log(
    'Goods',
    { selectedBusinessPlan, selectedSaasAddons, selectedMarketingPlan },
    { purchasingBusinessPlan, purchasingSaasAddons, purchasingMarketingPlan }
  )

  const handleComplete = e => {
    e.preventDefault()
    e.stopPropagation()

    const cart: types.Cart = {
      saasPlanMachineName: selectedBusinessPlan ? selectedBusinessPlan.subscriptionOptions[0].planMachineName : null,
      saasAddonsMachineNames: selectedSaasAddons.map(addon => addon.subscriptionOptions[0].planMachineName),
      marketingPlanMachineName: selectedMarketingPlan ? selectedMarketingPlan.subscriptionOptions[0].planMachineName : null,
      period: props.businessBilling.period,
      coupon: null
    }

    props.businessBillingCartSelect(cart)

    props.onComplete()
  }

  return (
    <div className="goods">
      <div className="goods-section section-saas">
        <div className="text-column">
          <h3>Welcome to BeAKid</h3>
          <h2>Choose your payment plan</h2>
          <Accordion preExpanded={['trial']}>
            <AccordionItem uuid="trial">
              <AccordionItemHeading>
                <AccordionItemButton>
                  How does a 7-Day free trial work?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  Once you sign up to BeAKid your system will be fully functional.
                  All you need to do is create classes and activate them.
                  Your customers will be able to go through registration, payment and all other features included in the plan.
                  Once the 7 day trial period is over, your account will be charged based on the plan and addons you chose.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Why do you need my credit card for the free trial
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  Working with kids is a full time job and things can get busy.
                  We don&apos;t want a situation where after 7 days your customers can no longer register because you got busy and
                  did not put in your credit or ACH information.  However, note you will not be charged for the duration of
                  the 7 days and can cancel during this time.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  How do I cancel if I am not super impressed?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  Canceling is really easy.
                  Simply reach out on live chat to our support or email us at <a href="mailto:support@beakid.com">support@beakid.com</a> and we will cancel your
                  subscription within 24 hours.
                  Please note that if you signed up to a yearly subscription at a discount rate your subscription will
                  continue to remain active for the duration of the year and will cancel after 12 months from the date
                  of registration.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <div className="plan-section">
          <div className="period">
            <label>Monthly</label>
            <CustomizedSwitch
              checked={props.businessBilling.period === 'yearly'}
              onChange={() => props.businessBillingPeriodSet(props.businessBilling.period === 'monthly' ? 'yearly' : 'monthly')}
            />
            <label>Yearly<span className="discount">Get 10% off</span></label>
          </div>

          <PlanSelect
            section="saas"
            plans={props.businessBilling.businessPlans}
            onChange={(plan: types.BusinessPlan) => handleBusinessPlanChanged(plan)}
            selected={selectedBusinessPlan}
          />
        </div>
      </div>

      <div className="goods-section">
        <div className="text-column">
          <h2>Addons</h2>

          <p>
            You can improve your setup by considering a couple of the following addons.
            They will make yours and your customers experience better, faster and more familiar.
          </p>
        </div>

        <div className="plan-section">
          <PlanSelect
            section="saas-addons"
            plans={props.businessBilling.saasAddons}
            onChange={(plans: types.BusinessPlan[]) => handleSaasAddonChanged(plans)}
            disabled={selectedBusinessPlan === undefined}
            selected={selectedSaasAddons}
            multiple
          />
        </div>
      </div>

      <div className="goods-section">
        <div className="text-column">
          <h2>Marketing plan</h2>

          <p>
            Looking to get more customers to your business or organization?
            Instead of paying large fees for short lived ads consider signing up to BeAKid marketing plan that offers
            a year around promotion of your company for the same cost that other charge for a couple of months.
            Longer presence on our site means more eyes over longer period of time.
          </p>
        </div>

        <div className="plan-section">
          <PlanSelect
            section="marketing"
            plans={props.businessBilling.marketingPlans}
            onChange={(plan: types.BusinessPlan) => handleMarketingPlanChanged(plan)}
            selected={selectedMarketingPlan}
          />
        </div>
      </div>

      <div className="actions">
        <button className="btn-submit" onClick={handleComplete} disabled={!(purchasingBusinessPlan || purchasingSaasAddons.length > 0 || purchasingMarketingPlan)}>Continue</button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  businessBilling: state.businessBilling
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    ...businessBillingActions
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(Goods)
