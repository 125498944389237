import Registry from 'Beakid/Registry'
import BusinessSetup from './BusinessSetup'
import BusinessDashboard from './BusinessDashboard'
// import EventManagement from './EventManagement'
import FormEditor from './FormEditor'
import BusinessBilling from './BusinessBilling'
import StripeConnect from './StripeConnect'

Registry.Add('BusinessSetup', BusinessSetup)
Registry.Add('BusinessDashboard', BusinessDashboard)
Registry.Add('FormEditor', FormEditor)
// Registry.Add('EventManagement', EventManagement)
Registry.Add('BusinessBilling', BusinessBilling)
Registry.Add('StripeConnect', StripeConnect)
