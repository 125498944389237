export const BUSINESSSETUP_ADOPT_STATE = 'BUSINESSSETUP_ADOPT_STATE'
export const BUSINESSSETUP_SAVE_TASK = 'BUSINESSSETUP_SAVE_TASK'
export const BUSINESSSETUP_SAVE_TASK_DATA = 'BUSINESSSETUP_SAVE_TASK_DATA'
export const BUSINESSSETUP_SAVE_TASK_COMPLETE = 'BUSINESSSETUP_SAVE_TASK_COMPLETE'

export const BUSINESSSETUP_PLAN_SELECT = 'BUSINESSSETUP_PLAN_SELECT'
export const BUSINESSSETUP_PLAN_SET = 'BUSINESSSETUP_PLAN_SET'
export const BUSINESSSETUP_BILLING_PERIOD_SET = 'BUSINESSSETUP_BILLING_PERIOD_SET'
export const BUSINESSSETUP_PAYMENT_METHODS_SET = 'BUSINESSSETUP_PAYMENT_METHODS_SET'
export const BUSINESSSETUP_DEFAULT_PAYMENT_METHOD_SET = 'BUSINESSSETUP_DEFAULT_PAYMENT_METHOD_SET'
export const BUSINESSSETUP_BANK_ACCOUNT_SETUP_INTENT_SET = 'BUSINESSSETUP_BANK_ACCOUNT_SETUP_INTENT_SET'
export const BUSINESSSETUP_BANK_ACCOUNT_SETUP_INTENT_RESET = 'BUSINESSSETUP_BANK_ACCOUNT_SETUP_INTENT_RESET'

// // wizard stages and steps
// export const BUSINESSSETUP_GOTO = 'BUSINESSSETUP_GOTO'
// export const BUSINESSSETUP_ABOUT_SUBMIT = 'BUSINESSSETUP_ABOUT_SUBMIT'
// // location data
// export const BUSINESSSETUP_LOCATION_ADD = 'BUSINESSSETUP_LOCATION_ADD'
// export const BUSINESSSETUP_LOCATION_DELETE = 'BUSINESSSETUP_LOCATION_DELETE'
// export const BUSINESSSETUP_LOCATION_UPDATE = 'BUSINESSSETUP_LOCATION_UPDATE'
// export const BUSINESSSETUP_LOCATION_MAKE_MAIN = 'BUSINESSSETUP_LOCATION_MAKE_MAIN'
// // location form
// export const BUSINESSSETUP_LOCATIONFORM_CREATE = 'BUSINESSSETUP_LOCATIONFORM_CREATE'
// export const BUSINESSSETUP_LOCATIONFORM_EDIT = 'BUSINESSSETUP_LOCATIONFORM_EDIT'
// export const BUSINESSSETUP_LOCATIONFORM_LIST = 'BUSINESSSETUP_LOCATIONFORM_LIST'
// // staff data
// export const STAFF_ADD = 'BUSINESSSETUP_STAFF_ADD'
// export const STAFF_DELETE = 'BUSINESSSETUP_STAFF_DELETE'
// export const STAFF_UPDATE = 'BUSINESSSETUP_STAFF_UPDATE'
// // staff form
// export const STAFFFORM_INRODUCE_OWNER = 'BUSINESSSETUP_STAFFFORM_INRODUCE_OWNER'
// export const STAFFFORM_CREATE = 'BUSINESSSETUP_STAFFFORM_CREATE'
// export const STAFFFORM_EDIT = 'BUSINESSSETUP_STAFFFORM_EDIT'
// export const STAFFFORM_LIST = 'BUSINESSSETUP_STAFFFORM_LIST'
// // tuition data
// export const BUSINESSSETUP_TUITION_ADD = 'BUSINESSSETUP_TUITION_ADD'
// export const BUSINESSSETUP_TUITION_DELETE = 'BUSINESSSETUP_TUITION_DELETE'
// export const BUSINESSSETUP_TUITION_UPDATE = 'BUSINESSSETUP_TUITION_UPDATE'
// // tuition form
// export const BUSINESSSETUP_TUITIONFORM_CREATE = 'BUSINESSSETUP_TUITIONFORM_CREATE'
// export const BUSINESSSETUP_TUITIONFORM_EDIT = 'BUSINESSSETUP_TUITIONFORM_EDIT'
// export const BUSINESSSETUP_TUITIONFORM_LIST = 'BUSINESSSETUP_TUITIONFORM_LIST'
// // class data
// export const BUSINESSSETUP_CLASS_ADD = 'BUSINESSSETUP_CLASS_ADD'
// export const BUSINESSSETUP_CLASS_DELETE = 'BUSINESSSETUP_CLASS_DELETE'
// export const BUSINESSSETUP_CLASS_UPDATE = 'BUSINESSSETUP_CLASS_UPDATE'
// // class form
// export const BUSINESSSETUP_CLASSFORM_CREATE = 'BUSINESSSETUP_CLASSFORM_CREATE'
// export const BUSINESSSETUP_CLASSFORM_EDIT = 'BUSINESSSETUP_CLASSFORM_EDIT'
// export const BUSINESSSETUP_CLASSFORM_LIST = 'BUSINESSSETUP_CLASSFORM_LIST'
// // sessions data
// export const BUSINESSSETUP_SESSION_ADD = 'BUSINESSSETUP_SESSION_ADD'
// export const BUSINESSSETUP_SESSION_DELETE = 'BUSINESSSETUP_SESSION_DELETE'
// export const BUSINESSSETUP_SESSION_UPDATE = 'BUSINESSSETUP_SESSION_UPDATE'
// // sessions form
// export const BUSINESSSETUP_SESSIONFORM_CREATE = 'BUSINESSSETUP_SESSIONFORM_CREATE'
// export const BUSINESSSETUP_SESSIONFORM_EDIT = 'BUSINESSSETUP_SESSIONFORM_EDIT'
// export const BUSINESSSETUP_SESSIONFORM_LIST = 'BUSINESSSETUP_SESSIONFORM_LIST'

// export const BUSINESSSETUP_EPAYMENT_BUSINESSTYPE = 'BUSINESSSETUP_EPAYMENT_BUSINESSTYPE'
// export const BUSINESSSETUP_EPAYMENT_SETTINGS = 'BUSINESSSETUP_EPAYMENT_SETTINGS'
