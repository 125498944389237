import moment, { Moment } from 'moment'
import { Period } from '../types'

const getFilter = (startDate: Moment, endDate: Moment) => (rec) => {
  const recDate = moment(rec[0])
  return recDate.isAfter(startDate) && recDate.isBefore(endDate)
}

const dates = {
  mtd: {
    current: {
      begin: moment([]).date(0),
      end: moment([]).date(moment([]).daysInMonth() - 1)
    },
    previous: {
      begin: moment([]).subtract(1, 'months').date(0),
      end: moment([]).subtract(1, 'months').date(moment([]).subtract(1, 'months').daysInMonth() - 1)
    }
  },
  ytd: {
    current: {
      begin: moment([]).month(0).date(0),
      end: moment([]).month(11).date(moment([]).month(11).daysInMonth() - 1)
    },
    previous: {
      begin: moment([]).subtract(1, 'years').month(0).date(0),
      end: moment([]).subtract(1, 'years').month(11).date(moment([]).subtract(1, 'years').month(11).daysInMonth() - 1)
    }
  }
}

export const filterByPeriod = (period: Period, isCurrent: boolean) => (
  getFilter(
    dates[period][isCurrent ? 'current' : 'previous'].begin,
    dates[period][isCurrent ? 'current' : 'previous'].end
  )
)

export const allMonthDays = Array.from(Array(moment([]).daysInMonth()).keys()).map(index => (index + 1))
export const allYearMonths = Array.from(Array(12).keys())

export const fillGaps = (data, period: Period) => (
  data.map(currencyGroup => ({
    ...currencyGroup,
    values: period === 'mtd'
      ? allMonthDays.map(index => {
        const category = moment([]).date(index).format('YYYY-MM-DD')
        const reportRecord = currencyGroup.values.find(rec => rec[0] === category)
        return [
          category,
          reportRecord ? reportRecord[1] : 0,
          reportRecord ? reportRecord[2] : 0
        ]
      })
      : allYearMonths.map(index => {
        const category = moment([]).month(index).format('YYYY-MM')
        const reportRecord = currencyGroup.values.find(rec => rec[0] === category)
        return [
          category,
          reportRecord ? reportRecord[1] : 0,
          reportRecord ? reportRecord[2] : 0
        ]
      })
  }))
)
