export enum QuestionType {
  Text = 'textfield',
  Long = 'textarea',
  Dropdown = 'select',
  Radios = 'radio',
  Checkboxes = 'checkbox',
  File = 'file',
  Signature = 'signature'
}

type QuestionId = number | string

export type QuestionOption = {
  name: string,
  value: string
}

export enum QuestionFileAllowedType {
  Pdf = 'pdf',
  Pictures = 'pictures',
  Xls = 'xls',
  Docs = 'docs'
}

export type AttachmentFile = {
  name: string,
  fid: number
}

export type Question = {
  id?: QuestionId,
  type: null | QuestionType,
  title: string,
  required: boolean,
  helpText?: string,
  options?: QuestionOption[],
  allowedFileTypes?: QuestionFileAllowedType[],
  attachment?: AttachmentFile
}
