import React, { useEffect, useRef, useState } from 'react'
import FormButton from 'Beakid/FormButton'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import EPaymentIndividualInput from './components/EPaymentIndividualInput'
import EPaymentCompanyInput, { EPaymentCompanyFields } from './components/EPaymentCompanyInput'
import * as businessSetupActions from 'Scenes/BusinessSetup/actions'
import * as types from 'Scenes/BusinessSetup/types'
import classNames from 'classnames'
import epaymentImg from './images/epayment.svg'
import axios from 'axios'
import './styles.scss'
import Header from '../Header'

type TaskPaymentProps = {
  task: types.Task
  onDone: () => void
} & types.PropsWithBusinessSetupState & types.BusinessSetupActions

export interface StepAccountEPaymentError {
  fein?: string,
  ssnLast4?: string,
  businessUrl?: string,
  phoneNumber?: string,
  streetAddress?: string,
  postalCode?: string,
  tosAccepted?: string
}

function TaskPayment (props: TaskPaymentProps) {
  const [data, setData] = useState(props.task.data as types.DataPayment)
  const { businessType } = data
  const taskCompletedRef = useRef<number>()
  taskCompletedRef.current = props.businessSetup.tasks[2].completed

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_validated, setValidated] = useState<boolean>(false)

  const [error, setError] = useState<StepAccountEPaymentError>({})

  const handleBusinessTypeClick = (businessType: types.BusinessType) => setData({ ...data, businessType })
  // const handleFieldsChange = (fields: EPaymentCompanyFields) => props.businessSetupEPaymentSettings(fields)
  const handleFieldsChange = (fields: EPaymentCompanyFields) => setData({ ...data, ...fields })

  const individualPreValidated = (): boolean => {
    const { ssnLast4, businessUrl, phoneNumber, streetAddress, postalCode, tosAccepted } = data

    return ssnLast4 !== '' &&
      businessUrl !== '' &&
      phoneNumber !== '' &&
      streetAddress !== '' &&
      postalCode !== '' &&
      tosAccepted
  }

  const companyPreValidated = (): boolean => {
    const { fein, ssnLast4, businessUrl, phoneNumber, streetAddress, postalCode, tosAccepted } = data

    return fein !== '' &&
      ssnLast4 !== '' &&
      businessUrl !== '' &&
      phoneNumber !== '' &&
      streetAddress !== '' &&
      postalCode !== '' &&
      tosAccepted
  }

  useEffect(() => {
    if (businessType === null) {
      setValidated(false)
    } else if (businessType === types.BusinessType.Individual && !individualPreValidated()) {
      setValidated(false)
    } else if (businessType === types.BusinessType.Company && !companyPreValidated()) {
      setValidated(false)
    } else {
      setValidated(true)
    }
  }, [data])

  const validateUrl = (url: string): boolean => {
    console.log('validating url', url)
    return true
  }

  const validatePostalCode = async (postalCode: string):Promise<boolean> => {
    return axios.get('/grovvup/setup/zip/validate/' + postalCode + '/US').then(res => {
      if (res.status !== 200) {
        console.warn('Postal code validating failed')
        return false
      }
      console.log('Postal code validated OK')
      return true
    }).catch(error => {
      console.error('Error in promise', error)
      return false
    })
  }

  const formValidate = async (): Promise<boolean> => {
    const { fein, ssnLast4, businessUrl, phoneNumber, streetAddress, postalCode } = data

    if (ssnLast4 === '') {
      setError({ ssnLast4: 'Please, submit last 4 digits of your SSN' })
    } else if (ssnLast4.length !== 4) {
      setError({ ssnLast4: 'Invalid last 4 digits of SSN' })
    } else if (businessUrl === '') {
      setError({ businessUrl: 'Please, submit business url' })
    } else if (!validateUrl(businessUrl)) {
      setError({ businessUrl: 'Please, submit business url' })
    } else if (phoneNumber === '') {
      setError({ phoneNumber: 'Invalid phone number' })
    } else if (typeof postalCode === 'undefined' || postalCode === '' || !await validatePostalCode(postalCode)) {
      setError({ postalCode: 'Invalid zip code' })
    } else if (typeof streetAddress === 'undefined' || streetAddress === '') {
      setError({ streetAddress: 'Invalid street address' })
    } else if (businessType === types.BusinessType.Company && fein === '') {
      setError({ fein: 'Invalid FEIN number' })
    } else {
      return true
    }
    return false
  }

  const handleValidate = () => formValidate()

  useEffect(() => {
    if (Object.keys(error).length > 0) {
      console.log('Validation error', error)
    }
  }, [error])

  const handleSubmit = () => ({
    'task-payment-data': data
  })

  console.log('payment task', props.businessSetup.tasks[2])

  const handleComplete = () => {
    const timerId = setInterval(() => {
      if (taskCompletedRef.current === 1) {
        clearInterval(timerId)
        props.onDone()
      }
    }, 500)
  }

  // handle top menu button click
  // const trySubmit = () => {
  //   console.log('Try submit EPAYMENT')
  //   if (validated) {
  //     formValidate().then(result => {
  //       if (result) {
  //         handleNext()
  //         // submit form
  //       }
  //     })
  //   }
  // }

  return (
    <div className="task task-payment">
      <Header task={props.task.name}/>

      <div className="content">
        <div
          className="cells">
          <div className="cell forms">
            <div className="business-type sub-form">
              <label>Choose your business type</label>
              <ul className="options">
                {Object.keys(types.BusinessType).map((bt: types.BusinessType, index: number) => <li key={index}>
                  <FormButton
                    className={classNames({ active: businessType === types.BusinessType[bt] })}
                    onClick={() => { handleBusinessTypeClick(types.BusinessType[bt]) }}
                    text={bt}
                  />
                </li>)}
              </ul>
            </div>
            <div className={classNames({
              settings: true,
              'sub-form': true,
              'grayed-out': !businessType
            })}>
              {(!businessType || businessType === types.BusinessType.Individual) &&
                <EPaymentIndividualInput
                  data={data}
                  onChange={handleFieldsChange}
                  {...(error ? { error } : {})}
                />
              }
              {businessType === types.BusinessType.Company &&
                <EPaymentCompanyInput
                  data={data}
                  onChange={handleFieldsChange}
                  {...(error ? { error } : {})}
                />
              }
            </div>
          </div>
          <div className="cell picture">
            <div className="picture-wrapper">
              <img className="illustration" src={epaymentImg} alt="E-Payment illustration"/>
            </div>
            <div className="text-content">
              <h4>E-Payment</h4>
              <p className="message">Information about e-payment. This information will appear in you business information to help your clients know about you.</p>
            </div>
          </div>
        </div>

        <div className="actions">
          <FormButton text="Cancel" className="btn-cancel" onClick={props.onDone}/>
          <FormButton
            text="Finish task"
            onValidate={handleValidate}
            beakidElement='task-payment-submit'
            onSubmit={handleSubmit}
            onComplete={handleComplete}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  businessSetup: state.businessSetup
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    ...businessSetupActions
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(TaskPayment)
