import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import * as types from 'Scenes/BusinessBilling/types'
import ContentSection from '../ContentSection'
import Subscription from '../Subscription'
import Dropdown, { MenuItem } from '@trendmicro/react-dropdown'
import './styles.scss'
import FormButton from 'Beakid/FormButton'

type CartProps = {
  onGoShopping: () => void
  onGoPaymentMethods: () => void
  onCheckout: (paymentMethodId: string) => void
} & types.PropsWithBusinessBillingState

function Cart (props: CartProps) {
  const [paymentMethodId, setPaymentMethodId] = useState<string>(props.businessBilling.defaultPaymentMethod)
  const [showCouponInput, setShowCouponInput] = useState<boolean>(false)
  const [coupon, setCoupon] = useState<string>()

  const allPlanOptions = useMemo(() => [
    ...props.businessBilling.businessPlans.map(plan => plan.subscriptionOptions[0]),
    ...props.businessBilling.saasAddons.map(plan => plan.subscriptionOptions[0]),
    ...props.businessBilling.marketingPlans.map(plan => plan.subscriptionOptions[0])
  ], [props.businessBilling.businessPlans, props.businessBilling.saasAddons, props.businessBilling.marketingPlans])

  const period = props.businessBilling.cart?.period

  const total = useMemo(() => {
    let result = 0

    if (period) {
      if (props.businessBilling.cart?.saasPlanMachineName) {
        const cost = allPlanOptions.find(option => option.planMachineName === props.businessBilling.cart?.saasPlanMachineName).cost
        result += +cost[period].value
      }

      if (props.businessBilling.cart?.saasAddonsMachineNames.length > 0) {
        props.businessBilling.cart?.saasAddonsMachineNames.forEach(addon => {
          const cost = allPlanOptions.find(option => option.planMachineName === addon).cost
          result += +cost[period].value
        })
      }

      if (props.businessBilling.cart?.marketingPlanMachineName) {
        const cost = allPlanOptions.find(option => option.planMachineName === props.businessBilling.cart?.marketingPlanMachineName).cost
        result += +cost[period].value
      }
    }

    return result
  }, [props.businessBilling.cart])

  const unit = useMemo(() => {
    const anyPlanName = props.businessBilling.cart?.saasPlanMachineName ?? props.businessBilling.cart?.marketingPlanMachineName
    const anyPlan = allPlanOptions.find(plan => plan.planMachineName === anyPlanName)
    return anyPlan?.cost[period]?.unit
  }, [allPlanOptions])

  useEffect(() => {
    if (props.businessBilling.cart?.coupon) {
      setShowCouponInput(false)
    }
  }, [props.businessBilling.cart?.coupon])

  const handleGoShopping = (e) => {
    e.preventDefault()
    props.onGoShopping()
  }

  const handleCheckout = (e) => {
    e.preventDefault()
    props.onCheckout(paymentMethodId)
  }

  const handleApplyCoupon = (e) => {
    e.preventDefault()
    setShowCouponInput(true)
  }

  const handleLoadCoupon = () => {}
  const handleCouponSubmit = () => ({ coupon })
  console.log('coupon', coupon)

  const handlePaymentMethodManage = e => {
    e.preventDefault()
    props.onGoPaymentMethods()
  }

  const totalPrint = useMemo(() => {
    // const currencyFractionDigits = new Intl.NumberFormat('en-US', {
    //   style: 'currency',
    //   currency: 'USD'
    // }).resolvedOptions().maximumFractionDigits

    return total.toLocaleString('en-US', {
      maximumFractionDigits: 2
    })
  }, [total])

  const paymentMethod = paymentMethodId
    ? props.businessBilling.paymentMethods.find(method => method.id === paymentMethodId)
    : undefined

  return (
    <div className="cart">
      <ContentSection title="Your cart">
        <div className="cart-content">
          <Subscription subscription={props.businessBilling.cart}/>
        </div>

        <div className="cart-checkout">

          <div className="title">Checkout</div>

          {props.businessBilling.paymentMethods.length > 0 && (
            <div className="payment-method">
              <div className="label">Payment method</div>
              <Dropdown>
                <Dropdown.Toggle btnStyle="flat">{paymentMethod?.title ?? 'Add Payment Method'}</Dropdown.Toggle>
                <Dropdown.Menu>
                  {props.businessBilling.paymentMethods.map((method, key) => (
                    <MenuItem onSelect={() => setPaymentMethodId(method.id)} key={key}>{method.title}</MenuItem>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <button onClick={handlePaymentMethodManage} className="btn-pm-manage">Add/Remove Card or Bank Account</button>
            </div>
          )}

          <div className="summary">
            <div className="summary-item">
              <span className="label">Cart total:</span>
              <span className="value money">
                {unit}
                {
                  totalPrint
                }
              </span>
            </div>
            <div className="summary-item">
              <span className="label">Billing interval:</span>
              <span className="value">{period?.charAt(0).toUpperCase() + period?.slice(1)}</span>
            </div>
          </div>

          <div className="coupon">
            {props.businessBilling.cart?.coupon && (
              <div className="coupon-applied">Coupon <span className="coupon-name">{coupon}</span> applied</div>
            )}
            {showCouponInput && (
              <div className="coupon-input">
                <input type="text" placeholder="Enter coupon code" onChange={e => setCoupon(e.target.value)}/>
                <FormButton
                  onClick={handleLoadCoupon}
                  className="btn-coupon-input"
                  text={'Apply'}
                  beakidElement="coupon-apply"
                  onSubmit={handleCouponSubmit}
                  // onComplete={handleSaveCardComplete}
                />
              </div>
            )}
            {!(props.businessBilling.cart?.coupon || showCouponInput) && (
              <button onClick={handleApplyCoupon} className="btn-coupon">Apply coupon</button>
            )}
          </div>

          <div className="actions">
            <button onClick={handleCheckout} className="btn-checkout">Checkout now</button>
            or
            <button onClick={handleGoShopping} className="btn-go-shopping">Continue shopping</button>
          </div>

          {/* <div className="proposal activation-proposal">
            <div className="title">Activation</div>
            <div className="message">Your subscription is not active yet.</div>
            <div className="action"><button onClick={handleActivate}>Activate now</button></div>
          </div> */}
        </div>
      </ContentSection>
    </div>
  )
}

const mapStateToProps = (state) => ({
  businessBilling: state.businessBilling
})

export default connect(mapStateToProps)(Cart)
