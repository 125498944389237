import * as types from './types'
import { createReducer } from 'typesafe-actions'
import * as commands from './constants'

const initialState: types.EventManagementState = {
  tab: types.EventManagementTab.Events,
  popup: null,
  events: [],
  types: []
}

export default createReducer(initialState)
  .handleType(
    commands.EVENTMANAGEMENT_ADOPT_STATE,
    (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    }
  )
  .handleType(
    commands.EVENTMANAGEMENT_GOTO,
    (state, action) => ({
      ...state,
      tab: action.payload
    })
  )
  .handleType(
    commands.EVENTMANAGEMENT_POPUP_EVENT_CREATE,
    (state, action) => ({
      ...state,
      popup: action.payload === false ? null : { name: 'eventCreate', data: action.payload }
    })
  )
  .handleType(
    commands.EVENTMANAGEMENT_POPUP_EVENT_EDIT,
    (state, action) => ({
      ...state,
      popup: action.payload.id === false ? null : { name: 'eventEdit', data: action.payload }
    })
  )
  .handleType(
    commands.EVENTMANAGEMENT_EVENT_DELETE,
    (state, action) => ({
      ...state,
      events: [
        ...state.events.slice(0, state.events.findIndex(event => event.id === action.payload)),
        ...state.events.slice(state.events.findIndex(event => event.id === action.payload) + 1)]
    })
  )
  .handleType(
    commands.EVENTMANAGEMENT_POPUP_EVENT_EDIT_TAB,
    (state, action) => ({
      ...state,
      popup: { ...state.popup, data: { ...state.popup.data, tab: action.payload } }
    })
  )
  .handleType(
    commands.EVENTMANAGEMENT_EVENT_SAVE,
    (state, action) => (
      'id' in action.payload
        ? {
          ...state,
          events: [
            ...state.events.slice(0, state.events.findIndex(event => event.id === action.payload.id)),
            action.payload,
            ...state.events.slice(state.events.findIndex(event => event.id === action.payload.id) + 1)]
        }
        : {
          ...state,
          events: [...state.events, action.payload]
        }
    )
  )
  .handleType(
    commands.EVENTMANAGEMENT_EVENT_POPULATE_TEAMS,
    (state, action) => {
      const eventIndex = state.events.findIndex(event => event.id === action.payload.id)
      console.log('eventIndex', eventIndex)
      return {
        ...state,
        events: [
          ...state.events.slice(0, eventIndex),
          { ...state.events[eventIndex], teams: action.payload.teams },
          ...state.events.slice(state.events.findIndex(event => event.id === action.payload.id) + 1)]
      }
    }
  )
  .handleType(
    commands.EVENTMANAGEMENT_EVENT_TIMELINE_POST,
    (state, action) => {
      const eventIndex = state.events.findIndex(event => event.id === action.payload.eventId)
      const event = state.events[eventIndex]
      const timeline = 'timeline' in event ? event.timeline : []

      return {
        ...state,
        events: [
          ...state.events.slice(0, eventIndex),
          { ...event, timeline: [{ ...action.payload.item, isNew: true }, ...timeline] },
          ...state.events.slice(eventIndex + 1)]
      }
    }
  )

  /**
   * Set 'id' of a temporary post
   */
  .handleType(
    commands.EVENTMANAGEMENT_EVENT_TIMELINE_POST_PERMANENT_ID,
    (state, action) => {
      // return state
      const eventIndex = state.events.findIndex(event => event.id === action.payload.eventId)
      const event = state.events[eventIndex]
      const timeline = 'timeline' in event ? event.timeline : []
      const itemIndex = timeline.findIndex(item => item.isNew)

      return {
        ...state,
        events: [
          ...state.events.slice(0, eventIndex),
          {
            ...event,
            timeline: [
              ...timeline.slice(0, itemIndex),
              {
                ...timeline[itemIndex],
                isNew: false,
                id: action.payload.postId
              },
              ...timeline.slice(itemIndex + 1)
            ]
          },
          ...state.events.slice(eventIndex + 1)]
      }
    }
  )

  .handleType(
    commands.EVENTMANAGEMENT_EVENT_TEAM_DELETE,
    (state, action) => {
      const eventIndex = state.events.findIndex(event => event.id === action.payload.eventId)
      const event = state.events[eventIndex]
      const teams = 'teams' in event ? event.teams : []
      const teamIndex = teams.findIndex(team => team.id === action.payload.teamId)

      return {
        ...state,
        events: [
          ...state.events.slice(0, eventIndex),
          {
            ...event,
            teams: [
              ...teams.slice(0, teamIndex),
              ...teams.slice(teamIndex + 1)
            ]
          },
          ...state.events.slice(eventIndex + 1)
        ]
      }
    }
  )
