import React, { useEffect, useState } from 'react'
import FormButton from 'Beakid/FormButton'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import IndividualInput from './components/IndividualInput'
import CompanyInput from './components/CompanyInput'
import * as stripeConnectActions from './actions'
import * as types from './types'
import classNames from 'classnames'
import epaymentImg from './images/epayment.svg'
import axios from 'axios'
import './styles.scss'

type StepAccountEPaymentProps = {
  state: { stripeConnect?: types.StripeConnectState }
} & types.PropsWithStripeConnectState & types.StripeConnectActions

function StripeConnect (props: StepAccountEPaymentProps) {
  useEffect(() => {
    if ('stripeConnect' in props.state) {
      console.log('Adopting state', props.state)
      props.stripeConnectAdoptState(props.state.stripeConnect)
    }
  }, [props.state])

  const { businessType } = props.stripeConnect

  const [validated, setValidated] = useState<boolean>(false)

  const [error, setError] = useState<types.StripeConnectError>({})

  const handleBusinessTypeClick = (businessType: types.BusinessType) => props.stripeConnectBusinessType(businessType)
  const handleFieldsChange = (fields: types.StripeConnectFields) => props.stripeConnectMergeFields(fields)

  const individualPreValidated = ():boolean => {
    if (typeof props.stripeConnect === 'undefined') {
      return false
    }

    const { ssnLast4, businessUrl, phoneNumber, streetAddress, postalCode, tosAccepted } = props.stripeConnect

    return ssnLast4 !== '' &&
      businessUrl !== '' &&
      phoneNumber !== '' &&
      streetAddress !== '' &&
      postalCode !== '' &&
      tosAccepted
  }

  const companyPreValidated = ():boolean => {
    if (typeof props.stripeConnect === 'undefined') {
      return false
    }

    const { fein, ssnLast4, businessUrl, phoneNumber, streetAddress, postalCode, tosAccepted } = props.stripeConnect

    return fein !== '' &&
      ssnLast4 !== '' &&
      businessUrl !== '' &&
      phoneNumber !== '' &&
      streetAddress !== '' &&
      postalCode !== '' &&
      tosAccepted
  }

  useEffect(() => {
    if (businessType === null) {
      setValidated(false)
    } else if (businessType === types.BusinessType.Individual && !individualPreValidated()) {
      setValidated(false)
    } else if (businessType === types.BusinessType.Company && !companyPreValidated()) {
      setValidated(false)
    } else {
      setValidated(true)
    }
  }, [props.stripeConnect])

  const validateUrl = (url: string): boolean => {
    console.log('validating url', url)
    return true
  }

  const validatePostalCode = async (postalCode: string):Promise<boolean> => {
    return axios.get('/grovvup/setup/zip/validate/' + postalCode).then(res => {
      if (res.status !== 200) {
        console.warn('Postal code validating failed')
        return false
      }
      console.log('Postal code validated OK')
      return true
    }).catch(error => {
      console.error('Error in promise', error)
      return false
    })
  }

  const formValidate = async (): Promise<boolean> => {
    const { fein, ssnLast4, businessUrl, phoneNumber, streetAddress, postalCode } = props.stripeConnect

    if (ssnLast4 === '') {
      setError({ ssnLast4: 'Please, submit last 4 digits of your SSN' })
    } else if (ssnLast4.length !== 4) {
      setError({ ssnLast4: 'Invalid last 4 digits of SSN' })
    } else if (businessUrl === '') {
      setError({ businessUrl: 'Please, submit business url' })
    } else if (!validateUrl(businessUrl)) {
      setError({ businessUrl: 'Please, submit business url' })
    } else if (phoneNumber === '') {
      setError({ phoneNumber: 'Invalid phone number' })
    } else if (typeof postalCode === 'undefined' || postalCode === '' || !await validatePostalCode(postalCode)) {
      setError({ postalCode: 'Invalid zip code' })
    } else if (typeof streetAddress === 'undefined' || streetAddress === '') {
      setError({ streetAddress: 'Invalid street address' })
    } else if (businessType === types.BusinessType.Company && fein === '') {
      setError({ fein: 'Invalid FEIN number' })
    } else {
      return true
    }
    return false
  }

  useEffect(() => {
    if (error !== {}) {
      console.log('Validation error', error)
    }
  }, [error])

  return (
    <div className="stripe-connect">
      <div className="content">
        <div
          className="cells">
          <div className="cell forms">
            <div className="business-type sub-form">
              <label>Choose your business type</label>
              <ul className="options">
                {Object.keys(types.BusinessType).map((bt: types.BusinessType, index: number) => <li key={index}>
                  <FormButton
                    className={classNames({ active: businessType === types.BusinessType[bt] })}
                    onClick={() => { handleBusinessTypeClick(types.BusinessType[bt]) }}
                    text={bt}
                  />
                </li>)}
              </ul>
            </div>
            <div className={classNames({
              settings: true,
              'sub-form': true,
              'grayed-out': businessType === null
            })}>
              {(businessType === types.BusinessType.Individual) &&
                <IndividualInput
                  onChange={handleFieldsChange}
                  {...(error ? { error } : {})}
                />
              }
              {businessType === types.BusinessType.Company &&
                <CompanyInput
                  onChange={handleFieldsChange}
                  {...(error ? { error } : {})}
                />
              }
            </div>
            <div className="actions">
              <FormButton
                text="Save"
                className="btn-save"
                // onValidate={formValidate}
                beakidElement='save'
                // disabled={!validated}
              />
            </div>
          </div>
          <div className="cell picture">
            <div className="picture-wrapper">
              <img className="illustration" src={epaymentImg} alt="E-Payment illustration"/>
            </div>
            <div className="text-content">
              <h4>E-Payment</h4>
              <p className="message">Information about e-payment. This information will appear in you business information to help your clients know about you.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  stripeConnect: state.stripeConnect
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    ...stripeConnectActions
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(StripeConnect)
