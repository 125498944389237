import React, { useMemo } from 'react'
import moment from 'moment'
import * as types from 'Scenes/BusinessSetup/types'
import { Cost } from '../Cost'
import './styles.scss'

type BusinessSetupProps = {
  option: types.SubscriptionOption
  proposeActivation: boolean
  upcomingPayment?: types.UpcomingPayment
  trialing: boolean
  onChange: () => void
  onActivate: () => void
  onDeactivate: () => void
}

function CurrentPlan (props: BusinessSetupProps) {
  const handleBtnChange = event => {
    event.preventDefault()
    event.stopPropagation()
    props.onChange()
  }

  const handleActivate = event => {
    event.preventDefault()
    event.stopPropagation()
    props.onActivate()
  }

  const handleDeactivate = event => {
    event.preventDefault()
    event.stopPropagation()
    props.onDeactivate()
  }

  console.log('props.option', props.option)

  const helpMessage = useMemo(() => ({
    transaction: <>
      A transaction plan will charge a fee on every credit card transaction.
      You can upgrade or downgrade the plan at any time.
      If you choose to cancel you plan, please email <a href="mailto:support@beakid.com" target="_blank" rel="noreferrer">support@beakid.com</a> with you company name.
    </>,
    subscription: <>
      A subscription plan will charge you on the monthly basis.
      You can upgrade or downgrade the plan at any time.
      If you choose to cancel you plan, please email <a href="mailto:support@beakid.com" target="_blank" rel="noreferrer">support@beakid.com</a> with you company name.
    </>
  }[props.option.machineName]
  ), [props.option])

  return (
    <div className="current-plan2">
      <div className="details">
        <div className="first-line">
          <div className="title">{props.option.planTitle}</div>
          <div className="cost-wrapper">
            <div className="cost">
              <span className="value"><Cost { ...props.option.cost }/></span>
              <span className="subject">/ {props.option.cost.subject}</span>
            </div>
            <a className="fees" href="https://stripe.com/pricing" target="_blank" rel="noreferrer">+ Credit card proccesing fees</a>
          </div>
        </div>
        <div className="description">
          {helpMessage}
        </div>
        {props.proposeActivation &&
          <button className="btn-change" onClick={handleBtnChange}>Change Plan</button>
        }
      </div>
      <div className="status">
        {props.proposeActivation &&
          <div className="proposal activation-proposal">
            <div className="title">Activation</div>
            <div className="message">Your subscription is not active yet.</div>
            <div className="action"><button onClick={handleActivate}>Activate now</button></div>
          </div>
        }
        {props.upcomingPayment &&
          <div className="proposal upcoming-payment">
            <div className="title">
              {props.trialing
                ? 'Trial period activated'
                : 'Upcoming Payment'
              }
            </div>
            <div className="message">Your next bill is for</div>
            <div className="subject">
              <span className="value">
                <span className="currency">{props.upcomingPayment.currency}</span>
                <span className="money">{props.upcomingPayment.amount.toFixed(2)}</span>
              </span>
              on
              <span className="when">{moment.unix(props.upcomingPayment.when).format('DD MMM, YYYY')}</span>
            </div>
          </div>
        }
        {!props.proposeActivation && (
          <button className="btn-cancel-subscription" onClick={handleDeactivate}>Cancel Subscription</button>
        )}
      </div>
    </div>
  )
}

export default CurrentPlan
