import React, { useEffect, useState, useRef } from 'react'
import FormButton from 'Beakid/FormButton'
import { QuestionOption } from 'Scenes/FormEditor/types'

type QuestionProps = {
  options: QuestionOption[],
  onChange: (options: QuestionOption[]) => void
}

function OptionsInput (props: QuestionProps) {
  const [addOption, setAddOption] = useState(false)
  const [optionValue, setOptionValue] = useState('')
  const textfieldRef = useRef<HTMLInputElement>()

  const handleAddOption = () => {
    setOptionValue('')
    // setAddOption(false)
    props.onChange([...props.options, { name: optionValue, value: optionValue }])
    textfieldRef.current?.focus()
  }
  const handleOptionDelete = value => props.onChange(props.options.filter(option => option.value !== value))

  const handleKeyUp = event => {
    if (event.which === 13) {
      handleAddOption()
    }
  }

  const handleBlur = event => {
    if (!event.relatedTarget?.matches('button.btn-option-add')) {
      setAddOption(false)
    }
  }

  useEffect(() => {
    if (addOption) {
      textfieldRef.current?.focus()
    }
  }, [addOption])

  return (
    <ol className="options">
      {props.options.map((option, index) => (<li key={index}>
        <label>{option.name}</label>
        <FormButton text="" onClick={() => handleOptionDelete(option.value)} className="btn-delete" />
      </li>))}
      <li>
        {addOption && (
          <div className="option-input">
            <input
              type="text"
              onChange={event => setOptionValue(event.target.value)}
              value={optionValue}
              onKeyUp={handleKeyUp}
              placeholder="Type option and hit Enter"
              onBlur={handleBlur}
              ref={textfieldRef}
            />
          </div>
        )}
        <FormButton
          text="+ Add Option"
          onClick={() => { addOption ? handleAddOption() : setAddOption(true) }}
          className="btn-add btn-option-add"
          disabled={addOption && optionValue === ''}
        />
      </li>
    </ol>
  )
}

export default OptionsInput
