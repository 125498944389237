import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import InvoiceList from '../InvoiceList'
import PaymentMethodList from '../PaymentMethodList'
// import Goods from '../Goods'
import Showcase from '../Showcase'
import 'react-tabs/style/react-tabs.css'
import './styles.scss'

function Dashboard () {
  return (
    <div className="dashboard">
      <Tabs>
        <TabList>
          <Tab>Current subscription</Tab>
          <Tab>Payment methods</Tab>
          <Tab>Invoice history</Tab>
        </TabList>

        <TabPanel>
          <Showcase/>
        </TabPanel>
        <TabPanel>
          <PaymentMethodList/>
        </TabPanel>
        <TabPanel>
          <InvoiceList/>
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default Dashboard
