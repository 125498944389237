import * as types from './types'
import { createReducer } from 'typesafe-actions'
import * as commands from './constants'

const initialState: types.BusinessSetupState = {
  tasks: [],
  task: null
  // stage: types.BusinessSetupStage.Business,
  // step: types.BusinessSteps.about,
  // about: null,
  // locations: [],
  // locationFormState: types.LocationFormState.Create,
  // editingLocationIndex: null,
  // staffMembers: [],
  // staffFormState: types.StaffFormState.Create,
  // editingStaffMemberIndex: null,
  // tuitions: [],
  // tuitionFormState: types.TuitionFormState.Create,
  // editingTuitionIndex: null,
  // classes: [],
  // classFormState: types.ClassFormState.Create,
  // editingClassIndex: null,
  // epayment: { businessType: null },
  // account: null
}

export default createReducer(initialState)
  .handleType(
    commands.BUSINESSSETUP_ADOPT_STATE,
    (state, action) => {
      console.log(commands.BUSINESSSETUP_ADOPT_STATE, action)
      return {
        ...state,
        ...action.payload
      }
    }
  )
  .handleType(
    commands.BUSINESSSETUP_SAVE_TASK,
    (state, action) => {
      return {
        ...state,
        tasks: [
          ...state.tasks.slice(0, action.payload.order - 1),
          action.payload,
          ...state.tasks.slice(action.payload.order)
        ]
      }
    }
  )
  .handleType(
    commands.BUSINESSSETUP_SAVE_TASK_DATA,
    (state: types.BusinessSetupState, action): types.BusinessSetupState => {
      console.log('BUSINESSSETUP_SAVE_TASK_DATA', action.payload)
      return {
        ...state,
        tasks: [
          ...state.tasks.slice(0, action.payload.taskIndex),
          {
            ...state.tasks[action.payload.taskIndex],
            data: action.payload.data
          },
          ...state.tasks.slice(action.payload.taskIndex + 1)
        ]
      }
    }
  )
  .handleType(
    commands.BUSINESSSETUP_SAVE_TASK_COMPLETE,
    (state: types.BusinessSetupState, action): types.BusinessSetupState => {
      console.log('BUSINESSSETUP_SAVE_TASK_COMPLETE', action.payload)
      return {
        ...state,
        tasks: [
          ...state.tasks.slice(0, action.payload.taskIndex),
          {
            ...state.tasks[action.payload.taskIndex],
            completed: action.payload.completed
          },
          ...state.tasks.slice(action.payload.taskIndex + 1)
        ]
      }
    }
  )
  .handleType(
    commands.BUSINESSSETUP_PLAN_SELECT,
    (state: types.BusinessSetupState, action): types.BusinessSetupState => ({
      ...state,
      tasks: [
        ...state.tasks.slice(0, 3),
        {
          ...state.tasks[3],
          data: {
            ...state.tasks[3].data,
            activeBusinessPlan: {
              machineName: action.payload,
              subscribed: 0,
              status: 'active', // check this
              payeeBusinessNid: 0 // check this
            }
          }
        },
        ...state.tasks.slice(4, 5)]
    })
  )
  .handleType(
    commands.BUSINESSSETUP_PLAN_SET,
    (state: types.BusinessSetupState, action): types.BusinessSetupState => ({
      ...state,
      tasks: [
        ...state.tasks.slice(0, 3),
        {
          ...state.tasks[3],
          data: {
            ...state.tasks[3].data,
            activeBusinessPlan: action.payload
          },
          completed: 1.0
        },
        ...state.tasks.slice(4, 5)]
    })
  )
  .handleType(
    commands.BUSINESSSETUP_PAYMENT_METHODS_SET,
    (state: types.BusinessSetupState, action): types.BusinessSetupState => ({
      ...state,
      tasks: [
        ...state.tasks.slice(0, 3),
        {
          ...state.tasks[3],
          data: {
            ...state.tasks[3].data,
            paymentMethods: action.payload
          }
        },
        ...state.tasks.slice(4, 5)]
    })
  )
  .handleType(
    commands.BUSINESSSETUP_DEFAULT_PAYMENT_METHOD_SET,
    (state: types.BusinessSetupState, action): types.BusinessSetupState => ({
      ...state,
      tasks: [
        ...state.tasks.slice(0, 3),
        {
          ...state.tasks[3],
          data: {
            ...state.tasks[3].data,
            defaultPaymentMethod: action.payload
          }
        },
        ...state.tasks.slice(4, 5)]
    })
  )
  .handleType(
    commands.BUSINESSSETUP_BANK_ACCOUNT_SETUP_INTENT_SET,
    (state: types.BusinessSetupState, action): types.BusinessSetupState => ({
      ...state,
      tasks: [
        ...state.tasks.slice(0, 3),
        {
          ...state.tasks[3],
          data: {
            ...state.tasks[3].data,
            setupIntent: action.payload
          }
        },
        ...state.tasks.slice(4, 5)]
    })
  )
  .handleType(
    commands.BUSINESSSETUP_BANK_ACCOUNT_SETUP_INTENT_RESET,
    (state: types.BusinessSetupState): types.BusinessSetupState => ({
      ...state,
      tasks: [
        ...state.tasks.slice(0, 3),
        {
          ...state.tasks[3],
          data: {
            ...state.tasks[3].data,
            setupIntent: null
          }
        },
        ...state.tasks.slice(4, 5)]
    })
  )
  .handleType(
    commands.BUSINESSSETUP_BILLING_PERIOD_SET,
    (state: types.BusinessSetupState, action): types.BusinessSetupState => ({
      ...state,
      tasks: [
        ...state.tasks.slice(0, 3),
        {
          ...state.tasks[3],
          data: {
            ...state.tasks[3].data,
            period: action.payload
          }
        },
        ...state.tasks.slice(4, 5)]
    })
  )
//   .handleType(
//     commands.BUSINESSSETUP_GOTO,
//     (state, action) => ({
//       ...state,
//       ...action.payload
//     })
//   )
//   .handleType(
//     commands.BUSINESSSETUP_ABOUT_SUBMIT,
//     (state, action) => {
//       console.log(commands.BUSINESSSETUP_ABOUT_SUBMIT, action)

//       return {
//         ...state,
//         about: action.payload,
//         locations: Object.assign([], state.locations, {
//           0: {
//             ...(state.locations.length > 0 ? state.locations[0] : {}),
//             name: 'Main office',
//             isMain: true,
//             streetAddress: action.payload.streetAddress,
//             postalCode: action.payload.postalCode,
//             phoneNumber: action.payload.phone
//           }
//         }),
//         stage: types.BusinessSetupStage.Finish,
//         step: types.FinishSteps.finish
//       }
//     }
//   )

// // Locations

//   .handleType(
//     commands.BUSINESSSETUP_LOCATIONFORM_CREATE,
//     state => ({
//       ...state,
//       locationFormState: types.LocationFormState.Create,
//       editingLocationIndex: null
//     })
//   )
//   .handleType(
//     commands.BUSINESSSETUP_LOCATIONFORM_EDIT,
//     (state, action) => ({
//       ...state,
//       locationFormState: types.LocationFormState.Edit,
//       editingLocationIndex: action.payload
//     })
//   )
//   .handleType(
//     commands.BUSINESSSETUP_LOCATIONFORM_LIST,
//     state => ({
//       ...state,
//       locationFormState: types.LocationFormState.List,
//       editingLocationIndex: null
//     })
//   )
//   .handleType(
//     commands.BUSINESSSETUP_LOCATION_ADD,
//     (state, action) => ({
//       ...state,
//       locations: [...state.locations, action.payload],
//       locationFormState: types.LocationFormState.List,
//       editingLocationIndex: null
//     })
//   )
//   .handleType(
//     commands.BUSINESSSETUP_LOCATION_UPDATE,
//     (state, action) => ({
//       ...state,
//       locations: Object.assign([], state.locations, { [action.payload.locationIndex]: action.payload.location }),
//       locationFormState: types.LocationFormState.List,
//       editingLocationIndex: null
//     })
//   )
//   .handleType(
//     commands.BUSINESSSETUP_LOCATION_MAKE_MAIN,
//     (state, action) => ({
//       ...state,
//       locations: Object.assign(
//         [],
//         state.locations.map(loc => ({ ...loc, isMain: false })), // set isMain to false for all locations...
//         {
//           [action.payload]: {
//             ...state.locations[action.payload],
//             isMain: true // ... and to true for location referenced by action payload
//           }
//         }
//       ),
//       locationFormState: types.LocationFormState.List,
//       editingLocationIndex: null
//     })
//   )
//   .handleType(
//     commands.BUSINESSSETUP_LOCATION_DELETE,
//     (state, action) => ({
//       ...state,
//       locations: [...state.locations.slice(0, action.payload), ...state.locations.slice(action.payload + 1)],
//       locationFormState: state.locations.length > 1 ? types.LocationFormState.List : types.LocationFormState.Create,
//       editingLocationIndex: null
//     })
//   )

// // Staff

//   .handleType(
//     commands.STAFF_ADD,
//     (state, action) => ({
//       ...state,
//       staffMembers: [...state.staffMembers, action.payload],
//       staffFormState: types.StaffFormState.List,
//       editingStaffIndex: null
//     })
//   )
//   .handleType(
//     commands.STAFF_UPDATE,
//     (state, action) => ({
//       ...state,
//       staffMembers: Object.assign([], state.staffMembers, { [action.payload.memberIndex]: action.payload.member }),
//       staffFormState: types.StaffFormState.List,
//       editingStaffIndex: null
//     })
//   )
//   .handleType(
//     commands.STAFF_DELETE,
//     (state, action) => ({
//       ...state,
//       staffMembers: [...state.staffMembers.slice(0, action.payload), ...state.staffMembers.slice(action.payload + 1)],
//       staffFormState: state.staffMembers.length > 1 ? types.StaffFormState.List : types.StaffFormState.Create,
//       editingStaffIndex: null
//     })
//   )
//   .handleType(
//     commands.STAFFFORM_INRODUCE_OWNER,
//     state => ({
//       ...state,
//       staffFormState: types.StaffFormState.IntroduceOwner,
//       editingStaffMemberIndex: null
//     })
//   )
//   .handleType(
//     commands.STAFFFORM_LIST,
//     state => ({
//       ...state,
//       staffFormState: types.StaffFormState.List,
//       editingStaffMemberIndex: null
//     })
//   )
//   .handleType(
//     commands.STAFFFORM_EDIT,
//     (state, action) => ({
//       ...state,
//       staffFormState: types.StaffFormState.Edit,
//       editingStaffMemberIndex: action.payload
//     })
//   )
//   .handleType(
//     commands.STAFFFORM_CREATE,
//     state => ({
//       ...state,
//       staffFormState: types.StaffFormState.Create,
//       editingStaffMemberIndex: null
//     })
//   )

// // tuitions

//   .handleType(
//     commands.BUSINESSSETUP_TUITION_ADD,
//     (state, action) => ({
//       ...state,
//       tuitions: [...state.tuitions, action.payload],
//       tuitionFormState: types.TuitionFormState.List,
//       editingTuitionIndex: null
//     })
//   )
//   .handleType(
//     commands.BUSINESSSETUP_TUITION_UPDATE,
//     (state, action) => ({
//       ...state,
//       tuitions: Object.assign([], state.tuitions, { [action.payload.tuitionIndex]: action.payload.tuition }),
//       tuitionFormState: types.TuitionFormState.List,
//       editingTuitionIndex: null
//     })
//   )
//   .handleType(
//     commands.BUSINESSSETUP_TUITION_DELETE,
//     (state, action) => ({
//       ...state,
//       tuitions: [...state.tuitions.slice(0, action.payload), ...state.tuitions.slice(action.payload + 1)],
//       tuitionFormState: state.tuitions.length > 1 ? types.TuitionFormState.List : types.TuitionFormState.Create,
//       editingTuitionIndex: null
//     })
//   )
//   .handleType(
//     commands.BUSINESSSETUP_TUITIONFORM_LIST,
//     state => ({
//       ...state,
//       tuitionFormState: types.TuitionFormState.List,
//       editingTuitionsIndex: null
//     })
//   )
//   .handleType(
//     commands.BUSINESSSETUP_TUITIONFORM_EDIT,
//     (state, action) => ({
//       ...state,
//       tuitionFormState: types.TuitionFormState.Edit,
//       editingTuitionIndex: action.payload
//     })
//   )
//   .handleType(
//     commands.BUSINESSSETUP_TUITIONFORM_CREATE,
//     state => ({
//       ...state,
//       tuitionFormState: types.TuitionFormState.Create,
//       editingTuitionIndex: null
//     })
//   )

// // classes

//   .handleType(
//     commands.BUSINESSSETUP_CLASS_ADD,
//     (state, action) => ({
//       ...state,
//       classes: [...state.classes, action.payload],
//       classFormState: types.ClassFormState.List,
//       editingClassIndex: null
//     })
//   )
//   .handleType(
//     commands.BUSINESSSETUP_CLASS_UPDATE,
//     (state, action) => ({
//       ...state,
//       classes: Object.assign([], state.classes, { [action.payload.classIndex]: action.payload.cls }),
//       classFormState: types.ClassFormState.List,
//       editingClassIndex: null
//     })
//   )
//   .handleType(
//     commands.BUSINESSSETUP_CLASS_DELETE,
//     (state, action) => ({
//       ...state,
//       classes: [...state.classes.slice(0, action.payload), ...state.classes.slice(action.payload + 1)],
//       classFormState: state.classes.length > 1 ? types.ClassFormState.List : types.ClassFormState.Create,
//       editingClassIndex: null
//     })
//   )
//   .handleType(
//     commands.BUSINESSSETUP_CLASSFORM_LIST,
//     state => ({
//       ...state,
//       classFormState: types.ClassFormState.List,
//       editingClasssIndex: null
//     })
//   )
//   .handleType(
//     commands.BUSINESSSETUP_CLASSFORM_EDIT,
//     (state, action) => ({
//       ...state,
//       classFormState: types.ClassFormState.Edit,
//       editingClassIndex: action.payload
//     })
//   )
//   .handleType(
//     commands.BUSINESSSETUP_CLASSFORM_CREATE,
//     state => ({
//       ...state,
//       classFormState: types.ClassFormState.Create,
//       editingClassIndex: null
//     })
//   )

// // E-Payment

//   .handleType(
//     commands.BUSINESSSETUP_EPAYMENT_BUSINESSTYPE,
//     (state, action) => ({
//       ...state,
//       epayment: { ...state.epayment, businessType: action.payload }
//     })
//   )
//   .handleType(
//     commands.BUSINESSSETUP_EPAYMENT_SETTINGS,
//     (state, action) => ({
//       ...state,
//       epayment: { ...state.epayment, settings: action.payload }
//     })
//   )

// sessions

// .handleType(
//   commands.BUSINESSSETUP_SESSION_ADD,
//   (state, action) => ({
//     ...state,
//     sessions: [...state.sessions, action.payload],
//     sessionFormState: types.SessionFormState.List,
//     editingSessionIndex: null
//   })
// )
// .handleType(
//   commands.BUSINESSSETUP_SESSION_UPDATE,
//   (state, action) => ({
//     ...state,
//     sessions: Object.assign([], state.sessions, { [action.payload.sessionIndex]: action.payload.session }),
//     sessionFormState: types.SessionFormState.List,
//     editingSessionIndex: null
//   })
// )
// .handleType(
//   commands.BUSINESSSETUP_SESSION_DELETE,
//   (state, action) => ({
//     ...state,
//     sessions: [...state.sessions.slice(0, action.payload), ...state.sessions.slice(action.payload + 1)],
//     sessionFormState: state.sessions.length > 1 ? types.SessionFormState.List : types.SessionFormState.Create,
//     editingSessionIndex: null
//   })
// )
// .handleType(
//   commands.BUSINESSSETUP_SESSIONFORM_LIST,
//   state => ({
//     ...state,
//     sessionFormState: types.SessionFormState.List,
//     editingSessionIndex: null
//   })
// )
// .handleType(
//   commands.BUSINESSSETUP_SESSIONFORM_EDIT,
//   (state, action) => ({
//     ...state,
//     sessionFormState: types.SessionFormState.Edit,
//     editingSessionIndex: action.payload
//   })
// )
// .handleType(
//   commands.BUSINESSSETUP_SESSIONFORM_CREATE,
//   state => ({
//     ...state,
//     sessionFormState: types.SessionFormState.Create,
//     editingSessionIndex: null
//   })
// )
