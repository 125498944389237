import React, { useState, useEffect, useRef, useMemo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as businessSetupActions from 'Scenes/BusinessSetup/actions'
import * as types from 'Scenes/BusinessSetup/types'
import PhoneInput from 'react-phone-input-2'
import ReactTooltip from 'react-tooltip'
import { StepAccountEPaymentError } from '../..'
import 'react-phone-input-2/lib/style.css'
import './styles.scss'

export type EPaymentIndividualFields = {
  ssnLast4: string,
  businessUrl: string,
  phoneNumber: string,
  streetAddress: string,
  postalCode: string
}

type EPaymentIndividualInputProps = {
  businessSetup: types.BusinessSetupState
  data: types.DataPayment
  onChange: (fields: EPaymentIndividualFields) => void
  error?: StepAccountEPaymentError
}

function EPaymentIndividualInput (props: EPaymentIndividualInputProps) {
  const ssnLast4Ref = useRef()
  const businessUrlRef = useRef()
  const phoneNumberRef = useRef()
  const streetAddressRef = useRef()
  const postalCodeRef = useRef()

  const basicData = useMemo(() => props.businessSetup.tasks.find(task => task.name === 'basic').data as types.DataBasic, [])

  const [updated, setUpdated] = useState<boolean>(false)
  const [error, setError] = useState(props.error)
  const [ssnLast4, setLastSsn4] = useState<string>(props.data.ssnLast4 || '')
  const [businessUrl, setBusinessUrl] = useState<string>(props.data.businessUrl || basicData.website)
  const [phoneNumber, setPhoneNumber] = useState<string>(props.data.phoneNumber || basicData.phone)
  const [streetAddress, setStreetAddress] = useState<string>(props.data.streetAddress || basicData.streetAddress)
  const [postalCode, setPostalCode] = useState<string>(props.data.postalCode || basicData.postalCode)
  const [tosAccepted, setTosAccepted] = useState<boolean>(props.data.tosAccepted ?? false)

  const fields = { ssnLast4, businessUrl, phoneNumber, streetAddress, postalCode, tosAccepted }

  // change field via setter callback, then set 'updated' flag used below
  const updateField = (setter: any, value: any) => {
    setter(value)
    setUpdated(true)
  }

  // once fields gets updated, call parent' onChange event once
  useEffect(() => {
    if (updated) {
      props.onChange(fields)
      setUpdated(false)
    }
  }, [fields, updated])

  // emit error tooltip
  useEffect(() => {
    if ('ssnLast4' in error) {
      ReactTooltip.show(ssnLast4Ref.current)
    }
    if ('businessUrl' in error) {
      ReactTooltip.show(businessUrlRef.current)
    }
    if ('streetAddress' in error) {
      ReactTooltip.show(streetAddressRef.current)
    }
    if ('phoneNumber' in error) {
      ReactTooltip.show(phoneNumberRef.current)
    }
    if ('postalCode' in error) {
      ReactTooltip.show(postalCodeRef.current)
    }
  }, [error])

  // adopt error status from props
  useEffect(() => {
    setError(props.error)
  }, [props.error])

  // reset error (typically when input gets focus)
  const resetError = (event) => {
    ReactTooltip.hide(event.target)
    setError({})
  }

  console.log('basicData.country', basicData.country)

  return (
    <div className="epayment-settings settings-individual">
      <div className="line line-ssnlast4">
        <input
          type="text"
          onChange={event => updateField(setLastSsn4, event.target.value)}
          value={ssnLast4}
          placeholder="SSN (last 4 digits) *"
          {...('ssnLast4' in error ? ({
            'data-tip': error.ssnLast4,
            'data-type': 'error',
            'data-effect': 'solid',
            ref: ssnLast4Ref,
            onFocus: resetError
          }) : {})}
        />
      </div>
      <div className="line line-business-url">
        <input
          type="text"
          onChange={event => updateField(setBusinessUrl, event.target.value)}
          value={businessUrl}
          placeholder="Business URL *"
          {...('businessUrl' in error ? ({
            'data-tip': error.businessUrl,
            'data-type': 'error',
            'data-effect': 'solid',
            ref: businessUrlRef,
            onFocus: resetError
          }) : {})}
        />
      </div>
      <div
        className="line line-phone-number"
        {...('phoneNumber' in error ? ({
          'data-tip': error.phoneNumber,
          'data-type': 'error',
          'data-effect': 'solid',
          ref: phoneNumberRef,
          onFocus: resetError
        }) : {})}
      >
        <PhoneInput
          placeholder="Business Phone Number *"
          value={phoneNumber}
          onChange={value => updateField(setPhoneNumber, value)}
          country={basicData.country.toLowerCase()}
          onlyCountries={Object.values(types.Country).map(country => country.toLowerCase())}
          countryCodeEditable={false}
          {...('phoneNumber' in error ? ({
            onFocus: resetError
          }) : {})}
        />
      </div>
      <div className="line line-address">
        <input
          type="text"
          onChange={event => updateField(setStreetAddress, event.target.value)}
          value={streetAddress}
          placeholder="Street Address *"
          {...('streetAddress' in error ? ({
            'data-tip': error.streetAddress,
            'data-type': 'error',
            'data-effect': 'solid',
            ref: streetAddressRef,
            onFocus: resetError
          }) : {})}
        />
        <input
          type="text"
          onChange={event => updateField(setPostalCode, event.target.value)}
          value={postalCode}
          placeholder="Zip Code *"
          {...('postalCode' in error ? ({
            'data-tip': error.postalCode,
            'data-type': 'error',
            'data-effect': 'solid',
            ref: postalCodeRef,
            onFocus: resetError
          }) : {})}
        />
      </div>
      <div className="line line-tos">
        <div className="form-type-checkbox">
          <input type="checkbox" checked={tosAccepted} onChange={event => updateField(setTosAccepted, event.target.checked)}/>
        </div>
        <div className="text-content">
          <label>For accepting payments, you must agree to</label>
          <a href="#https://stripe.com/connect-account/legal#connected-account-agreement" target="_blank">Stripe Connected Account Agreement</a>
          *
        </div>
        {/* {'tosAccepted' in props.businessSetup.epayment ? (
          true === props.businessSetup.epayment.tosAccepted ? (
          ) : (
          )
        ) : (
        )} */}
      </div>
      {Object.keys(props.error).length > 0 && <ReactTooltip />}
    </div>
  )
}

const mapStateToProps = (state) => ({
  businessSetup: state.businessSetup
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    ...businessSetupActions
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(EPaymentIndividualInput)
