import React from 'react'
import QuestionInput, { QuestionInputProps } from './QuestionInput'

type QuestionProps = QuestionInputProps

function TextQuestionInput (props: QuestionProps) {
  const handleHelpTextChange = event => {
    if ('onChange' in props) {
      props.onChange({ ...props.entity, helpText: event.target.value })
    }
  }

  return (
    <QuestionInput { ...props }>
      <div className="line line-help-text">
        <input type="text" onChange={handleHelpTextChange} placeholder="Help text" defaultValue={props.entity.helpText} />
      </div>
    </QuestionInput>
  )
}

export default TextQuestionInput
