import React from 'react'
import './styles.scss'

type PostFormProps = {
  message: string
  children?: React.ReactNode
}

export function EmptyState (props: PostFormProps) {
  return (
    <div className="empty-state">
      <div className="content">
        <div className="message">{props.message}</div>
        { props.children }
      </div>
    </div>
  )
}
