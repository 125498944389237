import FormButton from 'Beakid/FormButton'
import React, { useCallback, useEffect, useState } from 'react'
import Switch from './components/Switch'
import { useDropzone } from 'react-dropzone'
import './styles.scss'
import { confirmAlert } from 'react-confirm-alert'
import axios from 'axios'
import classNames from 'classnames'

export function PostForm () {
  const [type, setType] = useState<string>('message')
  const [audience, setAudience] = useState<string>('active')
  const [message, setMessage] = useState<string>('')
  const [emailChecked, setEmailChecked] = useState<boolean>(false)
  const [smsChecked, setSMSChecked] = useState<boolean>(false)
  const [indicatePostInProgress, setIndicatePostInProgress] = useState<boolean>(false)
  const [indicatePostComplete, setIndicatePostComplete] = useState<boolean>(false)
  const [uploading, setUploading] = useState<boolean>(false)
  const [sourceFiles, setSourceFiles] = useState([])
  const [files, setFiles] = useState([])
  const [showUploadArea, setShowUploadArea] = useState<boolean>(false)

  const handlePostSubmit = () => {
    setIndicatePostInProgress(true)
    return {
      post: {
        type,
        audience,
        emailChecked,
        smsChecked,
        message,
        files: files.map(file => file.fid)
      }
    }
  }

  const handlePostComplete = () => {
    setIndicatePostComplete(true)
    setIndicatePostInProgress(false)
    setShowUploadArea(false)
    setSourceFiles([])
    setFiles([])
    setTimeout(() => {
      setIndicatePostComplete(false)
      setMessage('')
    }, 1000)
  }

  const handleToggleUploadArea = event => {
    setShowUploadArea(!showUploadArea)
    event.stopPropagation()
    event.preventDefault()
  }

  const onDrop = useCallback(acceptedFiles => {
    const rejected = acceptedFiles.filter(file => (file.size > 10 * 1024 * 1024))

    if (rejected.length > 0) {
      confirmAlert({
        title: 'File rejected',
        message: 'File must be of image type and must not exceed 10MB of size',
        buttons: [
          {
            label: 'Ok',
            onClick: () => {}
          }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true
      })
      return
    }

    setSourceFiles([...files, ...acceptedFiles.map(file => ({
      ref: file,
      fid: null,
      url: null
    }))])
  }, [])

  useEffect(() => {
    if (!uploading && files.filter(file => file.fid === null).length > 0) {
      setUploading(true)

      const uploadFiles = async () => {
        const promises = await Promise.all(files.map((file) => (new Promise((resolve, reject) => {
          if (file.fid === null) {
            const data = new FormData()
            data.append('file', file.ref)

            console.log(data)

            return axios.post('/grovvup/post/upload-file', data, {})
              .then(res => {
                if (res.status !== 200) {
                  console.warn('Upload failed')
                  reject(new Error('Upload failed'))
                } else {
                  resolve({ ...file, fid: res.data.fid })
                }
              })
          } else {
            resolve(file)
          }
        }))))

        setFiles(promises)
      }

      uploadFiles()
        .catch(err => console.error(err))
        .then(() => setUploading(false))

      // Promise.all(files.map((file, index) => {
      //   if (file.fid === null) {
      //     const data = new FormData()
      //     data.append('file', file.ref)

      //     console.log(data)

      //     return axios.post('/grovvup/post/upload-file', data, {})
      //       .then(res => {
      //         if (res.status !== 200) {
      //           console.warn('Upload failed')
      //           return
      //         }
      //         setFiles([
      //           ...files.slice(0, index),
      //           { ...file, fid: res.data.fid },
      //           ...files.slice(index + 1)
      //         ])
      //       })
      //   }
      //   return null
      // }).filter(promise => promise))
      //   .then(() => {
      //     setUploading(false)
      //   })
      //   .catch(error => console.log(error))
    }
  }, [files, uploading])

  useEffect(() => {
    const loadFiles = async () => {
      const promises = await Promise.all(sourceFiles.map(file => (new Promise((resolve) => {
        if (file.url === null && file.ref.type.substr(0, 6) === 'image/') {
          const reader = new FileReader()
          reader.addEventListener('load', function () {
            resolve({ ...file, url: reader.result })
          })
          reader.readAsDataURL(file.ref)
        } else {
          resolve(file)
        }
      }))))
      setFiles(promises)
    }

    loadFiles().catch(err => console.error(err))

    // const newFiles = []
    // let processedCount = 0

    // sourceFiles.forEach((file, index) => {
    //   if (file.url === null && file.ref.type.substr(0, 6) === 'image/') {
    //     const reader = new FileReader()
    //     reader.addEventListener('load', function () {
    //       newFiles[index] = { ...file, url: reader.result }
    //       processedCount++
    //       if (processedCount === sourceFiles.length) {
    //         setFiles(newFiles)
    //       }
    //       // setFiles([
    //       //   ...files.slice(0, index),
    //       //   { ...file, url: reader.result },
    //       //   ...files.slice(index + 1)
    //       // ])
    //     }, false)
    //     reader.readAsDataURL(file.ref)
    //   } else {
    //     newFiles[index] = file
    //     processedCount++
    //     if (processedCount === sourceFiles.length) {
    //       setFiles(newFiles)
    //     }
    //   }
    // })
  }, [sourceFiles])

  console.log('files', files, uploading)

  const { getRootProps, getInputProps /* , isDragActive */ } = useDropzone({ onDrop })

  return (
    <div className="post-form">
      <div className="line">
        <div className="form-item form-type-select">
          <label>To</label>
          <select
            name="audience"
            defaultValue={audience}
            onChange={event => setAudience(event.target.value)}
            // {...('country' in error ? {
            //   'data-tip': error.country,
            //   'data-type': 'error',
            //   'data-effect': 'solid',
            //   ref: countryRef,
            //   onFocus: resetError
            // } : {})}
          >
            <option key="active" value="active">All active members</option>
            <option key="inactive" value="inactive">All inactive members</option>
            <option key="all" value="all">All members</option>
          </select>
        </div>

        <div className="form-item form-type-select">
          <label>Type</label>
          <select
            name="type"
            defaultValue={type}
            onChange={event => setType(event.target.value)}
            // {...('country' in error ? {
            //   'data-tip': error.country,
            //   'data-type': 'error',
            //   'data-effect': 'solid',
            //   ref: countryRef,
            //   onFocus: resetError
            // } : {})}
          >
            <option key="message">Message</option>
          </select>
        </div>
      </div>
      <div className="line">
        <div className="form-item form-type-textarea">
          <textarea
            name="message"
            className="form-control"
            placeholder="What's on your mind?"
            defaultValue={message}
            onChange={event => setMessage(event.target.value)}
          ></textarea>
        </div>
      </div>

      <div className="form-actions">
        <div className="pull-left">
          <button className="image-btn attach-image" onClick={handleToggleUploadArea}/>
          <button className="image-btn attach-file" onClick={handleToggleUploadArea}/>
        </div>
        <div className="pull-right">
          <div className="form-item form-type-switch">
            <Switch checked={emailChecked} onChange={setEmailChecked}/>
            <label>E-mail</label>
          </div>
          <div className="form-item form-type-switch">
            <Switch checked={smsChecked} onChange={setSMSChecked}/>
            <label>SMS</label>
          </div>

          <FormButton
            text={
              indicatePostComplete
                ? 'Posted!'
                : indicatePostInProgress
                  ? 'Posting...'
                  : 'Send'
            }
            disabled={indicatePostInProgress}
            beakidElement='submit-post'
            onSubmit={handlePostSubmit}
            onComplete={handlePostComplete}
          />
        </div>
      </div>

      {showUploadArea &&
        <div className={classNames({ 'upload-area': true, uploading })} {...getRootProps()}>
          <input {...getInputProps()} />
          {files.length === 0
            ? <>
              <div className="help">
                JPEG, PNG or GIF formats <br/>
                Recommended size: 1920 x 1080 <br/>
                Max. file size: 4mb
              </div>
            </>
            : (
              files.map(file => (
                file.ref.type.substr(0, 6) === 'image/'
                  ? (
                    <div className="file file-type-image">
                      <div className="preview">
                        {file.url
                          ? <img src={file.url}/>
                          : <div className="loading...">Loading...</div>
                        }
                      </div>
                    </div>
                  )
                  : (
                    <div className="file file-type-document">
                      <div className="path">{file.ref.path}</div>
                    </div>
                  )
              ))
            )
          }
        </div>
      }
    </div>
  )
}
