import * as types from './types'
import { createReducer } from 'typesafe-actions'
import * as commands from './constants'

const initialState: types.StripeConnectState = {
  businessType: null,
  businessUrl: null,
  phoneNumber: null,
  postalCode: null,
  ssnLast4: null,
  dob: null,
  streetAddress: null,
  fein: null,
  country: null,
  mcc: null
}

export default createReducer(initialState)
  .handleType(
    commands.STRIPECONNECT_ADOPT_STATE,
    (state, action) => ({
      ...state,
      ...action.payload
    })
  )
  .handleType(
    commands.STRIPECONNECT_BUSINESSTYPE,
    (state, action) => ({
      ...state,
      businessType: action.payload
    })
  )
  .handleType(
    commands.STRIPECONNECT_MERGE_FIELDS,
    (state, action) => ({
      ...state,
      ...action.payload
    })
  )
