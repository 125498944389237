import * as types from './types'
import { createReducer } from 'typesafe-actions'
import * as commands from './constants'

const initialState: types.BusinessBillingState = {
  account: null,
  businessNid: null,
  businessPlans: [],
  saasAddons: [],
  marketingPlans: [],
  businesses: [],
  activeSubscription: null,
  cart: null,
  // activeBusinessPlan: null,
  // activeSaasAddons: [],
  // activeMarketingPlan: null,
  paymentMethods: [],
  defaultPaymentMethod: null,
  invoices: [],
  setupIntent: null,
  period: null
}

export default createReducer<types.BusinessBillingState>(initialState)
  .handleType(
    commands.BUSINESSBILLING_ADOPT_STATE,
    (state, action) => ({
      ...state,
      ...action.payload
    })
  )
  .handleType(
    commands.BUSINESSBILLING_CART_SELECT,
    (state, action) => ({
      ...state,
      cart: action.payload
    })
  )
  .handleType(
    commands.BUSINESSBILLING_ACTIVE_SUBSCRIPTION_SELECT,
    (state, action) => ({
      ...state,
      activeSubscription: action.payload
    })
  )
  // .handleType(
  //   commands.BUSINESSBILLING_PLAN_SELECT,
  //   (state, action) => ({
  //     ...state,
  //     activeBusinessPlan: {
  //       machineName: action.payload,
  //       subscribed: 0
  //     }
  //   })
  // )
  // .handleType(
  //   commands.BUSINESSBILLING_SAAS_ADDONS_SELECT,
  //   (state, action) => ({
  //     ...state,
  //     activeSaasAddons: action.payload.map(addon => ({
  //       machineName: addon,
  //       subscribed: 0
  //     }))
  //   })
  // )
  // .handleType(
  //   commands.BUSINESSBILLING_MARKETING_PLAN_SELECT,
  //   (state, action) => ({
  //     ...state,
  //     activeMarketingPlan: {
  //       machineName: action.payload,
  //       subscribed: 0
  //     }
  //   })
  // )
  // .handleType(
  //   commands.BUSINESSBILLING_PLAN_SET,
  //   (state, action) => ({
  //     ...state,
  //     activeBusinessPlan: action.payload
  //   })
  // )
  // .handleType(
  //   commands.BUSINESSBILLING_MARKETING_PLAN_SET,
  //   (state, action) => ({
  //     ...state,
  //     activeMarketingPlan: action.payload
  //   })
  // )
  .handleType(
    commands.BUSINESSBILLING_PAYMENT_METHODS_SET,
    (state, action) => {
      console.log('Executing BUSINESSBILLING_PAYMENT_METHODS_SET', action.payload)
      return {
        ...state,
        paymentMethods: action.payload
      }
    }
  )
  .handleType(
    commands.BUSINESSBILLING_INVOICES_SET,
    (state, action) => ({
      ...state,
      invoices: action.payload
    })
  )
  .handleType(
    commands.BUSINESSBILLING_DEFAULT_PAYMENT_METHOD_SET,
    (state, action) => ({
      ...state,
      defaultPaymentMethod: action.payload
    })
  )
  .handleType(
    commands.BUSINESSBILLING_BANK_ACCOUNT_SETUP_INTENT_SET,
    (state, action) => ({
      ...state,
      setupIntent: action.payload
    })
  )
  .handleType(
    commands.BUSINESSBILLING_BANK_ACCOUNT_SETUP_INTENT_RESET,
    state => ({
      ...state,
      setupIntent: null
    })
  )
  .handleType(
    commands.BUSINESSBILLING_BILLING_PERIOD_SET,
    (state: types.BusinessBillingState, action): types.BusinessBillingState => ({
      ...state,
      period: action.payload
    })
  )
  .handleType(
    commands.BUSINESSBILLING_COUPON_SET,
    (state: types.BusinessBillingState, action): types.BusinessBillingState => ({
      ...state,
      cart: {
        ...state.cart,
        coupon: action.payload
      }
    })
  )
