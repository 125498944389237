import * as types from './types'
import { createAction } from 'typesafe-actions'
import * as commands from './constants'

export const businessSetupAdoptState = createAction(commands.BUSINESSSETUP_ADOPT_STATE)<types.BusinessSetupState>()
export const businessSetupSaveTask = createAction(commands.BUSINESSSETUP_SAVE_TASK)<types.Task>()

export const businessSetupPlanSelect = createAction(commands.BUSINESSSETUP_PLAN_SELECT)<string>()
export const businessSetupPlanSet = createAction(commands.BUSINESSSETUP_PLAN_SET)<types.ActiveBusinessPlan>()
export const businessSetupPaymentMethodsSet = createAction(commands.BUSINESSSETUP_PAYMENT_METHODS_SET)<types.PaymentMethod[]>()
export const businessSetupDefaultPaymentMethodSet = createAction(commands.BUSINESSSETUP_DEFAULT_PAYMENT_METHOD_SET)<string>()
export const businessSetupBankAccountSetupIntentReset = createAction(commands.BUSINESSSETUP_BANK_ACCOUNT_SETUP_INTENT_RESET)<void>()
export const businessSetupBillingPeriodSet = createAction(commands.BUSINESSSETUP_BILLING_PERIOD_SET)<types.BillingPeriod>()

// export const businessSetupGoto = createCustomAction(
//   commands.BUSINESSSETUP_GOTO,
//   (stage: types.BusinessSetupStage, step: types.BusinessSetupStep) => ({ payload: { stage, step } })
// )

// export const businessStageAboutSubmit = createAction(commands.BUSINESSSETUP_ABOUT_SUBMIT)<types.BusinessAbout>()

// export const businessSetupLocationAdd = createAction(commands.BUSINESSSETUP_LOCATION_ADD)<types.Location>()
// export const businessSetupLocationDelete = createAction(commands.BUSINESSSETUP_LOCATION_DELETE)<number>()
// export const businessSetupLocationUpdate = createCustomAction(
//   commands.BUSINESSSETUP_LOCATION_UPDATE,
//   (locationIndex: number, location: types.Location) => ({ payload: { locationIndex, location } })
// )
// export const businessSetupLocationMakeMain = createAction(commands.BUSINESSSETUP_LOCATION_MAKE_MAIN)<number>()
// export const businessSetupLocationFormCreate = createAction(commands.BUSINESSSETUP_LOCATIONFORM_CREATE)<void>()
// export const businessSetupLocationFormEdit = createAction(commands.BUSINESSSETUP_LOCATIONFORM_EDIT)<number>()
// export const businessSetupLocationFormList = createAction(commands.BUSINESSSETUP_LOCATIONFORM_LIST)<void>()

// export const staffAdd = createAction(commands.STAFF_ADD)<types.StaffMember>()
// export const staffDelete = createAction(commands.STAFF_DELETE)<number>()
// export const staffUpdate = createCustomAction(
//   commands.STAFF_UPDATE,
//   (memberIndex: number, member: types.StaffMember) => ({ payload: { memberIndex, member } })
// )
// export const staffFormCreate = createAction(commands.STAFFFORM_CREATE)<void>()
// export const staffFormEdit = createAction(commands.STAFFFORM_EDIT)<number>()
// export const staffFormList = createAction(commands.STAFFFORM_LIST)<void>()
// export const staffFormIntroduceOwner = createAction(commands.STAFFFORM_INRODUCE_OWNER)<void>()

// export const businessSetupTuitionAdd = createAction(commands.BUSINESSSETUP_TUITION_ADD)<types.Tuition>()
// export const businessSetupTuitionDelete = createAction(commands.BUSINESSSETUP_TUITION_DELETE)<number>()
// export const businessSetupTuitionUpdate = createCustomAction(
//   commands.BUSINESSSETUP_TUITION_UPDATE,
//   (tuitionIndex: number, tuition: types.Tuition) => ({ payload: { tuitionIndex, tuition } })
// )
// export const businessSetupTuitionFormCreate = createAction(commands.BUSINESSSETUP_TUITIONFORM_CREATE)<void>()
// export const businessSetupTuitionFormEdit = createAction(commands.BUSINESSSETUP_TUITIONFORM_EDIT)<number>()
// export const businessSetupTuitionFormList = createAction(commands.BUSINESSSETUP_TUITIONFORM_LIST)<void>()

// export const businessSetupClassAdd = createAction(commands.BUSINESSSETUP_CLASS_ADD)<types.Class>()
// export const businessSetupClassDelete = createAction(commands.BUSINESSSETUP_CLASS_DELETE)<number>()
// export const businessSetupClassUpdate = createCustomAction(
//   commands.BUSINESSSETUP_CLASS_UPDATE,
//   (classIndex: number, cls: types.Class) => ({ payload: { classIndex, cls } })
// )
// export const businessSetupClassFormCreate = createAction(commands.BUSINESSSETUP_CLASSFORM_CREATE)<void>()
// export const businessSetupClassFormEdit = createAction(commands.BUSINESSSETUP_CLASSFORM_EDIT)<number>()
// export const businessSetupClassFormList = createAction(commands.BUSINESSSETUP_CLASSFORM_LIST)<void>()

// export const businessSetupEPaymentBusinessType = createAction(commands.BUSINESSSETUP_EPAYMENT_BUSINESSTYPE)<types.BusinessType>()
// export const businessSetupEPaymentSettings = createAction(commands.BUSINESSSETUP_EPAYMENT_SETTINGS)<types.EPaymentSettings>()

// export const businessSetupSessionAdd = createAction(commands.BUSINESSSETUP_SESSION_ADD)<types.Session>()
// export const businessSetupSessionDelete = createAction(commands.BUSINESSSETUP_SESSION_DELETE)<number>()

// export const businessSetupSessionUpdate = createCustomAction(
//   commands.BUSINESSSETUP_SESSION_UPDATE,
//   (sessionIndex: number, session: types.Session) => ({ payload: { sessionIndex, session } })
// )

// export const businessSetupSessionFormCreate = createAction(commands.BUSINESSSETUP_SESSIONFORM_CREATE)<void>()
// export const businessSetupSessionFormEdit = createAction(commands.BUSINESSSETUP_SESSIONFORM_EDIT)<number>()
// export const businessSetupSessionFormList = createAction(commands.BUSINESSSETUP_SESSIONFORM_LIST)<void>()
