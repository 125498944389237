import React, { useEffect, useState } from 'react'
import { useStripe } from '@stripe/react-stripe-js'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as businessBillingActions from 'Scenes/BusinessBilling/actions'
import Popup from 'react-popup'
import * as types from 'Scenes/BusinessBilling/types'
import Initial from './Initial'
import Mandate from './Mandate'
import { SetupIntent } from '@stripe/stripe-js'
import WaitPopup from '../WaitPopup'
import './styles.scss'

type UsBankAccountInputProps = {
  method: types.PaymentMethodBankAccount
  onComplete: () => void
} & types.PropsWithBusinessBillingState & types.BusinessBillingActions

declare var Drupal: {
  behaviors: {
    grovvupReactApp: {
      formSubmit: (formId: string, beakidElement: string, store: any, completeCallback: () => void) => void
    }
  }
}

function UsBankAccountInput (props: UsBankAccountInputProps) {
  const stripe = useStripe()

  const [showMandate, setShowMandate] = useState<boolean>(false)
  const [confirmationFailed, setConfirmationFailed] = useState<boolean>(false)
  const [microDepoVerificationRequired, setMicroDepoVerificationRequired] = useState<boolean>(false)
  const [showWaitPopup, setShowWaitPopup] = useState<boolean>(false)

  const handleGotClientSecret = (accountHolderName: string, accountHolderEmail: string) => {
    console.log('clientSecret', props.businessBilling.setupIntent.clientSecret)

    stripe.collectBankAccountForSetup({
      clientSecret: props.businessBilling.setupIntent.clientSecret,
      params: {
        payment_method_type: 'us_bank_account',
        payment_method_data: {
          billing_details: {
            name: accountHolderName,
            email: accountHolderEmail
          }
        }
      },
      expand: ['payment_method']
    })
      .then(({ setupIntent, error }) => {
        if (error) {
          console.error(error.message)
          // PaymentMethod collection failed for some reason.
        } else if (setupIntent.status === 'requires_payment_method') {
          // Customer canceled the hosted verification modal. Present them with other
          // payment method type options.
          console.log('Customer canceled verification modal')
        } else if (setupIntent.status === 'requires_confirmation') {
          // We collected an account - possibly instantly verified, but possibly
          // manually-entered. Display payment method details and mandate text
          // to the customer and confirm the intent once they accept
          // the mandate.
          setShowMandate(true)
        }
      })
  }

  const finish = (formId: string, setupIntent: SetupIntent, onComplete: () => void) => {
    setShowWaitPopup(true)
    Drupal.behaviors.grovvupReactApp.formSubmit(
      formId,
      'bank-account-created',
      { 'us-bank-account-payment-method-id': setupIntent.payment_method },
      () => {
        setShowWaitPopup(false)
        onComplete()
      }
    )
  }

  const handleGotMandateConfirmation = (formId: string) => {
    stripe.confirmUsBankAccountSetup(props.businessBilling.setupIntent.clientSecret)
      .then(({ setupIntent, error }) => {
        if (error) {
          console.error(error.message)
          // The payment failed for some reason.
        } else if (setupIntent.status === 'requires_payment_method') {
          // Confirmation failed. Attempt again with a different payment method.
          setConfirmationFailed(true)
        } else if (setupIntent.status === 'succeeded') {
          // Confirmation succeeded! The account is now saved.
          // Display a message to customer.
          console.log('setupIntent', setupIntent)
          finish(formId, setupIntent, props.onComplete)
        } else if (setupIntent.next_action?.type === 'verify_with_microdeposits') {
          // The account needs to be verified via microdeposits.
          // Display a message to consumer with next steps (consumer waits for
          // microdeposits, then enters a statement descriptor code on a page sent to them via email).
          setMicroDepoVerificationRequired(true)
        }
      })
  }

  useEffect(() => {
    if (!microDepoVerificationRequired) {
      return
    }

    const PopupContent = () => (
      <div className="microdeposit-verification-required-notification">
        Microdeposit verification is required
      </div>
    )

    Popup.create({
      title: 'Notification!',
      content: <PopupContent/>,
      buttons: {
        right: [{
          text: 'Ok',
          className: 'success',
          action: function () {
            setShowWaitPopup(false)
            props.onComplete()
            Popup.close()
          }
        }]
      }
    })
  }, [microDepoVerificationRequired])

  const factory = window.BeakidReactFactory
  const FormContext = factory.FormContext

  const screen = showMandate
    ? 'Mandate'
    : confirmationFailed
      ? 'ConfirmationFailed'
      : 'Initial'

  return (
    <FormContext.Consumer>
      {drupalFormId => {
        return (
          <div className="us-bank-account-input">
            <h3>US Bank Account</h3>

            {screen === 'Initial' && <Initial onComplete={(accountHolderName, accountHolderEmail) => handleGotClientSecret(accountHolderName, accountHolderEmail)} onCancel={() => props.onComplete()}/>}
            {screen === 'Mandate' && <Mandate onComplete={() => handleGotMandateConfirmation(drupalFormId)} onCancel={() => props.onComplete()}/>}
            {screen === 'ConfirmationFailed' && <p>Confirmation failed</p>}

            <WaitPopup open={showWaitPopup}/>
          </div>
        )
      }}
    </FormContext.Consumer>
  )
}

const mapStateToProps = (state) => ({
  businessBilling: state.businessBilling
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    ...businessBillingActions
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(UsBankAccountInput)
