import React from 'react'
import Cell from './Cell'
import { connect } from 'react-redux'
import * as types from '../types'
import ChartTotal, { Metric } from './ChartTotal'

type UsersChartProps = types.PropsWithBusinessDashboardState

function UsersChart (props: UsersChartProps) {
  const data = props.businessDashboard.analytics.data[props.businessDashboard.currentBusiness].users

  const metrics: Metric[] = data ? [
    {
      name: 'Total',
      format: 'number',
      values: [{ current: data.total }]
    },
    {
      name: 'Active',
      format: 'number',
      values: [{ current: data.active }]
    },
    {
      name: 'Past',
      format: 'number',
      values: [{ current: data.past }]
    }
  ] : []

  return (
    <Cell title="Users">
      <ChartTotal metrics={metrics}/>
    </Cell>
  )
}

const mapStateToProps = (state) => ({
  businessDashboard: state.businessDashboard
})

export default connect(mapStateToProps)(UsersChart)
