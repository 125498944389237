import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import * as businessBillingActions from './actions'
import * as types from 'Scenes/BusinessBilling/types'
import Showcase from './components/Showcase'
import Dashboard from './components/Dashboard'
import './styles.scss'

const stripePromise = loadStripe(window.Drupal.settings.beakid.billing.stripe.keys.publishable)

type BusinessBillingProps = {
  state: { businessBilling?: types.BusinessBillingState }
} & types.PropsWithBusinessBillingState
  & types.BusinessBillingActions

function BusinessBilling (props: BusinessBillingProps) {
  useEffect(() => {
    if ('businessBilling' in props.state) {
      console.log('Adopting state', props.state)
      props.businessBillingAdoptState(props.state.businessBilling)
    }
  }, [props.state])

  const screen = props.businessBilling.activeSubscription
    ? 'Dashboard'
    : 'Showcase'

  console.log('props.businessBilling', props.businessBilling)
  console.log('screen', screen)

  return (
    <Elements stripe={stripePromise} options={{}}>
      <div className="business-billing">
        {screen === 'Dashboard' && (
          <Dashboard/>
        )}
        {screen === 'Showcase' && (
          <Showcase/>
        )}
      </div>
    </Elements>
  )
}

const mapStateToProps = (state) => ({
  businessBilling: state.businessBilling
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    ...businessBillingActions
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(BusinessBilling)
