import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import * as types from 'Scenes/BusinessSetup/types'
import Progress from '../Progress'
import FormButton from 'Beakid/FormButton'
import classNames from 'classnames'
import './styles.scss'

type TaskListItemProps = {
  name: string
  onTrigger: () => void
} & types.PropsWithBusinessSetupState

function TaskListItem (props: TaskListItemProps) {
  const task = useMemo(() => (
    props.businessSetup.tasks.find(item => item.name === props.name)
  ), [props])

  const prevTask = useMemo(() => (
    task ? props.businessSetup.tasks.find(item => item.order === task.order - 1) : undefined
  ), [task])

  const isActive = prevTask === undefined ||
    task.completed > 0 ||
    prevTask.completed > 0

  return (
    <div
      className={classNames({
        'task-list-item': true,
        active: isActive,
        started: task.completed > 0.0 && task.completed < 1.0,
        completed: task.completed === 1.0
      })}
    >
      <div className="column column-progress">
        <Progress value={task.completed}/>
      </div>
      <div className="column column-info">
        <div className="name">{task.title}</div>
        <div className="description">{task.description}</div>
      </div>
      <div className="column column-action">
        {task.completed === 1.0 && (
          <span className="step-completed">Step completed</span>
        )}
        {!(task.completed === 1.0 && !task.reentrable) && (
          <FormButton
            text={task.completed === 0.0 ? 'Start' : ''}
            className={classNames({
              'btn-start': task.completed === 0.0,
              'btn-edit': task.completed !== 0.0
            })}
            disabled={prevTask && prevTask?.completed !== 1.0}
            onClick={() => props.onTrigger()}
          />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  businessSetup: state.businessSetup
})

export default connect(mapStateToProps)(TaskListItem)
