import React from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { Invoice as InvoiceType } from 'Scenes/BusinessBilling/types'
import FormButton from 'Beakid/FormButton'

type InvoiceProps = {
  invoice: InvoiceType,
}

export function Invoice ({ invoice }: InvoiceProps) {
  const handleInvoiceClick = () => window.open(invoice.invoiceLink, '_blank', 'noopener,noreferrer')
  const handleRetryCharge = () => ({
    invoice_id: invoice.id
  })

  return (
    <div
      className={classNames({ invoice: true, [`status-${invoice.status.replace(' ', '-').toLowerCase()}`]: true })}
      onClick={() => handleInvoiceClick()}
    >
      <div className="cell number">{invoice.number}</div>
      <div className="cell created">{moment.unix(invoice.created).format('DD MMM, YYYY')}</div>
      <div className="cell status">
        {invoice.status}
        {invoice.status === 'open' &&
          <FormButton text="Retry charge" beakidElement="invoice-retry-charge" onSubmit={handleRetryCharge}/>
        }
      </div>
      <div className="cell product">{invoice.product.title}</div>
      <div className="cell amount-due">{invoice.currency} {invoice.amountDue.toFixed(2)}</div>
    </div>
  )
}
