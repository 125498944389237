import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import * as types from 'Scenes/BusinessSetup/types'
import classNames from 'classnames'
import './styles.scss'

type TaskDef = {
  name: string
  description: string
}

type SubtaskDef = TaskDef & {
  subtask: string
}

export type WorkflowItem = ({
  task: 'basic' | 'class' | 'payment' | 'launch' | 'billing'
  subtasks?: SubtaskDef[]
}) & TaskDef

const workflow: WorkflowItem[] = [
  {
    task: 'basic',
    name: 'Basic Information',
    description: 'Tell us about your business'
  },
  {
    task: 'class',
    name: 'Class Setup',
    description: 'Create your first class / program'
  },
  {
    task: 'payment',
    name: 'Payment Information',
    description: 'How you will get paid'
  },
  {
    task: 'billing',
    name: 'Billing',
    description: 'How you will pay to us',
    subtasks: [
      {
        subtask: 'payment method',
        name: 'Add credit card or US bank account',
        description: 'You wont be charged during the trial period of 7 days'
      },
      {
        subtask: 'plan',
        name: 'Payment Plan',
        description: 'Choose Payment Plan'
      }
    ]
  },
  {
    task: 'launch',
    name: 'Launch',
    description: 'The final step. Make your business and class accessible to customer.'
  }
]

type HeaderProps = {
  task: types.TaskType
  subtask?: string
} & types.PropsWithBusinessSetupState

function Header (props: HeaderProps) {
  const activeItemIndex = useMemo(() => workflow.findIndex(item => item.task === props.task), [props.task])
  const activeTask = useMemo(() => props.businessSetup.tasks[activeItemIndex], [activeItemIndex])
  const subtask = useMemo(() => workflow[activeItemIndex].subtasks?.find(item => item.subtask === props.subtask), [activeItemIndex, props.subtask])

  return (
    <div className="header">
      <div className="workflow-wrapper">
        <ul className="workflow">
          {workflow.map((item, index) => (
            <li key={index} className={classNames({
              past: index < activeItemIndex,
              active: index === activeItemIndex,
              future: index > activeItemIndex
            })}>
              {item.name}
            </li>
          ))}
        </ul>
      </div>
      <div className="description">{activeTask.description}</div>
      {'subtask' in activeTask && 'subtask' in props && (
        <div className="subtask">
          <div className="name">{subtask.name}</div>
          <div className="description">{subtask.description}</div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  businessSetup: state.businessSetup
})

export default connect(mapStateToProps)(Header)
