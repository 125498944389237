export const ADOPT_STATE = 'FORMEDITOR_ADOPT_STATE'
export const SET_TITLE = 'FORMEDITOR_SET_TITLE'
export const SET_DESCRIPTION = 'FORMEDITOR_SET_DESCRIPTION'
export const NEW_QUESTION = 'FORMEDITOR_NEW_QUESTION'
export const UPDATE_QUESTION = 'FORMEDITOR_UPDATE_QUESTION'
export const DUPLICATE_QUESTION = 'FORMEDITOR_DUPLICATE_QUESTION'
export const DELETE_QUESTION = 'FORMEDITOR_DELETE_QUESTION'
export const RESET = 'FORMEDITOR_RESET'
export const SORT_QUESTIONS = 'SORT_QUESTIONS'
export const LOCKOUT_DATE_SET = 'LOCKOUT_DATE_SET'
