export const BUSINESSDASHBOARD_ADOPT_STATE = 'BUSINESSDASHBOARD_ADOPT_STATE'
export const BUSINESSDASHBOARD_FILTER_SET = 'BUSINESSDASHBOARD_FILTER_SET'
// export const BUSINESSDASHBOARD_TOTAL_SIGNUPS_SET = 'BUSINESSDASHBOARD_TOTAL_SIGNUPS_SET'
// export const BUSINESSDASHBOARD_TOTAL_CHARGED_SET = 'BUSINESSDASHBOARD_TOTAL_CHARGED_SET'
// export const BUSINESSDASHBOARD_REFUNDS_SET = 'BUSINESSDASHBOARD_REFUNDS_SET'
// export const BUSINESSDASHBOARD_POPULAR_CLASSES_SET = 'BUSINESSDASHBOARD_POPULAR_CLASSES_SET'
// export const BUSINESSDASHBOARD_SCORES_SET = 'BUSINESSDASHBOARD_SCORES_SET'
// export const BUSINESSDASHBOARD_PAYOUTS_SET = 'BUSINESSDASHBOARD_PAYOUTS_SET'
export const BUSINESSDASHBOARD_BUSINESS_SET = 'BUSINESSDASHBOARD_BUSINESS_SET'
export const BUSINESSDASHBOARD_CALENDAR_SET = 'BUSINESSDASHBOARD_CALENDAR_SET'
export const BUSINESSDASHBOARD_ANALYTICS_SET = 'BUSINESSDASHBOARD_ANALYTICS_SET'
export const BUSINESSDASHBOARD_PERIOD_SET = 'BUSINESSDASHBOARD_PERIOD_SET'
