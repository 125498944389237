import React from 'react'
import Switch from 'react-ios-switch'

export default function CustomizedSwitch (props) {
  Switch.prototype.getWidth = () => {
    return 32
  }

  Switch.prototype.getHeight = () => {
    return 20
  }

  return <Switch {...props} />
}
