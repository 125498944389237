import React, { useMemo, useState } from 'react'
import Switch from '../Switch'
import { BusinessPlan } from 'Scenes/BusinessSetup/types'
import classNames from 'classnames'
import { Cost } from '../Cost'
import { connect } from 'react-redux'
import * as types from 'Scenes/BusinessSetup/types'

type BusinessBillingProps = {
  plan: BusinessPlan,
  onSelected: (planMachineName: string) => void
} & types.PropsWithBusinessSetupState

function Plan (props: BusinessBillingProps) {
  const data = props.businessSetup.tasks[3].data as types.DataBilling
  const [option1, option2] = props.plan.subscriptionOptions
  const [leftChosen, setLeftChosen] = useState<boolean>(true)
  const selectedOption = useMemo(() => leftChosen ? option1 : option2, [leftChosen])

  const handleSelect = event => {
    event.preventDefault()
    event.stopPropagation()
    props.onSelected((leftChosen ? option1 : option2).planMachineName)
  }

  const cost = selectedOption.cost[data.period] ?? selectedOption.cost[Object.keys(selectedOption.cost).shift()]

  return (
    <div className={classNames({ plan: true, [props.plan.title.replace(/[\W_]+/g, '-').toLowerCase()]: true, promoted: props.plan.promoted })}>
      <h2>{props.plan.title}</h2>

      <div className="options">
        <div className="or">{option2 && 'or'}</div>
        <label className={classNames({ option: true, active: leftChosen })}>{option1.title}</label>
        {option2 && (<>
          <Switch onChange={() => setLeftChosen(!leftChosen)} checked={!leftChosen}/>
          <label className={classNames({ option: true, active: !leftChosen })}>{option2.title}</label>
        </>)}
      </div>

      <div className="cost">
        <span className="value"><Cost { ...cost }/></span>
        /
        {cost.subject}
      </div>

      <div className="plus">+</div>

      <div className="card-fees">
        <div className="text">Credit Card Fees</div>
        <div className="fee">2.9% + 30c</div>
      </div>

      <div className="action">
        <button onClick={event => handleSelect(event)}><span>Get Started</span></button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  businessSetup: state.businessSetup
})

export default connect(mapStateToProps)(Plan)
