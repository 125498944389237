import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as businessBillingActions from 'Scenes/BusinessBilling/actions'
import ReactTooltip from 'react-tooltip'
import FormButton from 'Beakid/FormButton'
import * as types from 'Scenes/BusinessBilling/types'
import WaitPopup from 'Scenes/BusinessBilling/components/WaitPopup'
import './styles.scss'

type InitialProps = {
  onComplete: (accountHolderName: string, accountHolderEmail: string) => void
  onCancel: () => void
} & types.PropsWithBusinessBillingState & types.BusinessBillingActions

interface InitialError {
  accountHolderName?: string,
  accountHolderEmail?: string,
}

function Initial (props: InitialProps) {
  const [accountHolderName, setAccountHolderName] = useState<string>(props.businessBilling.account.firstName + ' ' + props.businessBilling.account.lastName)
  const [accountHolderEmail, setAccountHolderEmail] = useState<string>(props.businessBilling.account.email)
  const [showWaitPopup, setShowWaitPopup] = useState<boolean>(false)

  const accountHolderNameRef = useRef(null)
  const accountHolderEmailRef = useRef(null)
  const [error, setError] = useState<InitialError>({})

  const [isRequested, setIsRequested] = useState<boolean>(false)

  useEffect(() => {
    if (Object.keys(error).length > 0) {
      console.log('Validation error', error)
      setShowWaitPopup(false)
    }
    if ('accountHolderName' in error) {
      ReactTooltip.show(accountHolderNameRef.current)
    }
    if ('accountHolderEmail' in error) {
      ReactTooltip.show(accountHolderEmailRef.current)
    }
  }, [error])

  const resetError = (event) => {
    ReactTooltip.hide(event.target)
    setError({})
  }

  const handleClick = () => {
    props.businessBillingBankAccountSetupIntentReset()
    setIsRequested(true)
    return {}
  }

  const handleCancel = (event?) => {
    event.preventDefault()
    event.stopPropagation()
    if ('onComplete' in props) {
      props.onCancel()
    }
  }

  const gotClientSecret = () => {
    console.log('clientSecret', props.businessBilling.setupIntent.clientSecret)
    props.onComplete(accountHolderName, accountHolderEmail)
  }

  useEffect(() => {
    if (isRequested && props.businessBilling.setupIntent) {
      gotClientSecret()
    }
  }, [isRequested, props.businessBilling.setupIntent])

  return (
    <div className="us-bank-account-input--initial">
      <div className="elements">
        <div className="section section-1">
          <div className="line line-1">
            <input
              type="text"
              className="account-holder-name"
              placeholder="Account holder name"
              onChange={event => setAccountHolderName(event.target.value)}
              // defaultValue={accountHolderName}
              {...('accountHolderName' in error ? {
                'data-tip': error.accountHolderName,
                'data-type': 'error',
                'data-effect': 'solid',
                ref: accountHolderNameRef,
                onFocus: resetError
              } : {})}
            />
          </div>
          <div className="line line-2">
            <input
              type="text"
              className="account-holder-email"
              placeholder="Account holder e-mail"
              onChange={event => setAccountHolderEmail(event.target.value)}
              // defaultValue={accountHolderEmail}
              {...('accountHolderEmail' in error ? {
                'data-tip': error.accountHolderEmail,
                'data-type': 'error',
                'data-effect': 'solid',
                ref: accountHolderEmailRef,
                onFocus: resetError
              } : {})}
            />
          </div>
        </div>
      </div>

      <div className="actions">
        <button className="btn-cancel" onClick={event => handleCancel(event)}>Cancel</button>
        <FormButton
          text="Setup Bank Account"
          className="btn-start btn-submit"
          beakidElement="create-setup-intent"
          onClick={() => setError({})}
          onSubmit={() => handleClick()}
        />
      </div>

      {Object.keys(error).length > 0 && <ReactTooltip />}

      <WaitPopup open={showWaitPopup}/>
    </div>
  )
}

const mapStateToProps = (state) => ({
  businessBilling: state.businessBilling
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    ...businessBillingActions
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(Initial)
