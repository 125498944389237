import React from 'react'
import { Period } from '../types'
import { connect } from 'react-redux'
import * as types from '../types'

type PeriodSelectProps = {
  period: Period | 'custom'
  onChange: (period: Period | 'custom') => void
} & types.PropsWithBusinessDashboardState

function PeriodSelect (props: PeriodSelectProps) {
  return (
    <div className="period">
      <select value={props.period} onChange={ev => props.onChange(ev.target.value as Period)}>
        <option value="mtd" key="mtd">Month to date</option>
        <option value="ytd" key="ytd">Year to date</option>
        {props.businessDashboard.period === 'custom' && props.period === 'custom' && (
          <option value="custom" key="custom">Custom</option>
        )}
        {/* <option value="last month" key="last month">Last month</option> */}
      </select>
    </div>
  )
}

const mapStateToProps = (state) => ({
  businessDashboard: state.businessDashboard
})

export default connect(mapStateToProps)(PeriodSelect)
