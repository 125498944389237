import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import PeriodSelect from './PeriodSelect'
import * as businessDashboardActions from '../actions'
import * as types from '../types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

type PostFormProps = {
  title: string
  notification?: string
  children?: React.ReactNode
  period?: types.Period
  onPeriodChange?: (period: types.Period) => void
} & types.PropsWithBusinessDashboardState & types.BusinessDashboardActions

function Cell (props: PostFormProps) {
  const [period, setPeriod] = useState<types.Period>(props.period ?? 'ytd')

  useEffect(() => {
    if (period !== props.businessDashboard.period) {
      props.businessDashboardPeriodSet('custom')
    }
    props.onPeriodChange && props.onPeriodChange(period)
  }, [period])

  useEffect(() => {
    if (props.businessDashboard.period !== 'custom' && period !== props.businessDashboard.period) {
      setPeriod(props.businessDashboard.period)
    }
  }, [props.businessDashboard.period])

  const handlePeriod = (period: types.Period | 'custom') => {
    if (period !== 'custom') {
      setPeriod(period)
    }
  }

  console.log('Cell period', period)

  return (
    <div className={classNames({ cell: true, [`cell-${props.title.toLowerCase().replace(/[^a-z0-9]/gi, '-')}`]: true })}>
      <div className="cell-header">
        <div className="cell-title">{props.title}</div>
        {props.notification &&
          <div className="notification">{props.notification}</div>
        }

        {props.onPeriodChange && (
          <PeriodSelect period={period} onChange={handlePeriod}/>
        )}
      </div>
      <div className="cell-content">
        { props.children }
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  businessDashboard: state.businessDashboard
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    ...businessDashboardActions
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(Cell)
