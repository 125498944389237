import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as businessSetupActions from 'Scenes/BusinessSetup/actions'
import * as types from 'Scenes/BusinessSetup/types'
import Header from '../Header'
import SpinnerPopup from 'reactjs-popup'
import { SpinnerCircular } from 'spinners-react'

type TaskLaunchProps = {
  task: types.Task
  onDone: () => void
} & types.PropsWithBusinessSetupState & types.BusinessSetupActions

declare var Drupal: {
  behaviors: {
    grovvupReactApp: {
      formSubmit: (formId: string, beakidElement: string, store: any, completeCallback: () => void) => void
    }
  }
}

function TaskLaunch (props: TaskLaunchProps) {
  // const basicData = props.businessSetup.tasks[0].data as types.DataBasic

  useEffect(() => {
    Drupal.behaviors.grovvupReactApp.formSubmit(
      null,
      'task-launch-submit',
      {},
      () => {}
    )
  }, [])

  return (
    <div className="task task-launch">
      <Header task={props.task.name}/>

      <div className="content">
        <SpinnerPopup open={true} className="business-launch-popup">
          <SpinnerCircular />
        </SpinnerPopup>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  businessSetup: state.businessSetup
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    ...businessSetupActions
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(TaskLaunch)
