import { createStore, combineReducers } from 'redux'
import businessSetup from './BusinessSetup/reducer'
import businessDashboard from './BusinessDashboard/reducer'
import formEditor from './FormEditor/reducer'
import eventManagement from './EventManagement/reducer'
import businessBilling from './BusinessBilling/reducer'
import stripeConnect from './StripeConnect/reducer'

// const sentryReduxEnhancer = Sentry.createReduxEnhancer();

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__: any;
    grovvupReactGetStore: () => any
  }
}

const store = createStore(
  combineReducers({
    businessSetup,
    businessDashboard,
    formEditor,
    eventManagement,
    businessBilling,
    stripeConnect
  }),
  /*, sentryReduxEnhancer */
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true })
)

window.grovvupReactGetStore = () => store

export default store
