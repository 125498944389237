import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as types from '../../types'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/timegrid' // a plugin!
import * as businessDashboardActions from '../../actions'
import './styles.scss'

type CalendarProps = {} & types.PropsWithBusinessDashboardState & types.BusinessDashboardActions

declare var Drupal: {
  behaviors: {
    grovvupReactApp: {
      formSubmit: (formId: string, beakidElement: string, store: any) => void
    }
  }
}

const classToColor = {}
let lastChoosedColor = -1
const rgbOpacity = '30'

function Calendar (props: CalendarProps) {
  const [fetchCount, setFetchCount] = useState(0)

  const getClassroomColor = (id) => {
    const colors = [
      '#3F69FF',
      '#2CB774',
      '#E667AF',
      '#36D986',
      '#FF7B40',
      '#33CCCC'
    ]

    if (!(id in classToColor)) {
      lastChoosedColor++
      if (lastChoosedColor >= colors.length) {
        lastChoosedColor = 0
      }
      classToColor[id] = colors[lastChoosedColor]
    }

    return classToColor[id]
  }

  const events = useMemo(() => (
    props.businessDashboard.calendar.events.map(event => ({
      ...event,
      start: new Date(event.start),
      end: new Date(event.end),
      title: event.classroom.title + '/' + event.session.title,
      allDay: false,
      backgroundColor: event.studentCount > 0
        ? getClassroomColor(event.classroom.nid) + rgbOpacity
        : 'darkgray',
      textColor: getClassroomColor(event.classroom.nid),
      borderColor: event.studentCount > 0
        ? getClassroomColor(event.classroom.nid)
        : 'darkgray'
    }))
  ), [props.businessDashboard.calendar.events])

  console.log('today events', events)

  const renderEvent = (arg) => {
    return (
      <>
        <div className="fc-event-title">{arg.event.title}</div>
        <div className="fc-event-time">{arg.timeText}</div>
        <div
          className="fc-count"
          style={{ backgroundColor: arg.borderColor }}
        >
          {arg.event.extendedProps.studentCount}
          {arg.event.extendedProps.studentCount === 1 ? 'student' : 'students'}
        </div>
      </>
    )
  }

  const handleBusiness = (formId, currentBusiness) => {
    console.log('Switching to business 2:', currentBusiness)
    // props.businessDashboardBusinessSet(currentBusiness)
    if (fetchCount > 0) {
      Drupal.behaviors.grovvupReactApp.formSubmit(formId, 'submit-business', {
        businessDashboard: {
          currentBusiness
        }
      })
    }
    setFetchCount(fetchCount + 1)
  }

  useEffect(() => {
    if (props.businessDashboard.currentBusiness !== null) {
      handleBusiness('', props.businessDashboard.currentBusiness)
    }
  }, [props.businessDashboard.currentBusiness])

  // const factory = window.BeakidReactFactory
  // const FormContext = factory.FormContext

  return (
    <div className="dashboard-calendar">
      {
      // props.businessDashboard.isFranchisor && (
      //   <FormContext.Consumer>
      //     {formId => (
      //       <div className="business">
      //         {/* <label>Business</label> */}
      //         <select onChange={event => handleBusiness(formId, event.target.value)} defaultValue={props.businessDashboard.currentBusiness}>
      //           <option key="main" value={props.businessDashboard.mainBusiness}>Main Business</option>
      //           {props.businessDashboard.franchisees.map(franchisee => <option key={franchisee.id} value={franchisee.id}>{franchisee.title}</option>)}
      //         </select>
      //       </div>
      //     )}
      //   </FormContext.Consumer>
      // )
      }

      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="timeGridDay"
        headerToolbar={{
          start: 'prev,title,next',
          center: 'today',
          end: ''
        }}
        titleFormat={{ weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' }}
        // titleFormat={date => ({ html: `<span>test</span>${moment(date.date).format('dddd, MMMM')}` })}
        slotLabelFormat={{
          hour: 'numeric',
          minute: '2-digit',
          omitZeroMinute: false,
          meridiem: 'short'
        }}
        dayHeaders={false}
        nowIndicator={true}
        eventMinHeight={40}
        events={events}
        eventContent={renderEvent}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  businessDashboard: state.businessDashboard
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    ...businessDashboardActions
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(Calendar)
