import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from 'Scenes/StripeConnect/actions'
import * as types from 'Scenes/StripeConnect/types'
import PhoneInput from 'react-phone-input-2'
import ReactTooltip from 'react-tooltip'
import Datetime from 'react-datetime'
import moment from 'moment'
import 'react-phone-input-2/lib/style.css'
import './styles.scss'

type IndividualInputProps = {
  onChange: (fields: types.StripeConnectCompanyFields) => void,
  error?: types.StripeConnectError
} & types.PropsWithStripeConnectState

const countryRequirements = {
  [types.Country.Australia]: [
    'mcc'
  ]
}

const mccs = [
  { code: 7032, title: 'Sporting/Recreation Camps' },
  { code: 7911, title: 'Dance Hall, Studios, Schools' },
  { code: 8211, title: 'Elementary, Secondary Schools' },
  { code: 8299, title: 'Schools and Educational Services' }
]

function IndividualInput (props: IndividualInputProps) {
  const ssnLast4Ref = useRef()
  const businessUrlRef = useRef()
  const phoneNumberRef = useRef()
  const streetAddressRef = useRef()
  const postalCodeRef = useRef()
  const dobRef = useRef()
  const mccRef = useRef()

  const [updated, setUpdated] = useState<boolean>(false)
  const [error, setError] = useState(props.error)
  const [ssnLast4, setLastSsn4] = useState<string>(props.stripeConnect.ssnLast4 || '')
  const [businessUrl, setBusinessUrl] = useState<string>(props.stripeConnect.businessUrl || '')
  const [phoneNumber, setPhoneNumber] = useState<string>(props.stripeConnect.phoneNumber || '')
  const [streetAddress, setStreetAddress] = useState<string>(props.stripeConnect.streetAddress || '')
  const [postalCode, setPostalCode] = useState<string>(props.stripeConnect.postalCode || '')
  const [tosAccepted, setTosAccepted] = useState<boolean>(props.stripeConnect.tosAccepted || false)
  const [dob, setDOB] = useState(props.stripeConnect.dob || null)
  const [mcc, setMCC] = useState(props.stripeConnect.mcc)

  const fields = {
    ...props.stripeConnect,
    ssnLast4,
    businessUrl,
    phoneNumber,
    streetAddress,
    postalCode,
    tosAccepted,
    dob,
    mcc
  }

  console.log('phoneNumber', phoneNumber)

  // change field via setter callback, then set 'updated' flag used below
  const updateField = (setter: any, value: any) => {
    setter(value)
    setUpdated(true)
  }

  // once fields gets updated, call parent' onChange event once
  useEffect(() => {
    if (updated) {
      props.onChange(fields)
      setUpdated(false)
    }
  }, [fields, updated])

  // emit error tooltip
  useEffect(() => {
    if ('ssnLast4' in error) {
      ReactTooltip.show(ssnLast4Ref.current)
    }
    if ('businessUrl' in error) {
      ReactTooltip.show(businessUrlRef.current)
    }
    if ('streetAddress' in error) {
      ReactTooltip.show(streetAddressRef.current)
    }
    if ('phoneNumber' in error) {
      ReactTooltip.show(phoneNumberRef.current)
    }
    if ('postalCode' in error) {
      ReactTooltip.show(postalCodeRef.current)
    }
  }, [error])

  // adopt error status from props
  useEffect(() => {
    setError(props.error)
  }, [props.error])

  // reset error (typically when input gets focus)
  const resetError = (event) => {
    ReactTooltip.hide(event.target)
    setError({})
  }

  return (
    <div className="epayment-settings settings-individual">
      <div className="line line-ssnlast4">
        <input
          type="text"
          onChange={event => updateField(setLastSsn4, event.target.value)}
          value={ssnLast4}
          placeholder="SSN (last 4 digits) *"
          {...('ssnLast4' in error ? ({
            'data-tip': error.ssnLast4,
            'data-type': 'error',
            'data-effect': 'solid',
            ref: ssnLast4Ref,
            onFocus: resetError
          }) : {})}
        />
      </div>
      <div
        className="line line-dob"
        {...('dob' in error ? {
          'data-tip': error.dob,
          'data-type': 'error',
          'data-effect': 'solid',
          ref: dobRef
        } : {})}
      >
        <Datetime
          value={dob > 0 ? new Date(dob * 1000) : null}
          {...('dob' in error ? {
            onFocus: resetError
          } : {})}
          dateFormat='MMM D, YYYY'
          timeFormat={false}
          closeOnSelect={true}
          inputProps={{ placeholder: 'Date of Birth' }}
          onChange={(monent: moment.Moment) => setDOB(monent.valueOf() / 1000)}
        />
      </div>
      {countryRequirements[props.stripeConnect.country]?.map((req, reqIndex) => (
        req === 'mcc' && (
          <div className="line line-mcc" key={reqIndex}>
            <select
              name="mcc"
              placeholder="Merchant category code"
              defaultValue={mcc}
              onChange={event => updateField(setMCC, event.target.value)}
              {...('country' in error ? {
                'data-tip': error.mcc,
                'data-type': 'error',
                'data-effect': 'solid',
                ref: mccRef,
                onFocus: resetError
              } : {})}
            >
              <option key={-1}>Merchant category code</option>
              {mccs.map(({ code, title }) => <option value={code} key={code}>{title}</option>)}
            </select>
          </div>
        )
      ))}
      <div className="line line-business-url">
        <input
          type="text"
          onChange={event => updateField(setBusinessUrl, event.target.value)}
          value={businessUrl}
          placeholder="Business URL *"
          {...('businessUrl' in error ? ({
            'data-tip': error.businessUrl,
            'data-type': 'error',
            'data-effect': 'solid',
            ref: businessUrlRef,
            onFocus: resetError
          }) : {})}
        />
      </div>
      <div
        className="line line-phone-number"
        {...('phoneNumber' in error ? ({
          'data-tip': error.phoneNumber,
          'data-type': 'error',
          'data-effect': 'solid',
          ref: phoneNumberRef,
          onFocus: resetError
        }) : {})}
      >
        <PhoneInput
          placeholder="Business Phone Number *"
          value={phoneNumber}
          onChange={value => updateField(setPhoneNumber, value)}
          country={props.stripeConnect.country}
          onlyCountries={Object.values(types.Country).map(country => country.toLowerCase())}
          countryCodeEditable={false}
          {...('phoneNumber' in error ? ({
            onFocus: resetError
          }) : {})}
        />
      </div>
      <div className="line line-address">
        <input
          type="text"
          onChange={event => updateField(setStreetAddress, event.target.value)}
          value={streetAddress}
          placeholder="Street Address *"
          {...('streetAddress' in error ? ({
            'data-tip': error.streetAddress,
            'data-type': 'error',
            'data-effect': 'solid',
            ref: streetAddressRef,
            onFocus: resetError
          }) : {})}
        />
        <input
          type="text"
          onChange={event => updateField(setPostalCode, event.target.value)}
          value={postalCode}
          placeholder="Zip Code *"
          {...('postalCode' in error ? ({
            'data-tip': error.postalCode,
            'data-type': 'error',
            'data-effect': 'solid',
            ref: postalCodeRef,
            onFocus: resetError
          }) : {})}
        />
      </div>
      <div className="line line-tos">
        <div className="form-type-checkbox">
          <input type="checkbox" checked={tosAccepted} onChange={event => updateField(setTosAccepted, event.target.checked)}/>
        </div>
        <div className="text-content">
          <label>For accepting payments, you must agree to</label>
          <a href="#https://stripe.com/connect-account/legal#connected-account-agreement" target="_blank">Stripe Connected Account Agreement</a>
          *
        </div>
        {/* {'tosAccepted' in props.businessSetup.epayment ? (
          true === props.businessSetup.epayment.tosAccepted ? (
          ) : (
          )
        ) : (
        )} */}
      </div>
      {props.error !== {} && <ReactTooltip />}
    </div>
  )
}

const mapStateToProps = (state) => ({
  stripeConnect: state.stripeConnect
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    ...actions
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(IndividualInput)
