import React from 'react'
import { connect } from 'react-redux'
import * as types from 'Scenes/BusinessBilling/types'
import { Cost } from '../Cost'
import classNames from 'classnames'
// import './styles.scss'

type SubscriptionItemProps = {
  itemType: 'saasPlan' | 'saasAddon' | 'marketingPlan'
  planMachineName: string
  period: types.BillingPeriod
  hideCost: boolean
  longText: boolean
} & types.PropsWithBusinessBillingState

function SubscriptionItem (props: SubscriptionItemProps) {
  const items = props.itemType === 'saasPlan'
    ? props.businessBilling.businessPlans
    : props.itemType === 'saasAddon'
      ? props.businessBilling.saasAddons
      : props.businessBilling.marketingPlans

  const item = items.find(plan => plan.subscriptionOptions[0].planMachineName === props.planMachineName)

  const cost = item.subscriptionOptions[0].cost[props.period]

  const showFees = props.itemType === 'saasPlan'

  const tags = {
    saasPlan: 'SaaS Plan',
    saasAddon: 'SaaS Addon',
    marketingPlan: 'Marketing Plan'
  }

  return (
    <div className={classNames({ 'subscription-item': true, [`plan-${item.title.replace(/[\W_]+/g, '-').toLowerCase()}`]: true })}>
      <div className="text-info">
        <div className="identity">
          <span className={classNames({ 'item-type': true, [`item-type-${tags[props.itemType].replace(' ', '-').toLowerCase()}`]: true })}>{tags[props.itemType]}</span>
          <span className="subscription-item-title">{item.title}</span>
        </div>
        <div className="description">{props.longText ? item.description : item.description?.substring(0, 200) + '...'}
          {!props.longText && item.description !== undefined &&
            <span className="tooltip-anchor tooltip" title={item.description}></span>
          }
        </div>
      </div>

      {!props.hideCost && (
        <div className="cost">
          <span className="value"><Cost { ...cost }/></span>
          /
          {cost.subject}

          {showFees && (
            <div className="card-fees">
              <div className="text">Credit Card Fees</div>
              <div className="fee">2.9% + 30c</div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  businessBilling: state.businessBilling
})

export default connect(mapStateToProps)(SubscriptionItem)
