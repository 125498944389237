import React from 'react'
import Cell from './Cell'
import { connect } from 'react-redux'
import * as types from '../types'
import ReactTooltip from 'react-tooltip'

type BalancesChartProps = types.PropsWithBusinessDashboardState

function BalancesChart (props: BalancesChartProps) {
  const data = props.businessDashboard.analytics.data[0]?.balances
  const currency = props.businessDashboard.mainBusinessCurrency?.isoCode

  const format = (value: number, format: 'money') => (
    format === 'money' || format === '%'
      ? new Intl.NumberFormat('en-US').format(value)
      : value
  )

  if (data === undefined) {
    return null
  }

  return (
    <Cell title="Balances & Refunds">
      <div className="chart-total">
        <div className="metrics">
          <div className="metric metric-pool-size" key="pool">
            <div className="name">
              <span>Refund Pool Size</span>
            </div>
            <div className="values">
              <div className="item" key={0}>
                <span className="table-cell tooltipster" data-for="balances" data-tip="Total size of the set pool">Reserve Refund Pool Size</span>
                <span className="currency table-cell">{currency}</span>
                <span className="value table-cell">{format(data?.pool, 'money')}</span>
              </div>
              <div className="item" key={1}>
                <span className="table-cell" data-for="balances" data-tip="What is currently available for refunds">Current Remainder</span>
                <span className="currency table-cell">{currency}</span>
                <span className="value table-cell">{format(Math.max(0, data?.available - data?.pool), 'money')}</span>
              </div>
            </div>
          </div>
          <div className="metric metric-pool-size" key="available">
            <div className="name">
              <span className="tooltipster" data-for="balances" data-tip="Does not include refund pool">Total Balance</span>
            </div>
            <div className="values">
              <div className="item" key={0}>
                <span className="currency table-cell">{currency}</span>
                <span className="value table-cell">{format(Math.min(data?.available, data?.pool), 'money')}</span>
              </div>
            </div>
          </div>
          <div className="metric metric-pool-size" key="pending">
            <div className="name">Pending Payouts</div>
            <div className="values">
              <div className="item" key={0}>
                <span className="currency table-cell">{currency}</span>
                <span className="value table-cell">{format(data?.pending, 'money')}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactTooltip id="balances" />
    </Cell>
  )
}

const mapStateToProps = (state) => ({
  businessDashboard: state.businessDashboard
})

export default connect(mapStateToProps)(BalancesChart)
