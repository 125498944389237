import React from 'react'
import QuestionInput, { QuestionInputProps } from './QuestionInput'

type NewQuestionProps = QuestionInputProps

function NewQuestion (props: NewQuestionProps) {
  return (
    <div className="question-new">
      <QuestionInput { ...props }></QuestionInput>
    </div>
  )
}

export default NewQuestion
