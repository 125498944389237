import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as businessSetupActions from './actions'
import * as types from 'Scenes/BusinessSetup/types'
import TaskListItem from './components/TaskListItem'
import TaskBasic from './components/TaskBasic'
import TaskClass from './components/TaskClass'
import TaskPayment from './components/TaskPayment'
import './styles.scss'
import 'react-datetime/css/react-datetime.css'
import TaskBilling from './components/TaskBilling'
import classNames from 'classnames'
import TaskLaunch from './components/TaskLaunch'

type BusinessSetupProps = {
  state: { businessSetup?: types.BusinessSetupState }
} & types.PropsWithBusinessSetupState & types.BusinessSetupStateActions

function BusinessSetup (props: BusinessSetupProps) {
  const [activeTaskName, setActiveTask] = useState<string>()

  useEffect(() => {
    if ('businessSetup' in props.state) {
      console.log('Adopting state', props.state)
      props.businessSetupAdoptState(props.state.businessSetup)
    }
  }, [props.state])

  const activeTask = useMemo(
    () => props.businessSetup.tasks.find(item => item.name === activeTaskName),
    [activeTaskName, props.businessSetup.tasks]
  )

  console.log('props.businessSetup', props.businessSetup)

  const taskHandlers = {
    basic: TaskBasic,
    class: TaskClass,
    payment: TaskPayment,
    billing: TaskBilling,
    launch: TaskLaunch
  }

  const TaskHandler = taskHandlers[activeTaskName]

  return (
    <div className={classNames({
      'business-setup': true,
      [`current-task-${activeTaskName ?? 'no-task'}`]: true
    })}>
      {/* {props.businessSetup.tasks.length > 0 && <TaskPayment onDone={() => setActiveTask(undefined)} task={props.businessSetup.tasks.find(item => item.name === 'payment')}/>} */}
      {activeTaskName === undefined
        ? (
          <div className="task-list">
            {props.businessSetup.tasks.map((task, index) => (
              <TaskListItem name={task.name} key={index} onTrigger={() => setActiveTask(task.name)}/>
            ))}
          </div>
        )
        : (
          <TaskHandler onDone={() => setActiveTask(undefined)} task={activeTask} />
        )
      }
    </div>
  )
}

const mapStateToProps = (state) => ({
  businessSetup: state.businessSetup
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    ...businessSetupActions
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(BusinessSetup)
