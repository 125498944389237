import React, { useEffect, useMemo } from 'react'
import { PostForm } from './components/PostForm'
import Cell from './components/Cell'
import LatestSignups from './components/LatestSignups'
import Calendar from './components/Calendar'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as businessDashboardActions from './actions'
import * as types from 'Scenes/BusinessDashboard/types'
import EnrollmentsChart from './components/EnrollmentsChart'
import SignUpByGenderChart from './components/SignUpByGenderChart'
import TopFranchisees from './components/TopFranchisees'
import './styles.scss'
import ReceivablesRefundsChart from './components/ReceivablesRefundsChart'
import UsersChart from './components/UsersChart'
import AverageScoreChart from './components/AverageScoreChart'
import PeriodSelect from './components/PeriodSelect'
// import Payouts from './components/Payouts'
import BalancesChart from './components/BalancesChart'

type BusinessDashboardProps = {
  state: { businessDashboard?: types.BusinessDashboardState }
} & types.PropsWithBusinessDashboardState
  & types.BusinessDashboardStateActions
  & types.BusinessDashboardActions

// declare var Drupal: {
//   behaviors: {
//     grovvupReactApp: {
//       formSubmit: (formId: string, beakidElement: string, store: any) => void
//     }
//   }
// }

function BusinessDashboard (props: BusinessDashboardProps) {
  useEffect(() => {
    if ('businessDashboard' in props.state) {
      console.log('Adopting state', props.state)
      props.businessDashboardAdoptState(props.state.businessDashboard)
    }
  }, [props.state])

  console.log('props.state', props.state)

  const unreadLSCount = useMemo(() => (
    props.businessDashboard.latestSignups.lastSeenId
      ? props.businessDashboard.latestSignups.list
        .filter(signup => signup.id > props.businessDashboard.latestSignups.lastSeenId)
        .length
      : null
  ), [props.businessDashboard.latestSignups])

  const handleBusiness = (formId, currentBusiness) => {
    console.log('Switching to business:', currentBusiness)
    props.businessDashboardBusinessSet(currentBusiness)
    // Drupal.behaviors.grovvupReactApp.formSubmit(formId, 'submit-business', {
    //   businessDashboard: {
    //     currentBusiness
    //   }
    // })
  }

  const factory = window.BeakidReactFactory
  const FormContext = factory.FormContext

  return (
    <div className="business-dashboard">
      {props.businessDashboard.isFranchisor && (
        <div className="toolbar">
          <FormContext.Consumer>
            {formId => (
              <div className="business">
                {/* <label>Business</label> */}
                <select onChange={event => handleBusiness(formId, parseInt(event.target.value))} defaultValue={props.businessDashboard.currentBusiness}>
                  <option key="all" value={0}>All businesses</option>
                  <option key="main" value={props.businessDashboard.mainBusiness}>Main Business</option>
                  {props.businessDashboard.franchisees.map(franchisee => <option key={franchisee.id} value={franchisee.id}>{franchisee.title}</option>)}
                </select>
              </div>
            )}
          </FormContext.Consumer>

          <PeriodSelect period={props.businessDashboard.period} onChange={period => props.businessDashboardPeriodSet(period)}/>
        </div>
      )}
      <div className="columns">
        <div className="column">
          <Cell title="Quick Post">
            <PostForm/>
          </Cell>
          {!props.businessDashboard.isFranchisor && (
            <Cell title="Latest Signups" { ...(unreadLSCount > 0 ? { notification: unreadLSCount.toString() } : {}) }>
              <LatestSignups/>
            </Cell>
          )}
          {props.businessDashboard.isFranchisor && (<>
            <UsersChart/>
            <TopFranchisees/>
          </>)}
        </div>
        <div className="column">
          <Cell title="Calendar"><Calendar/></Cell>
        </div>
      </div>

      {props.businessDashboard.analytics.enabled && (<>
        <div className="columns">
          <div className="column">
            <ReceivablesRefundsChart/>
          </div>
          <div className="column">
            <EnrollmentsChart/>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <SignUpByGenderChart/>
          </div>
          <div className="column">
            <AverageScoreChart/>
          </div>
        </div>
      </>)}

      <div className="columns">
        <div className="column">
          <BalancesChart/>
        </div>
      </div>

      {/* {!props.businessDashboard.isFranchisor && (
        <div className="columns">
          <div className="column">
            <Payouts business={props.businessDashboard.mainBusiness}/>
          </div>
          <div className="column"></div>
        </div>
      )} */}
    </div>
  )
}

const mapStateToProps = (state) => ({
  businessDashboard: state.businessDashboard
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    ...businessDashboardActions
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(BusinessDashboard)
